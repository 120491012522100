<template>
  <Transition
    appear
    enter-active-class="ease-out duration-200"
    :enter-from-class="closedClasses"
    :enter-to-class="openClasses"
    leave-active-class="ease-in duration-100"
    :leave-from-class="openClasses"
    :leave-to-class="closedClasses"
    @after-enter="afterEnter"
    @after-leave="afterLeaveOuter"
  >
    <div
      v-if="widgetVisible"
      class="fixed bottom-8 transition-all"
      :class="classes"
    >
      <TransitionGroup
        appear
        enter-active-class="ease-in duration-200"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
        @after-leave="afterLeaveInner"
      >
        <div
          v-if="contentVisible"
          class="relative"
          key="content"
          :class="[
            poweredByVisible && 'h-[calc(100%-theme(spacing.10))]',
            !poweredByVisible && 'h-full',
          ]"
        >
          <RouterView v-slot="{ Component, route }">
            <Transition
              enter-active-class="duration-500 transition ease-[cubic-bezier(0.55, 0, 0.1, 1)]"
              leave-active-class="duration-500 transition ease-[cubic-bezier(0.55, 0, 0.1, 1)]"
              :enter-from-class="
                [
                  transitionType === SLIDE_LEFT_TRANSITION &&
                    'translate-x-full',
                  transitionType === SLIDE_RIGHT_TRANSITION &&
                    '-translate-x-full',
                ].join(' ')
              "
              :leave-to-class="
                [
                  transitionType === SLIDE_LEFT_TRANSITION &&
                    '-translate-x-full',
                  transitionType === SLIDE_RIGHT_TRANSITION &&
                    'translate-x-full',
                ].join(' ')
              "
            >
              <div
                class="h-full w-full flex flex-col absolute"
                :key="route.path"
              >
                <div
                  class="p-3 flex items-center bg-[color:var(--ih-primary-color)] text-white font-medium text-lg"
                >
                  <img
                    v-if="isHomeRoute(route) && logo"
                    class="shrink-0 w-20 h-10"
                    :src="logo"
                    alt=""
                  />

                  <div class="grow h-10 flex items-center">
                    <ArrowLeftIcon
                      v-if="!isHomeRoute(route)"
                      class="p-1 w-8 h-8 cursor-pointer text-white mr-6 hover:brightness-75 hover:bg-[color:var(--ih-primary-color)] rounded-md shrink-0"
                      @click="routeHistoryBack()"
                    />

                    <div class="grow" v-text="route.meta.title"></div>

                    <component
                      v-if="route.meta.widgetExpandable"
                      :is="
                        expanded ? ArrowsPointingInIcon : ArrowsPointingOutIcon
                      "
                      @click="toggleExpand()"
                      class="p-1 w-8 h-8 cursor-pointer text-white mr-4 hover:brightness-75 hover:bg-[color:var(--ih-primary-color)] rounded-md shrink-0"
                    />

                    <XMarkIcon
                      class="p-1 w-8 h-8 cursor-pointer text-white hover:brightness-75 hover:bg-[color:var(--ih-primary-color)] rounded-md shrink-0"
                      @click="close()"
                    />
                  </div>
                </div>

                <Component :is="Component" />
              </div>
            </Transition>
          </RouterView>
        </div>

        <div
          v-if="poweredByVisible && contentVisible"
          class="bg-white text-gray-500 border-t border-gray-300 text-xs"
          key="footer"
        >
          <a
            href="https://inlinehelp.com?ref=widget"
            target="_blank"
            class="flex items-center justify-center h-10 -ml-3"
          >
            <span class="inline-block pt-[2px]">⚡️</span>

            <span>{{
              $pgettext("Widget footer brand text.", "Powered by Inline Help")
            }}</span>
          </a>
        </div>
      </TransitionGroup>
    </div>
  </Transition>
</template>

<script setup lang="ts">
  import { computed, nextTick, onMounted, ref, watch } from "vue";
  import {
    ArrowLeftIcon,
    ArrowsPointingInIcon,
    ArrowsPointingOutIcon,
    XMarkIcon,
  } from "@heroicons/vue/24/solid";
  import type { RouteLocationNormalizedLoaded } from "vue-router";
  import { useRoute, useRouter } from "vue-router";

  import { useConfigStore } from "@/store/config";

  import type { LocalStorageRouterHistory } from "@/router/local-storage-history";

  type Props = {
    visible: boolean;
  };

  const props = withDefaults(defineProps<Props>(), {
    visible: false,
  });

  const emit = defineEmits<{
    close: [];
  }>();

  const widgetVisible = ref(false);
  const contentVisible = ref(false);
  const classes = ref("");
  const expanded = ref(false);

  const router = useRouter();

  const configStore = useConfigStore();

  const horizontalPosition = configStore.config.widget.style.launcher.position;
  const isHorizontalPositionRight = horizontalPosition === "right";
  const horizontalPositionClass = isHorizontalPositionRight
    ? "right-8"
    : "left-8";

  const spaceSideClass = configStore.config.widget.style.launcher.space.side
    ? isHorizontalPositionRight
      ? "-translate-x-[--ih-launcher-space-side]"
      : "translate-x-[--ih-launcher-space-side]"
    : "";

  const spaceBottomClass = configStore.config.widget.style.launcher.space.bottom
    ? "-translate-y-[--ih-launcher-space-bottom]"
    : "";

  const closedClasses = `h-12 w-12 bg-[color:var(--ih-primary-color)] rounded-lg ${horizontalPositionClass} ${spaceSideClass} ${spaceBottomClass}`;

  const openClasses = computed(
    () =>
      `${
        expanded.value
          ? "w-[calc(100%-theme(spacing.16)-calc(2*var(--ih-launcher-space-side)))] h-[calc(100%-theme(spacing.16)-calc(2*var(--ih-launcher-space-bottom)))]"
          : "h-full max-h-[calc(100%-theme(spacing.16)-calc(2*var(--ih-launcher-space-bottom)))] sm:h-[calc(66.666667%+theme(spacing.10))] w-96 max-w-[calc(100%-theme(spacing.16)-calc(2*var(--ih-launcher-space-side)))]"
      } bg-white shadow-lg rounded-xl overflow-clip ${horizontalPositionClass} ${spaceSideClass} ${spaceBottomClass}`,
  );

  const logo = configStore.config.widget.logo || null;

  const poweredByVisible = configStore.config.global.poweredBy;

  const route = useRoute();

  const isHomeRoute = (route: RouteLocationNormalizedLoaded) =>
    route.path === "/";

  const SLIDE_LEFT_TRANSITION = 0;
  const SLIDE_RIGHT_TRANSITION = 1;

  const transitionType = ref(SLIDE_LEFT_TRANSITION);

  const routeHistoryBack = () => router.go(-1);

  const toggleExpand = () => {
    expanded.value = !expanded.value;

    classes.value = openClasses.value;
  };

  (router.options.history as LocalStorageRouterHistory).onPush(() => {
    transitionType.value = SLIDE_LEFT_TRANSITION;
  });

  (router.options.history as LocalStorageRouterHistory).onBack(() => {
    transitionType.value = SLIDE_RIGHT_TRANSITION;
  });

  onMounted(() => {
    if (!configStore.config.widget.sections.length) {
      router.push("/");
    }
  });

  watch(
    () => props.visible,
    value => {
      nextTick(() => {
        if (value) {
          widgetVisible.value = true;
        }

        if (!value) {
          contentVisible.value = false;
        }
      });
    },
    { immediate: true },
  );

  watch(
    () => route.meta.widgetExpandable,
    value => {
      if (!value) {
        expanded.value = false;

        classes.value = openClasses.value;
      }
    },
  );

  const close = () => {
    emit("close");

    router.replace("/");
  };

  const afterEnter = () => {
    classes.value = openClasses.value;

    contentVisible.value = true;
  };

  const afterLeaveInner = () => {
    expanded.value = false;

    widgetVisible.value = false;
  };

  const afterLeaveOuter = () => {
    classes.value = "";
  };
</script>
