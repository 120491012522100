import { computed, readonly, ref } from "vue";
import { defineStore } from "pinia";
import { defaultsDeep } from "lodash-es";

import { useHttpClientApi } from "@/lib/http";

import { tailwindConfig } from "@/util/tailwind";

import type { DeepPartial } from "@/types";
import {
  type AvailableLanguages,
  defaultLanguage,
} from "@/translations/gettext";

export const SECTION_TYPE_CHATBOT = "chatbot";
export const SECTION_TYPE_EXPLAIN_THIS = "explain_this";
export const SECTION_TYPE_TICKET_FORM = "ticket_form";
export const SECTION_TYPE_KNOWLEDGE_BASE = "knowledge_base";
export const SECTION_TYPE_PAGE_RESOURCES = "page_resources";
export const SECTION_TYPE_CUSTOM = "custom";

export type SectionType =
  | typeof SECTION_TYPE_CHATBOT
  | typeof SECTION_TYPE_EXPLAIN_THIS
  | typeof SECTION_TYPE_TICKET_FORM
  | typeof SECTION_TYPE_KNOWLEDGE_BASE
  | typeof SECTION_TYPE_PAGE_RESOURCES
  | typeof SECTION_TYPE_CUSTOM;

export const SECTION_IMAGE_POSITION_TOP = "top";
export const SECTION_IMAGE_POSITION_LEFT = "left";

export type SectionImagePosition =
  | typeof SECTION_IMAGE_POSITION_TOP
  | typeof SECTION_IMAGE_POSITION_LEFT;

export const SECTION_LINK_TYPE_NONE = "none";
export const SECTION_LINK_TYPE_INTERNAL = "internal_link";
export const SECTION_LINK_TYPE_EXTERNAL = "external_link";

export type SectionLinkType =
  | typeof SECTION_LINK_TYPE_NONE
  | typeof SECTION_LINK_TYPE_INTERNAL
  | typeof SECTION_LINK_TYPE_EXTERNAL;

export type Section = {
  type: SectionType;
  title: string;
  description?: string;
  image?: string;
  imagePosition?: SectionImagePosition;
  link?: {
    type: SectionLinkType;
    url: string;
    openInNewTab: boolean;
  };
};

type TooltipLauncherConfig = {
  circleColor: string;
  circleColorHover: string;
  circleFillColor: string;
  circleFillColorHover: string;
  questionMarkColor: string;
  questionMarkColorHover: string;
  position:
    | "top"
    | "top-start"
    | "top-end"
    | "right"
    | "right-start"
    | "right-end"
    | "bottom"
    | "bottom-start"
    | "bottom-end"
    | "left"
    | "left-start"
    | "left-end";
  space: {
    mainAxis: number;
    crossAxis: number;
  };
  size: {
    width: number;
    height: number;
  };
  zIndex: "auto" | number;
};

type TooltipLauncherTypes = {
  info: TooltipLauncherConfig;
};

export type PopupConfig = {
  position: "left" | "right" | "top" | "bottom";
  space: {
    mainAxis: number;
    crossAxis: number;
  };
};

export type Tooltip = {
  type: "info";
  elements: {
    selector: string;
  }[];
  popup: PopupConfig;
  launcher: TooltipLauncherConfig;
};

export type Config = {
  global: {
    poweredBy: boolean;
    language: AvailableLanguages;
  };
  widget: {
    sections: Section[];
    welcomeMessage: string;
    logo: string;
    targetSelector: string;
    style: {
      primaryColor: string;
      secondaryColor: string;
      font: string;
      launcher: {
        icon: {
          shape: "square" | "circle" | "squircle";
          src: string;
        };
        position: "left" | "right";
        space: {
          side: number;
          bottom: number;
        };
      };
      zIndex: "auto" | number;
    };
    launcher: {
      nudge: {
        enabled: boolean;
        text: string;
        timeout: number;
      };
      enabled: boolean;
    };
  };
  chatbot: {
    welcomeMessage: string;
    icon: string;
    sourcesEnabled: boolean;
    aiGeneratedBadgeEnabled: boolean;
  };
  explainThis: {
    allowList: string[];
    denyList: string[];
    sourcesEnabled: boolean;
    askAboutThisEnabled: boolean;
    explainAnotherPartEnabled: boolean;
    messageRatingEnabled: boolean;
    aiGeneratedBadgeEnabled: boolean;
    popup: PopupConfig;
  };
  tooltips: {
    sourcesEnabled: boolean;
    askAboutThisEnabled: boolean;
    messageRatingEnabled: boolean;
    aiGeneratedBadgeEnabled: boolean;
    tooltips: Tooltip[];
  };
};

export const useConfigStore = defineStore("config", () => {
  const httpClientApi = useHttpClientApi();

  const raw = ref<DeepPartial<Config>>();

  const globalComponents = ref<string[]>([]);

  const clientApiKey = ref<string>();

  const tooltipTypeDefaults = ref<TooltipLauncherTypes>({
    info: {
      circleColor: "#2563eb",
      circleColorHover: "#2563eb",
      circleFillColor: "#bfc5f3",
      circleFillColorHover: "#bfc5f3",
      questionMarkColor: "#2535eb",
      questionMarkColorHover: "#2535eb",
      position: "right",
      space: {
        mainAxis: 5,
        crossAxis: 0,
      },
      size: {
        width: 24,
        height: 24,
      },
      zIndex: 5,
    },
  });

  const config = computed<Config>(() =>
    defaultsDeep(raw.value, {
      global: {
        poweredBy: false,
        language: defaultLanguage,
      },
      widget: {
        sections: [],
        welcomeMessage: "",
        logo: "",
        targetSelector: "body",
        style: {
          primaryColor: "#2563eb",
          secondaryColor: "#dbeafe",
          font: `ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
          launcher: {
            icon: {
              shape: "squircle",
              src: "",
            },
            position: "right",
            space: {
              side: 0,
              bottom: 0,
            },
          },
          zIndex: tailwindConfig.theme.zIndex.highest,
        },
        launcher: {
          nudge: {
            enabled: true,
            text: "Hello. Need any help? Click here.",
            timeout: 3_000,
          },
          enabled: true,
        },
      },
      chatbot: {
        welcomeMessage:
          "Hello,\nAsk us any question you might have or [explain_this:click here] and show us which part of the application you would like to get help with.",
        icon: "",
        sourcesEnabled: true,
        aiGeneratedBadgeEnabled: true,
      },
      explainThis: {
        allowList: [],
        denyList: [],
        sourcesEnabled: raw.value?.tooltips?.sourcesEnabled,
        askAboutThisEnabled: raw.value?.tooltips?.askAboutThisEnabled,
        explainAnotherPartEnabled: true,
        messageRatingEnabled: raw.value?.tooltips?.messageRatingEnabled,
        aiGeneratedBadgeEnabled: true,
        popup: {
          position: "right",
          space: {
            mainAxis: 20,
            crossAxis: 0,
          },
        },
      },
      tooltips: {
        sourcesEnabled: true,
        askAboutThisEnabled: true,
        messageRatingEnabled: true,
        aiGeneratedBadgeEnabled: true,
        tooltips: (raw.value?.tooltips?.tooltips || []).map(
          (tooltip: Tooltip) =>
            defaultsDeep(tooltip, {
              popup: {
                position: "right",
                space: {
                  mainAxis: 20,
                  crossAxis: 0,
                },
              },
              launcher: tooltipTypeDefaults.value[tooltip.type],
            }),
        ),
      },
    } as Config),
  );

  const $reset = () => {
    raw.value = {};

    globalComponents.value = [];
  };

  const fetch = async () => {
    raw.value = await httpClientApi.fetchConfig();
  };

  return {
    $reset,
    config: readonly(config),
    globalComponents,
    clientApiKey,
    fetch,
  };
});
