import type { DeepReadonly, DefineComponent } from "vue";
import { createPinia, setActivePinia } from "pinia";
import { until } from "@vueuse/core";

import type { PreLoadQueue, PreLoadQueueItem, PublicAPI } from "@/types";

import {
  createAppWithDelayedUnmount,
  maybeRegisterCustomElement,
  mountWithDelayedUnmount,
} from "@/core/web-component";

import { httpApiUrl, wsApiUrl } from "@/core/environment";

import logger from "@/core/logger";

import { useHttpClientApi } from "@/lib/http";
import { useWSClientApi } from "@/lib/websocket";

import { useAppRouter } from "@/router";

import type { Config } from "@/store/config";
import { useConfigStore } from "@/store/config";

import { usePublicAPI } from "@/lib/api";

import { useTooltips } from "@/modules/hints";

import { validateSelector } from "@/util/dom";

import { deactivateAllModules } from "@/modules";

import { useConversationStore } from "@/store/conversation";

import { useKnowledgeBaseStore } from "@/store/knowledge-base";

import { useStateStore } from "@/store/state";

import { useUserStore } from "@/store/user";

import { resetLocalStorage } from "@/lib/storage";

import { useTranslations } from "@/translations/translations";

import TheApp from "@/TheApp.ce.vue";

export type LoadOptions = {
  clientApiKey: string;
  snippetVersion: string;
};

const pinia = createPinia();

setActivePinia(pinia);

let unmountRoot: ((immediate?: boolean) => Promise<void>) | undefined;
let loadOptions: LoadOptions;

const processPreLoadQueue = (
  preLoadQueue: PreLoadQueue,
  publicAPI: PublicAPI,
) => {
  if (!preLoadQueue.length) {
    return;
  }

  const [method, ...args] = preLoadQueue.shift() as PreLoadQueueItem;

  logger.silly(
    "Processing pre load queue item.",
    [method, ...args],
    "Bootstrap",
  );

  if (!Object.hasOwn(publicAPI, method)) {
    logger.error(
      `API method "${method}" does not exist.`,
      { method, args },
      "api",
    );
  } else {
    publicAPI[method](...args);
  }

  processPreLoadQueue(preLoadQueue, publicAPI);
};

const mountApp = (config: DeepReadonly<Config>) => {
  const appWithDelayedUnmount = createAppWithDelayedUnmount(
    "inline-help",
    "InlineHelpApp",
    {
      style: `position: relative; z-index: ${config.widget.style.zIndex};`,
    },
  );

  const targetSelector = config.widget.targetSelector;

  if (!validateSelector(targetSelector)) {
    throw new Error(
      `Unable to initialize the Client. Widget target selector "${targetSelector}" is invalid.`,
    );
  }

  const target = document.querySelector(targetSelector);

  if (!target) {
    throw new Error(
      `Unable to initialize the Client. Widget target element based on the "${targetSelector}" selector was not found.`,
    );
  }

  unmountRoot = mountWithDelayedUnmount({
    ...appWithDelayedUnmount,
    target: target as HTMLElement,
  });
};

export const install = async (
  preLoadQueue: PreLoadQueue,
  options: LoadOptions,
) => {
  window.InlineHelp.initialized = true;

  loadOptions = options;

  logger.info("Initializing...");

  useHttpClientApi({
    endpoint: httpApiUrl,
    clientApiKey: options.clientApiKey,
  });

  useWSClientApi({
    endpoint: wsApiUrl,
    clientApiKey: options.clientApiKey,
  });

  const configStore = useConfigStore();

  configStore.clientApiKey = options.clientApiKey;

  await configStore.fetch();

  const { router } = useAppRouter({
    sections: configStore.config.widget.sections,
  });

  const { globalComponents } = maybeRegisterCustomElement(
    "inline-help",
    TheApp as unknown as DefineComponent,
    [router, useTranslations()],
  );

  configStore.globalComponents = globalComponents.map(({ name }) => name);

  await until(() => document.readyState).not.toBe("loading");

  const publicAPI = usePublicAPI();

  mountApp(configStore.config);

  const tooltips = useTooltips();

  tooltips.activate();

  window.InlineHelp = publicAPI;
  window.InlineHelp.initialized = true;

  processPreLoadQueue(preLoadQueue, publicAPI);

  Object.freeze(window.InlineHelp);

  logger.info("Initialized.");
};

export const reset = async () => {
  logger.info("Resetting ...");

  await unmountRoot?.(true);

  deactivateAllModules();

  const appRouter = useAppRouter();
  appRouter.$reset();

  const configStore = useConfigStore();
  configStore.$reset();

  const conversationStore = useConversationStore();
  conversationStore.$reset();

  const knowledgeBaseStore = useKnowledgeBaseStore();
  knowledgeBaseStore.$reset();

  const stateStore = useStateStore();
  stateStore.$reset();

  const userStore = useUserStore();
  userStore.$reset();

  const httpClientApi = useHttpClientApi();
  httpClientApi.$reset();

  const wsClientApi = useWSClientApi();
  wsClientApi.$reset();

  resetLocalStorage();

  await install([], loadOptions);

  logger.info("Reset.");
};

export const resetUI = async () => {
  logger.info("Resetting UI ...");

  await unmountRoot?.(true);

  const configStore = useConfigStore(pinia);

  mountApp(configStore.config);

  const tooltips = useTooltips();

  tooltips.activate();

  logger.info("Reset.");
};
