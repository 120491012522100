<template>
  <div class="overflow-y-auto grow flex flex-col">
    <Transition
      appear
      enter-active-class="ease-in duration-200"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <AppLoader
        :loading="!filteredCategories"
        :sync="false"
        class="grow"
        classes="p-10"
        :retry-cb="fetchCategories"
        :home-cb="goToWidgetHome"
        :text="
          $pgettext(
            'Loading text displayed when Knowledge Base categories are being fetched.',
            'Loading',
          )
        "
      >
        <KnowledgeBaseBodyWrapper>
          <nav v-if="filteredCategories?.length" class="grow mb-3 px-3">
            <ul role="list" class="flex flex-col">
              <li v-for="category in filteredCategories" :key="category.id">
                <KnowledgeBaseCategoryOrFolder
                  :item="{
                    ...category,
                    type: KNOWLEDGE_BASE_CONTENTS_CATEGORY,
                  }"
                  :show-icon="false"
                />
              </li>
            </ul>
          </nav>

          <div v-else class="grow mb-3 px-3">
            <div class="p-2 bg-yellow-200 text-xs text-gray-500">
              {{
                $pgettext(
                  "Knowledge Base Categories page fallback text when no Categories could be found.",
                  "No categories could be found.",
                )
              }}
            </div>
          </div>
        </KnowledgeBaseBodyWrapper>
      </AppLoader>
    </Transition>
  </div>
</template>

<script setup lang="ts">
  import { onBeforeUnmount } from "vue";
  import { storeToRefs } from "pinia";

  import {
    KNOWLEDGE_BASE_CONTENTS_CATEGORY,
    useKnowledgeBaseStore,
  } from "@/store/knowledge-base";

  import { useKnowledgeBase } from "@/modules/knowledge-base";

  import { SECTION_TYPE_KNOWLEDGE_BASE } from "@/store/config";

  import { useAppRouter } from "@/router";

  import KnowledgeBaseCategoryOrFolder from "@/modules/knowledge-base/KnowledgeBaseCategoryOrFolder.vue";
  import AppLoader from "@/components/AppLoader.vue";
  import KnowledgeBaseBodyWrapper from "@/modules/knowledge-base/KnowledgeBaseBodyWrapper.vue";

  defineOptions({
    async beforeRouteEnter() {
      const knowledgeBaseStore = useKnowledgeBaseStore();
      const { filteredCategories } = storeToRefs(knowledgeBaseStore);

      await knowledgeBaseStore.fetchCategories();

      if (filteredCategories.value?.length === 1) {
        return `/${SECTION_TYPE_KNOWLEDGE_BASE}/contents/${KNOWLEDGE_BASE_CONTENTS_CATEGORY}/${filteredCategories.value[0].id}`;
      }
    },
  });

  const knowledgeBase = useKnowledgeBase();

  const knowledgeBaseStore = useKnowledgeBaseStore();
  const { filteredCategories } = storeToRefs(knowledgeBaseStore);

  const { router } = useAppRouter();

  const fetchCategories = async () => {
    await knowledgeBaseStore.fetchCategories();

    if (filteredCategories.value?.length === 1) {
      router.replace(
        `/${SECTION_TYPE_KNOWLEDGE_BASE}/contents/${KNOWLEDGE_BASE_CONTENTS_CATEGORY}/${filteredCategories.value[0].id}`,
      );
    }
  };

  const goToWidgetHome = () => {
    router.push("/");
  };

  onBeforeUnmount(() => {
    knowledgeBase.deactivate();
  });
</script>
