<template>
  <div class="text-gray-500">
    <h2 class="font-semibold">
      {{
        $pgettext(
          "Inline sources heading displayed above the sources list.",
          "Read more",
        )
      }}
    </h2>

    <nav class="mt-2">
      <ul role="list">
        <li v-for="source in sources" :key="source.id">
          <RouterLink
            custom
            v-if="source.type === MESSAGE_SOURCE_ARTICLE"
            :to="`/${SECTION_TYPE_KNOWLEDGE_BASE}/article/${source.id}`"
            v-slot="{ navigate }"
          >
            <button
              type="button"
              @click.prevent.stop="openWidgetAndNavigateToSource(navigate)"
              class="gap-x-2 p-1 hover:underline cursor-pointer w-full leading-4 text-left flex items-center"
            >
              <DocumentTextIcon class="h-4 w-4 text-gray-400 shrink-0" />

              {{ source.title }}
            </button>
          </RouterLink>

          <button
            v-else
            type="button"
            @click.prevent.stop="openSourceUrl(source.url)"
            class="gap-x-2 p-1 hover:underline cursor-pointer w-full leading-4 text-left flex items-center"
          >
            <ArrowTopRightOnSquareIcon class="w-4 h-4 text-gray-400 shrink-0" />

            <span class="pr-6">{{ source.title }}</span>
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script setup lang="ts">
  import type { DeepReadonly } from "vue";
  import {
    ArrowTopRightOnSquareIcon,
    DocumentTextIcon,
  } from "@heroicons/vue/24/outline";
  import type { NavigationFailure } from "vue-router";

  import type { Message } from "@/store/conversation";
  import { MESSAGE_SOURCE_ARTICLE } from "@/store/conversation";

  import { SECTION_TYPE_KNOWLEDGE_BASE } from "@/store/config";

  import { useKnowledgeBase } from "@/modules/knowledge-base";

  type Props = {
    sources: DeepReadonly<Message>["metadata"]["sources"];
  };

  defineProps<Props>();

  const emit = defineEmits<{
    click: [];
  }>();

  const knowledgeBase = useKnowledgeBase();

  const openWidgetAndNavigateToSource = (
    navigate: (e?: MouseEvent | undefined) => Promise<void | NavigationFailure>,
  ) => {
    knowledgeBase.activate();

    navigate();

    emit("click");
  };

  const openSourceUrl = (url: string) => {
    window.open(url, "_blank");

    emit("click");
  };
</script>
