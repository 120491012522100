<template>
  <div
    v-if="filteredResourcesForThisPage.length"
    class="p-3 bg-white rounded-xl outline outline-1 -outline-offset-1 outline-gray-300 drop-shadow"
  >
    <h2 class="font-bold text-sm">{{ section.title }}</h2>

    <ul class="mt-3 space-y-2 text-sm">
      <li
        v-for="pageResource in filteredResourcesForThisPage"
        :key="pageResource.id"
      >
        <RouterLink
          :to="`/${SECTION_TYPE_KNOWLEDGE_BASE}/article/${pageResource.id}`"
          class="flex items-start gap-x-2 cursor-pointer hover:underline"
        >
          <DocumentTextIcon class="h-4 w-4 shrink-0" />

          <span class="leading-4">{{ pageResource.title }}</span>
        </RouterLink>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
  import { computed } from "vue";
  import { DocumentTextIcon } from "@heroicons/vue/24/outline";

  import type { Props } from "@/components/DefaultHomeSectionListItem.global.vue";

  import { SECTION_TYPE_KNOWLEDGE_BASE } from "@/store/config";

  import { useKnowledgeBaseStore } from "@/store/knowledge-base";

  defineProps<Props>();

  const MAX_RESOURCES_FOR_THIS_PAGE = 5;

  const knowledgeBaseStore = useKnowledgeBaseStore();

  knowledgeBaseStore.fetchPageResources({
    title: document.title,
    url: window.location.href,
  });

  const filteredResourcesForThisPage = computed(() =>
    knowledgeBaseStore.pageResources.slice(0, MAX_RESOURCES_FOR_THIS_PAGE),
  );
</script>
