<template>
  <div class="overflow-y-auto grow flex flex-col">
    <Transition
      appear
      enter-active-class="ease-in duration-200"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <AppLoader
        :loading="!filteredContents"
        :sync="false"
        class="grow"
        classes="p-10"
        :retry-cb="fetchContents"
        :home-cb="goToWidgetHome"
        :text="
          $pgettext(
            'Loading text displayed when Knowledge Base contents is being fetched.',
            'Loading',
          )
        "
      >
        <KnowledgeBaseBodyWrapper>
          <nav v-if="filteredContents?.length" class="grow mb-3 px-3">
            <ul role="list" class="flex flex-1 flex-col -mx-1">
              <li v-for="content in filteredContents" :key="content.id">
                <RouterLink
                  v-if="content.type === KNOWLEDGE_BASE_CONTENTS_ARTICLE"
                  :to="`/${SECTION_TYPE_KNOWLEDGE_BASE}/article/${content.id}`"
                  class="gap-x-2 p-2 text-sm text-gray-700 rounded-md flex items-start hover:bg-[color:var(--ih-secondary-color)]"
                >
                  <DocumentTextIcon
                    class="h-4 w-4 text-gray-400 shrink-0 mt-1"
                  />

                  <span class="leading-5">{{ content.title }}</span>
                </RouterLink>

                <KnowledgeBaseCategoryOrFolder
                  v-if="content.type === KNOWLEDGE_BASE_CONTENTS_FOLDER"
                  :item="content"
                />
              </li>
            </ul>
          </nav>

          <div v-else class="grow mb-3 px-3">
            <div class="p-2 bg-yellow-200 text-xs text-gray-500">
              {{
                $pgettext(
                  "Knowledge Base Contents page fallback text when no articles nor folders could be found.",
                  "No articles nor folders could be found.",
                )
              }}
            </div>
          </div>
        </KnowledgeBaseBodyWrapper>
      </AppLoader>
    </Transition>
  </div>
</template>

<script setup lang="ts">
  import { onBeforeUnmount, onMounted, toValue } from "vue";
  import { DocumentTextIcon } from "@heroicons/vue/24/outline";
  import { storeToRefs } from "pinia";

  import type { KnowledgeBaseContentsCategoryOrFolder } from "@/store/knowledge-base";
  import {
    KNOWLEDGE_BASE_CONTENTS_ARTICLE,
    KNOWLEDGE_BASE_CONTENTS_FOLDER,
    useKnowledgeBaseStore,
  } from "@/store/knowledge-base";

  import { useKnowledgeBase } from "@/modules/knowledge-base";

  import { SECTION_TYPE_KNOWLEDGE_BASE } from "@/store/config";

  import { useAppRouter } from "@/router";

  import KnowledgeBaseCategoryOrFolder from "@/modules/knowledge-base/KnowledgeBaseCategoryOrFolder.vue";
  import AppLoader from "@/components/AppLoader.vue";
  import KnowledgeBaseBodyWrapper from "@/modules/knowledge-base/KnowledgeBaseBodyWrapper.vue";

  const { router } = useAppRouter();
  const route = toValue(router.currentRoute);

  const knowledgeBaseStore = useKnowledgeBaseStore();
  const { filteredContents } = storeToRefs(knowledgeBaseStore);

  const knowledgeBase = useKnowledgeBase();

  const id = route.params.id as string;
  const type = route.params.type as KnowledgeBaseContentsCategoryOrFolder;

  const fetchContents = () => {
    knowledgeBaseStore.fetchContents(id, type);
  };

  const goToWidgetHome = () => {
    router.push("/");
  };

  onMounted(() => {
    knowledgeBase.activate(route.path);

    fetchContents();
  });

  onBeforeUnmount(() => {
    knowledgeBase.deactivate();
  });
</script>
