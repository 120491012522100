import { useStorage } from "@vueuse/core";

import { getStorageItemName, useLogger } from "@inline-help/shared";

import { buildId, defaultLogLevel } from "@/core/environment";

const storage = useStorage(
  getStorageItemName("ih_log_level", buildId),
  defaultLogLevel,
  localStorage,
);

export default useLogger("Client", storage, defaultLogLevel);

export {
  LOG_LEVEL_SILENT,
  LOG_LEVEL_WARN,
  LOG_LEVEL_ERROR,
  LOG_LEVEL_INFO,
  LOG_LEVEL_VERBOSE,
  LOG_LEVEL_SILLY,
} from "@inline-help/shared";
