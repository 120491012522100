<template>
  <div class="h-full overflow-y-auto">
    <div
      class="p-3 bg-[color:var(--ih-primary-color)] text-white text-lg pb-16"
    >
      <h1>{{ config.widget.welcomeMessage }}</h1>
    </div>

    <div class="p-3">
      <ul
        v-if="config.widget.sections.length"
        role="list"
        class="-mt-12 space-y-3"
      >
        <RouterLink
          custom
          v-for="section in config.widget.sections"
          :key="section.type"
          :to="`/${section.type}`"
          v-slot="{ navigate }"
        >
          <component
            :is="sectionComponent(section)"
            :section="section"
            :navigate="navigate"
          />
        </RouterLink>
      </ul>

      <div v-else class="p-2 bg-yellow-200 text-xs text-gray-500">
        {{
          $pgettext(
            "Widget Home fallback text when no Section were configured.",
            "No sections were configured in the Dashboard.",
          )
        }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { DeepReadonly, UnwrapRef } from "vue";
  import { onMounted } from "vue";
  import { camelCase, upperFirst } from "lodash-es";
  import { storeToRefs } from "pinia";

  import type { Section } from "@/store/config";
  import { useConfigStore } from "@/store/config";

  import { useAppRouter } from "@/router";

  import type { LocalStorageRouterHistory } from "@/router/local-storage-history";

  const { router } = useAppRouter();

  onMounted(() => {
    (router.options.history as LocalStorageRouterHistory).clear();
  });

  const configStore = useConfigStore();
  const { config, globalComponents } = storeToRefs(configStore);

  const sectionComponent = (section: UnwrapRef<DeepReadonly<Section>>) => {
    const componentName = `${upperFirst(
      camelCase(section.type),
    )}HomeSectionListItem`;

    return globalComponents.value.includes(componentName)
      ? componentName
      : "DefaultHomeSectionListItem";
  };
</script>
