import { unref } from "vue";
import type {
  MaybeElement,
  MaybeReadonlyRef,
  Middleware,
  MiddlewareState,
  ReferenceElement,
} from "@floating-ui/vue";
import { evaluate } from "@floating-ui/utils";

type DraggableMiddlewareState = MiddlewareState & {
  dragged?: boolean;
};

type Options = {
  element: MaybeReadonlyRef<Element>;
} & Partial<{
  drag(args: DraggableMiddlewareState): void | Promise<void>;
  dragStart(args: DraggableMiddlewareState): void | Promise<void>;
  dragEnd(args: DraggableMiddlewareState): void | Promise<void>;
}>;

type DraggableHTMLElement = HTMLElement & {
  __abortController?: AbortController;
  __reference: ReferenceElement;
  __dragged?: boolean;
  __dragX?: number;
  __dragY?: number;
};

// copied from @floating-ui utils as it's not exported
// https://github.com/floating-ui/floating-ui/blob/56d5e0f255459711fd3712c805ba03621fc86946/packages/vue/src/utils/unwrapElement.ts#L5
function unwrapElement<T>(element: MaybeElement<T>) {
  return ((element as Exclude<MaybeElement<T>, T>)?.$el ?? element) as T;
}

export const draggable = (options: Options): Middleware => ({
  name: "draggable",
  options,
  fn(state: MiddlewareState) {
    const element = unwrapElement(
      unref(options.element),
    ) as DraggableHTMLElement;

    const referenceElement = state.elements.reference;

    const referenceChanged = element.__reference !== referenceElement;

    element.__reference = referenceElement;

    if (referenceChanged) {
      element.__dragged = false;
      element.__dragX = undefined;
      element.__dragY = undefined;
    }

    let x = element.__dragX ?? state.x;
    let y = element.__dragY ?? state.y;

    if (element.__abortController) {
      element.__abortController.abort();
    }

    let dragging = false;

    const offset = { x: 0, y: 0 };

    const abortController = new AbortController();

    const { drag, dragStart, dragEnd } = evaluate(options, state);

    element.__abortController = abortController;

    element.setAttribute("draggable", "true");

    let dragElement = element.querySelector("#__dragImage");

    if (dragElement) {
      element.removeChild(dragElement);
    }

    dragElement = document.createElement("span");
    (dragElement as HTMLElement).style.cssText =
      "width: 1px; height: 1px; background: transparent; border: none;";
    dragElement.id = "__dragImage";

    element.append(dragElement);

    element.addEventListener(
      "dragstart",
      event => {
        dragStart?.(state);

        offset.x = event.offsetX;
        offset.y = event.offsetY;

        dragging = true;

        if (event.dataTransfer) {
          event.dataTransfer.effectAllowed = "move";
          event.dataTransfer.dropEffect = "none";
          event.dataTransfer.setDragImage(dragElement as HTMLElement, 0, 0);
        }
      },
      { signal: abortController.signal, passive: true },
    );

    document.body.addEventListener(
      "dragover",
      event => {
        if (!dragging) {
          return;
        }

        event.preventDefault();

        x = event.pageX - offset.x;
        y = event.pageY - offset.y;

        element.__dragged = true;

        element.__dragX = x;
        element.__dragY = y;

        drag?.({
          ...state,
          x,
          y,
          rects: {
            ...state.rects,
            floating: { ...state.rects.floating, x, y },
          },
        });
      },
      { signal: abortController.signal, passive: false },
    );

    element.addEventListener(
      "dragend",
      () => {
        dragEnd?.(state);

        dragging = false;
      },
      {
        passive: true,
        signal: abortController.signal,
      },
    );

    return { x, y, dragged: element.__dragged };
  },
});
