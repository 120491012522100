import type { Ref } from "vue";
import { computed } from "vue";
import { useStorage } from "@vueuse/core";

import { getStorageItemName } from "@/lib/storage";

type HistoryLocation = string;
/**
 * Allowed variables in HTML5 history state. Note that pushState clones the state
 * passed and does not accept everything: e.g.: it doesn't accept symbols, nor
 * functions as values. It also ignores Symbols as keys.
 *
 * @internal
 */
type HistoryStateValue =
  | string
  | number
  | boolean
  | null
  | undefined
  | HistoryState
  | HistoryStateArray;

/**
 * Allowed HTML history.state
 */
interface HistoryState {
  [x: number]: HistoryStateValue;

  [x: string]: HistoryStateValue;
}

/**
 * Allowed arrays for history.state.
 *
 * @internal
 */
interface HistoryStateArray extends Array<HistoryStateValue> {}

enum NavigationType {
  pop = "pop",
  push = "push",
}

enum NavigationDirection {
  back = "back",
  forward = "forward",
  unknown = "",
}

interface NavigationInformation {
  type: NavigationType;
  direction: NavigationDirection;
  delta: number;
}

interface NavigationCallback {
  (
    to: HistoryLocation,
    from: HistoryLocation,
    information: NavigationInformation,
  ): void;
}

/**
 * Starting location for Histories
 */
const START: HistoryLocation = "/";

/**
 * Interface implemented by History implementations that can be passed to the
 * router as {@link Router.history}
 *
 * @alpha
 */
export interface LocalStorageRouterHistory {
  /**
   * Base path that is prepended to every url. This allows hosting an SPA at a
   * sub-folder of a domain like `example.com/sub-folder` by having a `base` of
   * `/sub-folder`
   */
  readonly base: string;
  /**
   * Current History location
   */
  readonly location: HistoryLocation;
  /**
   * Current History state
   */
  readonly state: HistoryState;

  /**
   * Navigates to a location. In the case of an HTML5 History implementation,
   * this will call `history.pushState` to effectively change the URL.
   *
   * @param to - location to push
   * @param data - optional {@link HistoryState} to be associated with the
   * navigation entry
   */
  push(to: HistoryLocation, data?: HistoryState): void;

  onPush(cb: () => void): void;

  onBack(cb: () => void): void;

  /**
   * Same as {@link LocalStorageRouterHistory.push} but performs a `history.replaceState`
   * instead of `history.pushState`
   *
   * @param to - location to set
   * @param data - optional {@link HistoryState} to be associated with the
   * navigation entry
   */
  replace(to: HistoryLocation, data?: HistoryState): void;

  /**
   * Traverses history in a given direction.
   *
   * @example
   * ```js
   * myHistory.go(-1) // equivalent to window.history.back()
   * myHistory.go(1) // equivalent to window.history.forward()
   * ```
   *
   * @param delta - distance to travel. If delta is \< 0, it will go back,
   * if it's \> 0, it will go forward by that amount of entries.
   * @param triggerListeners - whether this should trigger listeners attached to
   * the history
   */
  go(delta: number, triggerListeners?: boolean): void;

  /**
   * Attach a listener to the History implementation that is triggered when the
   * navigation is triggered from outside (like the Browser back and forward
   * buttons) or when passing `true` to {@link LocalStorageRouterHistory.back} and
   * {@link LocalStorageRouterHistory.forward}
   *
   * @param callback - listener to attach
   * @returns a callback to remove the listener
   */
  listen(callback: NavigationCallback): () => void;

  /**
   * Generates the corresponding href to be used in an anchor tag.
   *
   * @param location - history location that should create an href
   */
  createHref(location: HistoryLocation): string;

  /**
   * Clears any event listener attached by the history implementation.
   */
  destroy(): void;

  clear(): void;
}

const isBrowser = typeof window !== "undefined";

const TRAILING_SLASH_RE = /\/$/;

const removeTrailingSlash = (path: string) =>
  path.replace(TRAILING_SLASH_RE, "");

function normalizeBase(base?: string): string {
  if (!base) {
    if (isBrowser) {
      // respect <base> tag
      const baseEl = document.querySelector("base");
      base = (baseEl && baseEl.getAttribute("href")) || "/";
      // strip full URL origin
      base = base.replace(/^\w+:\/\/[^/]+/, "");
    } else {
      base = "/";
    }
  }

  // ensure leading slash when it was removed by the regex above avoid leading
  // slash with hash because the file could be read from the disk like file://
  // and the leading slash would cause problems
  if (base[0] !== "/" && base[0] !== "#") base = "/" + base;

  // remove the trailing slash so all other method can just do `base + fullPath`
  // to build a href
  return removeTrailingSlash(base);
}

// remove any character before the hash
const BEFORE_HASH_RE = /^[^#]+#/;

function createHref(base: string, location: HistoryLocation): string {
  return base.replace(BEFORE_HASH_RE, "#") + location;
}

/**
 * @param base - Base applied to all urls, defaults to '/'
 * @returns a history object that can be passed to the router constructor
 */
export function createLocalStorageHistory(
  base: string = "",
): LocalStorageRouterHistory {
  const queue: Ref<HistoryLocation[]> = useStorage(
    getStorageItemName("ih_route_history"),
    [START],
    localStorage,
  );

  let listeners: NavigationCallback[] = [];
  const onPushListeners: (() => void)[] = [];
  const onBackListeners: (() => void)[] = [];

  base = normalizeBase(base);

  const position = computed(() => queue.value.length - 1);

  function setLocation(location: HistoryLocation) {
    queue.value.push(location);
  }

  function triggerListeners(
    to: HistoryLocation,
    from: HistoryLocation,
    { direction, delta }: Pick<NavigationInformation, "direction" | "delta">,
  ): void {
    const info: NavigationInformation = {
      direction,
      delta,
      type: NavigationType.pop,
    };
    for (const callback of listeners) {
      callback(to, from, info);
    }
  }

  const routerHistory: LocalStorageRouterHistory = {
    // rewritten by Object.defineProperty
    location: START,
    // TODO: should be kept in queue
    state: {},
    base,
    createHref: createHref.bind(null, base),

    replace(to) {
      // remove current entry and decrement position
      queue.value.splice(position.value, 1);
      setLocation(to);
    },

    push(to) {
      setLocation(to);

      for (const listener of onPushListeners) {
        listener();
      }
    },

    listen(callback) {
      listeners.push(callback);
      return () => {
        const index = listeners.indexOf(callback);
        if (index > -1) listeners.splice(index, 1);
      };
    },

    onPush(cb) {
      onPushListeners.push(cb);
    },

    onBack(cb) {
      onBackListeners.push(cb);
    },

    destroy() {
      listeners = [];
    },

    go(delta, shouldTrigger = true) {
      const from = this.location;
      const direction: NavigationDirection =
        // we are considering delta === 0 going forward, but in abstract mode
        // using 0 for the delta doesn't make sense like it does in html5 where
        // it reloads the page
        delta < 0 ? NavigationDirection.back : NavigationDirection.forward;
      if (direction === NavigationDirection.back) {
        queue.value.splice(position.value);

        for (const listener of onBackListeners) {
          listener();
        }
      }

      if (shouldTrigger) {
        triggerListeners(this.location, from, {
          direction,
          delta,
        });
      }
    },

    clear() {
      queue.value = [START];
    },
  };

  Object.defineProperty(routerHistory, "location", {
    enumerable: true,
    get: () => queue.value[position.value],
  });

  return routerHistory;
}
