import type { HintWithContext } from "@/store/state";
import { FEATURE_CHATBOT, useStateStore } from "@/store/state";

import logger from "@/core/logger";

import { useAppRouter } from "@/router";

const LOGGER_NAMESPACE = "Chatbot";

export const useChatbot = () => {
  const stateStore = useStateStore();

  const { router } = useAppRouter();

  const activate = (hintWithContext?: HintWithContext) => {
    if (stateStore.activeFeature === FEATURE_CHATBOT) {
      return;
    }

    stateStore.setActiveFeature(FEATURE_CHATBOT);

    stateStore.hintWithContext = hintWithContext;

    stateStore.launcherVisible = false;
    stateStore.widgetVisible = true;

    router.push("/chatbot");

    logger.verbose("Activated.", null, LOGGER_NAMESPACE);
  };

  const deactivate = () => {
    if (stateStore.activeFeature !== FEATURE_CHATBOT) {
      return;
    }

    stateStore.unsetActiveFeature();

    logger.verbose("Deactivated.", null, LOGGER_NAMESPACE);
  };

  return {
    activate,
    deactivate,
  };
};
