<template>
  <div class="relative min-h-16">
    <TransitionGroup
      appear
      enter-active-class="ease-in duration-300"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="ease-in duration-300"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-if="loadingVisible" key="loader">
        <div
          v-bind="$attrs"
          class="text-center text-3xl font-semibold tracking-wide text-[color:var(--ih-primary-color)] absolute w-full top-1/2 -translate-y-1/2 overflow-hidden"
          :class="[classes, retryVisible && '-mt-16']"
        >
          <div ref="animateLoaderRef">{{ text }}...</div>
        </div>

        <Transition
          appear
          enter-active-class="ease-in duration-300"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="ease-in duration-300"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            v-if="retryVisible"
            class="m-3 rounded-md bg-blue-50 p-3 mt-16"
            :class="[classes && '-mt-5']"
          >
            <div class="flex">
              <div class="flex-shrink-0">
                <InformationCircleIcon class="h-5 w-5 text-blue-400" />
              </div>

              <div class="ml-3 text-sm text-blue-700">
                <p>
                  {{
                    $pgettext(
                      "Loader title message when action takes longer than usual.",
                      "%{text} takes longer than usual.",
                      { text },
                    )
                  }}
                </p>

                <p>
                  <span>
                    {{
                      $pgettext(
                        "Part of the Loader description message when action takes longer than usual. Full message: 'Wait a little longer(,| or)(retry)( or go to Widget Home).'",
                        "Wait a little longer",
                      )
                    }}
                  </span>

                  <span v-if="homeCb && retryCb">
                    {{
                      $pgettext(
                        "Part of the Loader description message when action takes longer than usual. Full message: 'Wait a little longer(,| or)(retry)( or go to Widget Home).'",
                        ",",
                      )
                    }}
                  </span>

                  <span v-if="!homeCb && retryCb">
                    {{
                      $pgettext(
                        "Part of the Loader description message when action takes longer than usual. Full message: 'Wait a little longer(,| or)(retry)( or go to Widget Home).'",
                        " or",
                      )
                    }}
                  </span>

                  <button
                    type="button"
                    class="font-medium text-blue-700 hover:text-blue-600 underline hover:no-underline ml-1"
                    @click="retry()"
                  >
                    <span>
                      {{
                        $pgettext(
                          "Retry button part of the Loader description message when action takes longer than usual. Full message: 'Wait a little longer(,| or)(retry)( or go to Widget Home).'",
                          "retry",
                        )
                      }}
                    </span>

                    <span v-if="!homeCb">.</span>
                  </button>

                  <template v-if="homeCb">
                    <span>
                      {{
                        $pgettext(
                          "Part of the Loader description message when action takes longer than usual. Full message: 'Wait a little longer(,| or)(retry)( or go to Widget Home).'",
                          " or go to ",
                        )
                      }}
                    </span>

                    <button
                      type="button"
                      class="font-medium text-blue-700 hover:text-blue-600 underline hover:no-underline"
                      @click="homeCb()"
                    >
                      <span>
                        {{
                          $pgettext(
                            "Widget home button part of the Loader description message when action takes longer than usual. Full message: 'Wait a little longer(,| or)(retry)( or go to Widget Home).'",
                            "Widget home",
                          )
                        }}
                      </span>

                      <span>.</span>
                    </button>
                  </template>
                </p>
              </div>
            </div>
          </div>
        </Transition>
      </div>

      <div v-else key="content" class="h-full">
        <slot />
      </div>
    </TransitionGroup>
  </div>
</template>

<script setup lang="ts">
  import { nextTick, ref, watch } from "vue";
  import { InformationCircleIcon } from "@heroicons/vue/24/solid";
  import { isFunction } from "lodash-es";
  import anime from "animejs";
  import { until } from "@vueuse/core";
  import Letterize from "letterizejs";

  import logger from "@/core/logger";

  type Props = {
    loading: boolean;
    duration?: number;
    sync?: boolean;
    timeout?: number;
    retryCb?: () => void;
    homeCb?: () => void;
    text: string;
    classes?: string;
  };

  const props = withDefaults(defineProps<Props>(), {
    loading: false,
    sync: true,
    duration: 1_200,
    timeout: 10_000,
  });

  const emit = defineEmits<{
    stateChanged: [boolean];
  }>();

  const loadingVisible = ref(props.loading);

  const timer = ref();
  const retryVisible = ref(false);

  const LOGGER_NAMESPACE = "Loader";

  const animateLoaderRef = ref();
  let animating = ref(false);

  const startTimer = () => {
    if (props.retryCb) {
      logger.silly(
        "Starting loading timer.",
        { timeout: props.timeout, retryCb: props.retryCb.toString() },
        LOGGER_NAMESPACE,
      );

      timer.value = setTimeout(
        () => (retryVisible.value = true),
        props.timeout,
      );
    }
  };

  const clearTimer = () => {
    logger.silly(
      "Clearing loading timer.",
      { timeout: props.timeout, retryCb: props.retryCb?.toString() },
      LOGGER_NAMESPACE,
    );

    retryVisible.value = false;

    clearTimeout(timer.value);
  };

  watch(
    () => props.loading,
    async value => {
      if (!loadingVisible.value && !value) {
        return;
      }

      if (!value) {
        if (!props.sync) {
          loadingVisible.value = false;
          animating.value = false;

          return;
        }

        await until(animating).toMatch(state => !state);

        animating.value = true;
        retryVisible.value = false;

        clearTimer();

        anime({
          targets: animateLoaderRef.value.querySelectorAll("span"),
          delay: anime.stagger(50),
          duration: props.duration / 2,
          translateY: 16,
          opacity: 0,
          complete() {
            loadingVisible.value = false;
            animating.value = false;

            emit("stateChanged", false);
          },
        });

        return;
      }

      await until(animating).toMatch(state => !state);

      emit("stateChanged", true);

      animating.value = true;
      loadingVisible.value = true;

      await nextTick(() => {
        startTimer();

        const textElements = new Letterize({
          targets: animateLoaderRef.value,
          className: "inline-block",
        });

        textElements.listAll.forEach(element => {
          (element as HTMLElement).style.cssText =
            "opacity: 0; transform: translateY(16px);";
        });

        anime({
          targets: textElements.listAll,
          delay: anime.stagger(50),
          duration: props.duration / 2,
          translateY: 0,
          opacity: 1,
          complete() {
            animating.value = false;

            const dotTimeline = anime.timeline({
              targets: textElements.listAll.slice(-3),
              delay: anime.stagger(100),
              duration: 100,
              loop: true,
            });

            dotTimeline
              .add(
                {
                  translateY: -2,
                },
                400,
              )
              .add({
                translateY: 0,
              });
          },
        });
      });
    },
    {
      immediate: true,
    },
  );

  const retry = () => {
    if (isFunction(props.retryCb)) {
      clearTimer();

      startTimer();

      props.retryCb();
    }
  };
</script>
