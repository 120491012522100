export const isElementInDeniedScope = (
  element: HTMLElement,
  denyList: Readonly<string[]>,
) => {
  if (!denyList.length) {
    return false;
  }

  const deniedElements = document.querySelectorAll<HTMLElement>(
    denyList.join(", "),
  );

  for (const deniedElement of deniedElements) {
    if (deniedElement.contains(element) && deniedElement !== element) {
      return true;
    }
  }

  return false;
};

const isElementDenied = (
  element: HTMLElement,
  denyList: Readonly<string[]>,
) => {
  if (isElementInDeniedScope(element, denyList)) {
    return true;
  }

  if (!denyList.length) {
    return false;
  }

  for (const denyRule of denyList) {
    if (element.matches(denyRule)) {
      return true;
    }
  }

  return false;
};

export const isElementInAllowedScope = (
  element: HTMLElement,
  allowList: Readonly<string[]>,
) => {
  if (!allowList.length) {
    return true;
  }

  const allowedElements = document.querySelectorAll<HTMLElement>(
    allowList.join(","),
  );

  for (const allowedElement of allowedElements) {
    if (allowedElement.contains(element) && allowedElement !== element) {
      return true;
    }
  }

  return false;
};

export const isElementAllowed = (
  element: HTMLElement,
  allowList: Readonly<string[]>,
  denyList: Readonly<string[]>,
) => {
  if (isElementDenied(element, denyList)) {
    return false;
  }

  if (isElementInAllowedScope(element, allowList)) {
    return true;
  }

  if (!allowList.length) {
    return true;
  }

  for (const allowRule of allowList) {
    if (element.matches(allowRule)) {
      return true;
    }
  }

  return false;
};

export const resolveScopedElement = (element: HTMLElement) => {
  return (element.closest("[ih-outlined]") as HTMLElement) || element;
};
