import type { DefineComponent, Ref, ShallowRef } from "vue";
import { watch } from "vue";

import type { HintStream } from "@/lib/http";

import type { Hint, HintMetadata } from "@/modules/hints";

import { replacePlaceholders } from "@/core/content-resolvers";

import type { HintWithContext } from "@/store/state";

export const processHintStream = (
  stream: HintStream,
  body: string,
  metadata: HintMetadata,
  hintBody: ShallowRef<DefineComponent | undefined>,
  hintWithContext: Ref<HintWithContext | undefined>,
) => {
  let contentBuffer = "";
  let renderedContent = "";
  let counter = 0;
  let repeater: ReturnType<typeof setInterval> | undefined;
  let isFinished = false;

  let fullHint: Hint;

  const startRendering = () => {
    repeater = setInterval(() => {
      const char = contentBuffer[counter];

      if (isFinished && counter === contentBuffer.length) {
        clearInterval(repeater);

        repeater = undefined;

        hintWithContext.value = {
          ...fullHint,
          body,
          metadata,
        };

        return;
      }

      if (!char) {
        return;
      }

      counter++;

      renderedContent += char;

      hintBody.value = replacePlaceholders(renderedContent);
    }, 15);
  };

  const stopWatcher = watch(stream, ({ content, hint, error, finished }) => {
    if (hint) {
      fullHint = hint;

      stopWatcher();

      return;
    }

    if (content) {
      contentBuffer = content;

      if (!repeater) {
        startRendering();
      }
    }

    if (error) {
      stopWatcher();
    }

    isFinished = finished;
  });
};
