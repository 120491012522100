import type { Traits } from "@/store/user";
import { useUserStore } from "@/store/user";

import logger from "@/core/logger";

import { useExplainThis, useTooltips } from "@/modules/hints";

import { reset, resetUI } from "@/lib/bootstrap";

export type PublicAPI = ReturnType<typeof usePublicAPI>;

export const usePublicAPI = () => {
  const identify = (userId: string, traits?: Traits) => {
    const userStore = useUserStore();

    userStore.identify(userId, traits);
  };

  const update = () => {
    const tooltips = useTooltips();

    tooltips.deactivate();

    tooltips.activate();
  };

  const explainThis = () => {
    const explainThis = useExplainThis();

    explainThis.deactivate();

    explainThis.activate();
  };

  return {
    identify,
    setLogLevel: logger.setLogLevel,
    getLogLevel: logger.getLogLevel,
    reset,
    resetUI,
    update,
    explainThis,
  };
};
