<template>
  <button
    class="p-2 w-full bg-white gap-x-2 flex items-center rounded-lg outline outline-1 -outline-offset-1 outline-gray-300 drop-shadow hover:bg-gray-50 cursor-pointer"
    @click="openChatbot()"
  >
    <div
      class="flex shrink-0 h-8 w-8 items-center justify-center rounded-full bg-[color:var(--ih-secondary-color)]"
    >
      <ChatBubbleLeftRightIcon
        class="text-[color:var(--ih-primary-color)] h-6 w-6"
      />
    </div>

    <p class="text-sm font-semibold text-left leading-6 text-gray-900 grow">
      <slot
        >{{
          $pgettext(
            "Default text of a Hints Ask a question button.",
            "Ask a question about this",
          )
        }}
      </slot>
    </p>

    <ChevronRightIcon class="w-6 h-6 text-gray-500 shrink-0" />
  </button>
</template>

<script setup lang="ts">
  import { ChatBubbleLeftRightIcon } from "@heroicons/vue/24/outline";
  import { ChevronRightIcon } from "@heroicons/vue/20/solid";

  import { useChatbot } from "@/modules/chatbot";

  import type { HintWithContext } from "@/store/state";

  type Props = {
    hintWithContext: HintWithContext;
  };

  const props = defineProps<Props>();

  const chatbot = useChatbot();

  const openChatbot = () => {
    chatbot.activate(props.hintWithContext);
  };
</script>
