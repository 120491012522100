const moveCursorToEnd = (el: HTMLInputElement) => {
  const length = el.value.length;

  try {
    if (el.setSelectionRange) {
      el.setSelectionRange(length, length);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    // ignore "DOMException: An attempt was made to use an object that is not, or is no longer, usable"
    // which has unknown origin and strangely does not affect the code run in "try" block
    if (
      !(e instanceof DOMException) &&
      e.message ===
        "An attempt was made to use an object that is not, or is no longer, usable"
    ) {
      throw e;
    }
  }
};

export default (element: HTMLInputElement) => {
  requestAnimationFrame(() => {
    element.focus();

    moveCursorToEnd(element);
  });
};
