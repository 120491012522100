import { FEATURE_TICKET_FORM, useStateStore } from "@/store/state";

import logger from "@/core/logger";

import { useAppRouter } from "@/router";

export type TicketFormMetadata = {
  name: string;
  email: string;
  subject: string;
  message: string;
};

const LOGGER_NAMESPACE = "TicketForm";

export const useTicketForm = () => {
  const stateStore = useStateStore();

  const { router } = useAppRouter();

  const activate = () => {
    if (stateStore.activeFeature === FEATURE_TICKET_FORM) {
      return;
    }

    stateStore.setActiveFeature(FEATURE_TICKET_FORM);

    stateStore.launcherVisible = false;
    stateStore.widgetVisible = true;

    router.push("/ticket_form");

    logger.verbose("Activated.", null, LOGGER_NAMESPACE);
  };

  const deactivate = () => {
    if (stateStore.activeFeature !== FEATURE_TICKET_FORM) {
      return;
    }

    stateStore.unsetActiveFeature();

    logger.verbose("Deactivated.", null, LOGGER_NAMESPACE);
  };

  return {
    activate,
    deactivate,
  };
};
