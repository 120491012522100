<template>
  <li
    class="p-3 bg-white items-center gap-x-4 rounded-lg outline outline-1 -outline-offset-1 outline-gray-300 drop-shadow hover:bg-gray-50"
    :class="[
      imagePositionLeft && 'flex',
      (isLink || !customType) && 'cursor-pointer',
    ]"
    @click="goToLink"
  >
    <div v-if="section.image && imagePositionTop" class="w-full mb-2">
      <img :src="section.image" class="rounded-t-md w-full" alt="" />
    </div>

    <div class="min-w-0 flex-auto flex items-start gap-x-4">
      <div
        v-if="section.image || !customType"
        class="mx-auto flex h-12 w-12 items-center justify-center shrink-0 empty:hidden"
        :class="[
          !section.image && 'rounded-full bg-[color:var(--ih-secondary-color)]',
        ]"
      >
        <img
          v-if="section.image && imagePositionLeft"
          class="max-h-full"
          :src="section.image"
          alt=""
        />

        <slot v-else-if="!customType" name="image">
          <img
            class="text-[color:var(--ih-primary-color)] h-12 w-12"
            src="@/assets/images/default-launcher.svg"
            alt=""
          />
        </slot>
      </div>

      <div class="flex-auto min-w-0">
        <p class="text-sm font-semibold leading-6 text-gray-900">
          {{ section.title }}
        </p>

        <p
          v-if="section.description"
          class="mt-1 text-xs leading-5 text-gray-500"
        >
          {{ section.description }}
        </p>
      </div>

      <ChevronRightIcon
        v-if="isLink"
        class="w-6 h-6 text-gray-500 shrink-0 self-center"
      />
    </div>
  </li>
</template>

<script setup lang="ts">
  import { computed } from "vue";
  import { ChevronRightIcon } from "@heroicons/vue/20/solid";
  import type { NavigationFailure } from "vue-router";

  import {
    type Section,
    SECTION_IMAGE_POSITION_LEFT,
    SECTION_IMAGE_POSITION_TOP,
    SECTION_LINK_TYPE_EXTERNAL,
    SECTION_LINK_TYPE_INTERNAL,
    SECTION_TYPE_CUSTOM,
    SECTION_TYPE_KNOWLEDGE_BASE,
  } from "@/store/config";

  import {
    KNOWLEDGE_BASE_CONTENTS_ARTICLE,
    KNOWLEDGE_BASE_CONTENTS_CATEGORY,
    KNOWLEDGE_BASE_CONTENTS_FOLDER,
  } from "@/store/knowledge-base";

  import { useKnowledgeBase } from "@/modules/knowledge-base";

  import logger from "@/core/logger";

  export type Props = {
    section: Section;
    navigate: (e?: MouseEvent) => Promise<void | NavigationFailure> | void;
  };

  const props = defineProps<Props>();

  const LOGGER_NAMESPACE = "HomeSectionLink";

  const customType = computed(() => props.section.type === SECTION_TYPE_CUSTOM);

  const imagePositionTop = computed(
    () => props.section.imagePosition === SECTION_IMAGE_POSITION_TOP,
  );

  const imagePositionLeft = computed(
    () => props.section.imagePosition === SECTION_IMAGE_POSITION_LEFT,
  );

  const isLink = computed(() => !!props.section.link?.type);

  const isInternalLinkType = computed(
    () => props.section.link?.type === SECTION_LINK_TYPE_INTERNAL,
  );

  const isExternalLinkType = computed(
    () => props.section.link?.type === SECTION_LINK_TYPE_EXTERNAL,
  );

  const openInNewTab = computed(
    () => props.section.link?.openInNewTab === true,
  );

  const goToLink = (...args: Parameters<Props["navigate"]>) => {
    if (!isLink.value && customType.value) {
      return;
    }

    if (isExternalLinkType.value) {
      if (!props.section.link?.url) {
        logger.error(
          "Section navigation cannot proceed. There is no URL defined.",
          props.section,
          LOGGER_NAMESPACE,
        );

        return;
      }

      window.open(
        props.section.link.url,
        openInNewTab.value ? "_blank" : "_self",
      );

      return;
    }

    if (isInternalLinkType.value) {
      if (!props.section.link?.url) {
        logger.error(
          "Section navigation cannot proceed. There is no URL defined.",
          props.section,
          LOGGER_NAMESPACE,
        );

        return;
      }

      const [type, id] = props.section.link.url.split(":");

      const knowledgeBase = useKnowledgeBase();

      let path = `/${SECTION_TYPE_KNOWLEDGE_BASE}`;

      switch (type) {
        case KNOWLEDGE_BASE_CONTENTS_ARTICLE: {
          path += `/article/${id}`;

          break;
        }
        case KNOWLEDGE_BASE_CONTENTS_CATEGORY:
        case KNOWLEDGE_BASE_CONTENTS_FOLDER: {
          path += `/contents/${type}/${id}`;

          break;
        }
      }

      knowledgeBase.activate(path);

      return;
    }

    return props.navigate(...args);
  };
</script>
