import { reactive, ref } from "vue";

import type { DeepPartial, JSONObject, JSONValue } from "@/types";

import type {
  ConversationSource,
  Message,
  MessageAction,
  MessageFinishReason,
  MessageRating,
  MessageSentBy,
  MessageType,
} from "@/store/conversation";

import type { Config } from "@/store/config";

import {
  convertKeysToCamelCaseRecursive,
  convertKeysToSnakeCaseRecursive,
} from "@/util/object";

import type {
  KnowledgeBaseArticle,
  KnowledgeBaseCategory,
  KnowledgeBaseContents,
  KnowledgeBaseContentsCategoryOrFolder,
  KnowledgeBasePageResource,
} from "@/store/knowledge-base";
import { KNOWLEDGE_BASE_CONTENTS_CATEGORY } from "@/store/knowledge-base";

import { getContext } from "@/core/context";

import type { Hint, HintMetadata, HintType } from "@/modules/hints";

import type { TicketFormMetadata } from "@/modules/ticket-form";

import { useUserStore } from "@/store/user";

import type { Rating } from "@/components/interfaces";

import { useWSClientApi } from "@/lib/websocket";

import logger from "@/core/logger";

type ClientApiOptions = {
  endpoint: string;
  clientApiKey: string;
};

export type FetchPageResourcesContext = {
  title: string;
  url: string;
};

type StatusMessage = {
  status: "closed";
};

type HintMessage = Message & {
  content: string;
};

type ResponseMessage = {
  id: string;
  type: MessageType;
  content: string;
  action: MessageAction;
  finish_reason?: MessageFinishReason;
  context?: JSONObject;
  sent_by: MessageSentBy;
  created_at: string;
  rating?: MessageRating;
  author?: Omit<Message["author"], "userId"> & {
    user_id?: string;
  };
  metadata?: {
    selector: string;
    html: string;
    sources?: Message["metadata"]["sources"];
  };
};

export type HintStream = {
  content: string;
  finished: boolean;
  hint?: Hint;
  error: boolean;
};

let options: ClientApiOptions | undefined;

const CLIENT_API_PREFIX = "client-api/v1";

export const useHttpClientApi = (opts?: ClientApiOptions) => {
  if (opts && options) {
    throw new Error("Client HTTP API has already been initialized");
  }

  if (opts) {
    options = structuredClone(opts);
  }

  const endpoint = `${options?.endpoint}/${CLIENT_API_PREFIX}`;

  const clientApiKey = options?.clientApiKey;

  const $reset = () => {
    options = undefined;
  };

  const fetchWrapper = (
    method: string,
    url: string,
    requestInit?: RequestInit,
  ) => {
    if (requestInit?.body) {
      requestInit.body = JSON.stringify(requestInit.body);
    }

    return fetch(`${endpoint}${url}`, {
      method,
      headers: new Headers([
        ["Authorization", `Bearer ${clientApiKey}`],
        ["Content-Type", "application/json"],
      ]),
      ...requestInit,
    });
  };

  const fetchConfig = async () => {
    const response = await fetchWrapper(
      "GET",
      "/sites/config?with_custom_sections=true",
    );

    if (!response.ok) {
      throw "Unable to fetch the Client configuration. Have you configured the Client API Key correctly?";
    }

    const responseJSON = await response.json();

    return convertKeysToCamelCaseRecursive<JSONValue, DeepPartial<Config>>(
      responseJSON,
    );
  };

  const createConversation = async (
    source: ConversationSource,
    hintId?: string,
  ) => {
    const request: { conversation: { source: string; hintId?: string } } = {
      conversation: {
        source,
      },
    };

    if (hintId) {
      request.conversation.hintId = hintId;
    }

    const response = await fetchWrapper("POST", "/conversations", {
      body: convertKeysToSnakeCaseRecursive(request),
    });

    if (!response.ok) {
      throw "Unable to create the Conversation. Have you configured the Client API Key correctly?";
    }

    return response.json();
  };

  const fetchPageResources = async (page: FetchPageResourcesContext) => {
    const response = await fetchWrapper(
      "POST",
      "/knowledge-base/page-resources",
      {
        body: convertKeysToSnakeCaseRecursive({
          page,
        }),
      },
    );

    if (!response.ok) {
      throw "Unable to fetch the Resources for this page. Have you configured the Client API Key correctly?";
    }

    const responseJSON = await response.json();

    const { pageResources } = convertKeysToCamelCaseRecursive<
      JSONValue,
      { pageResources: KnowledgeBasePageResource[] }
    >(responseJSON);

    return pageResources;
  };

  const fetchArticle = async (id: string) => {
    const response = await fetchWrapper(
      "GET",
      `/knowledge-base/articles/${id}`,
    );

    if (!response.ok) {
      throw "Unable to fetch the Article. Have you configured the Client API Key correctly?";
    }

    const { article } = await response.json();

    return convertKeysToCamelCaseRecursive<JSONValue, KnowledgeBaseArticle>(
      article,
    );
  };

  const fetchCategories = async () => {
    const response = await fetchWrapper("GET", "/knowledge-base/categories");

    if (!response.ok) {
      throw "Unable to fetch the Knowledge Base Categories. Have you configured the Client API Key correctly?";
    }

    const { categories } = await response.json();

    return convertKeysToCamelCaseRecursive<JSONValue, KnowledgeBaseCategory[]>(
      categories,
    );
  };

  const fetchContents = async (
    id: string,
    type: KnowledgeBaseContentsCategoryOrFolder,
  ) => {
    const url = `/knowledge-base/contents?${
      type === KNOWLEDGE_BASE_CONTENTS_CATEGORY ? "category_id" : "folder_id"
    }=${id}`;

    const response = await fetchWrapper("GET", url);

    if (!response.ok) {
      throw "Unable to fetch the Knowledge Base Contents. Have you configured the Client API Key correctly?";
    }

    const { content } = await response.json();

    return convertKeysToCamelCaseRecursive<JSONValue, KnowledgeBaseContents[]>(
      content,
    );
  };

  const searchArticle = async (query: string) => {
    const response = await fetchWrapper(
      "GET",
      `/knowledge-base/articles?query=${encodeURIComponent(query)}`,
    );

    if (!response.ok) {
      throw "Unable to search the Knowledge Base Article. Have you configured the Client API Key correctly?";
    }

    const { articles } = await response.json();

    return convertKeysToCamelCaseRecursive<JSONValue, KnowledgeBaseArticle[]>(
      articles,
    );
  };

  const rateMessage = async (
    conversationId: string,
    messageId: string,
    rating: MessageRating,
  ) => {
    const response = await fetchWrapper(
      "PATCH",
      `/conversations/${conversationId}/messages/${messageId}`,
      {
        body: convertKeysToSnakeCaseRecursive({
          message: {
            rating,
          },
        }),
      },
    );

    if (!response.ok) {
      throw "Unable to rate the Message. Have you configured the Client API Key correctly?";
    }

    return response.json();
  };

  const sendTicketForm = async (body: string, metadata: TicketFormMetadata) => {
    const userStore = useUserStore();

    const user = userStore.user;

    const response = await fetchWrapper("POST", "/ticket-form", {
      body: convertKeysToSnakeCaseRecursive({
        ticketForm: {
          body,
          metadata,
          context: getContext(),
          ...(user?.userId
            ? {
                author: {
                  id: user.id,
                  userId: user.userId,
                  ...(user?.traits || {}),
                },
              }
            : {}),
        },
      }),
    });

    if (!response.ok) {
      throw "Unable to send the Ticket. Have you configured the Client API Key correctly?";
    }

    return response.json();
  };

  const fetchHintStream = async (
    hintId: string,
    type: HintType,
    body: string,
    metadata: HintMetadata,
  ) => {
    const channelNameWithParams = {
      channel: "Client::HintChannel",
      id: hintId,
    };

    const subscription = ref();

    const data = reactive<HintStream>({
      content: "",
      finished: false,
      hint: undefined,
      error: false,
    });

    const { consumer } = useWSClientApi();

    const LOGGER_NAMESPACE = "FetchHintStream";

    const disconnectFromWebSocket = () => {
      subscription.value?.unsubscribe();

      consumer.disconnect();
    };

    subscription.value = consumer.subscriptions.create(channelNameWithParams, {
      async connected(): Promise<void> {
        logger.verbose(
          "Subscription connected.",
          channelNameWithParams,
          LOGGER_NAMESPACE,
        );

        const response = await fetchWrapper("POST", "/hints", {
          body: convertKeysToSnakeCaseRecursive({
            hint: {
              id: hintId,
              hintType: type,
              body,
              metadata,
              context: getContext(),
            },
          }),
        });

        if (!response.ok) {
          data.error = true;

          disconnectFromWebSocket();

          throw "Unable to request the Hint. Have you configured the Client API Key correctly?";
        }

        const { hint } = await response.json();

        data.hint = convertKeysToCamelCaseRecursive<JSONObject, Hint>(hint);

        disconnectFromWebSocket();
      },
      async received(message: ResponseMessage | StatusMessage): Promise<void> {
        logger.silly("Data received.", message, LOGGER_NAMESPACE);

        if ((message as StatusMessage).status === "closed") {
          subscription.value?.unsubscribe();

          consumer.disconnect();

          return;
        }

        const mappedMessage = convertKeysToCamelCaseRecursive<
          ResponseMessage,
          HintMessage
        >(message as ResponseMessage);

        if (mappedMessage.finishReason === "stop") {
          data.finished = true;

          return;
        }

        data.content += mappedMessage.content;
      },
      rejected(): void {
        data.error = true;

        disconnectFromWebSocket();
      },
      async disconnected(): Promise<void> {
        logger.verbose("Subscription disconnected.", null, LOGGER_NAMESPACE);
      },
    });

    return data;
  };

  const rateHint = async (hint: Hint, rating: Rating) => {
    const response = await fetchWrapper("PATCH", `/hints/${hint.id}`, {
      body: convertKeysToSnakeCaseRecursive({
        hint: {
          rating,
        },
      }),
    });

    if (!response.ok) {
      throw "Unable to rate the Hint. Have you configured the Client API Key correctly?";
    }

    return response.json();
  };

  return {
    $reset,
    createConversation,
    fetchConfig,
    fetchPageResources,
    fetchArticle,
    fetchCategories,
    fetchContents,
    searchArticle,
    rateMessage,
    sendTicketForm,
    fetchHintStream,
    rateHint,
  };
};
