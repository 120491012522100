import { isArray, isBoolean, isObject } from "lodash-es";

import type { JSONObject } from "@/types";

import { convertObjectKeysToKebabCase } from "@/util/object";

const sanitizeValue = <T>(value: T) => {
  if (value === null) {
    return undefined;
  }

  if (isBoolean(value)) {
    return value ? "" : undefined;
  }

  if (isArray(value) || isObject(value)) {
    return JSON.stringify(value);
  }

  return `${value}`;
};

export const convertPropsToHtmlAttributes = <T extends JSONObject>(
  props: T,
) => {
  return Object.fromEntries(
    Object.entries(convertObjectKeysToKebabCase(props)).map(
      ([property, value]) => [property, sanitizeValue(value)],
    ),
  );
};
