<template>
  <DefaultHomeSectionListItem v-bind="props">
    <template #image>
      <BookOpenIcon class="text-[color:var(--ih-primary-color)] h-8 w-8" />
    </template>
  </DefaultHomeSectionListItem>
</template>

<script setup lang="ts">
  import { BookOpenIcon } from "@heroicons/vue/24/outline";

  import type { Props } from "@/components/DefaultHomeSectionListItem.global.vue";
  import DefaultHomeSectionListItem from "@/components/DefaultHomeSectionListItem.global.vue";

  const props = defineProps<Props>();
</script>
