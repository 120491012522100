<template>
  <div class="overflow-y-auto grow flex flex-col relative">
    <Transition
      appear
      enter-active-class="ease-in duration-200"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <AppLoader
        :loading="!article || !article.content"
        :sync="false"
        class="grow"
        classes="p-10"
        :retry-cb="fetchArticle"
        :home-cb="goToWidgetHome"
        :text="
          $pgettext(
            'Loading text displayed when Knowledge Base Article is being fetched.',
            'Loading',
          )
        "
      >
        <KnowledgeBaseBodyWrapper>
          <div class="grow flex flex-col mb-3 px-3" v-if="article">
            <div
              class="my-3 overflow-hidden grow relative"
              data-knowledge-base-article
            >
              <component :is="articleContent" />
            </div>

            <div v-if="article.relatedArticles?.length" class="mb-3">
              <h2 class="mt-6 font-semibold">
                {{
                  $pgettext(
                    "Knowledge Base Article related articles list header.",
                    "Related articles",
                  )
                }}
              </h2>

              <nav class="mt-2">
                <ul role="list" class="flex flex-1 flex-col -mx-1">
                  <li
                    v-for="relatedArticle in article.relatedArticles"
                    :key="relatedArticle.id"
                  >
                    <RouterLink
                      :to="`/${SECTION_TYPE_KNOWLEDGE_BASE}/article/${relatedArticle.id}`"
                      class="gap-x-2 p-2 text-sm leading-6 text-gray-700 rounded-md flex items-center hover:bg-gray-50"
                    >
                      <DocumentTextIcon
                        class="h-4 w-4 text-gray-400 shrink-0"
                      />

                      {{ relatedArticle.title }}
                    </RouterLink>
                  </li>
                </ul>
              </nav>
            </div>

            <div class="-mx-3 p-3 mt-3 text-sm leading-6 flex justify-end">
              <a
                :href="article.sourceUrl"
                target="_blank"
                class="text-gray-600 hover:text-gray-500 hover:underline flex items-center gap-1"
              >
                {{
                  $pgettext(
                    "Knowledge Base Article link to the Article original source.",
                    "Source",
                  )
                }}

                <ArrowTopRightOnSquareIcon class="h-5 w-5" />
              </a>
            </div>
          </div>
        </KnowledgeBaseBodyWrapper>
      </AppLoader>
    </Transition>
  </div>
</template>

<script setup lang="ts">
  import { computed, onBeforeUnmount, onMounted, toValue } from "vue";
  import { storeToRefs } from "pinia";
  import { DocumentTextIcon } from "@heroicons/vue/24/outline";
  import { ArrowTopRightOnSquareIcon } from "@heroicons/vue/20/solid";

  import { useKnowledgeBase } from "@/modules/knowledge-base";
  import { useKnowledgeBaseStore } from "@/store/knowledge-base";

  import { SECTION_TYPE_KNOWLEDGE_BASE } from "@/store/config";

  import { replacePlaceholders } from "@/core/content-resolvers";

  import { useAppRouter } from "@/router";

  import AppLoader from "@/components/AppLoader.vue";
  import KnowledgeBaseBodyWrapper from "@/modules/knowledge-base/KnowledgeBaseBodyWrapper.vue";

  const { router } = useAppRouter();
  const route = toValue(router.currentRoute);

  const knowledgeBaseStore = useKnowledgeBaseStore();
  const { article } = storeToRefs(knowledgeBaseStore);

  const knowledgeBase = useKnowledgeBase();

  const articleContent = computed(() => {
    if (!article.value) {
      return "";
    }

    return replacePlaceholders(article.value.content, {
      preserveNewLines: false,
    });
  });

  const fetchArticle = () => {
    knowledgeBaseStore.fetchArticle(route.params.id as string);
  };

  const goToWidgetHome = () => {
    router.push("/");
  };

  onMounted(() => {
    knowledgeBase.activate(route.path);

    fetchArticle();
  });

  onBeforeUnmount(() => {
    knowledgeBase.deactivate();
  });
</script>
