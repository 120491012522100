<template>
  <nav class="" aria-label="Breadcrumb">
    <ol role="list" class="flex flex-wrap items-center gap-1">
      <li>
        <RouterLink to="/" class="text-gray-400 hover:text-gray-500">
          <HomeIcon class="h-4 w-4 shrink-0" />

          <span class="sr-only">{{
            $pgettext(
              "Screen reader text only of Knowledge Base Breadcrumbs Home link icon.",
              "Home",
            )
          }}</span>
        </RouterLink>
      </li>

      <li v-if="defaultBreadcrumbVisible">
        <div class="flex items-center">
          <ChevronRightIcon class="h-4 w-4 shrink-0 text-gray-400" />

          <RouterLink
            :to="`/${SECTION_TYPE_KNOWLEDGE_BASE}`"
            class="ml-1 text-sm font-medium text-gray-400 hover:text-gray-500 hover:underline"
            >{{
              $pgettext(
                "Knowledge Base Breadcrumbs Knowledge Base link.",
                "Knowledge Base",
              )
            }}
          </RouterLink>
        </div>
      </li>

      <li v-for="(breadcrumb, index) in breadcrumbs" :key="breadcrumb.id">
        <div class="flex items-center">
          <ChevronRightIcon class="h-4 w-4 shrink-0 text-gray-400" />

          <span
            v-if="index === breadcrumbs.length - 1"
            class="ml-1 text-sm font-bold text-gray-400"
          >
            {{ breadcrumb.title }}
          </span>

          <RouterLink
            v-else
            :to="`/${SECTION_TYPE_KNOWLEDGE_BASE}/contents/${breadcrumb.type}/${breadcrumb.id}`"
            class="ml-1 text-sm font-medium text-gray-400 hover:text-gray-500 hover:underline"
            aria-current="page"
            >{{ breadcrumb.title }}
          </RouterLink>
        </div>
      </li>
    </ol>
  </nav>
</template>

<script setup lang="ts">
  import { computed, ref } from "vue";
  import { storeToRefs } from "pinia";
  import { HomeIcon } from "@heroicons/vue/24/solid";
  import { ChevronRightIcon } from "@heroicons/vue/20/solid";
  import { useRoute } from "vue-router";

  import { SECTION_TYPE_KNOWLEDGE_BASE } from "@/store/config";

  import type { KnowledgeBaseContentsMapValue } from "@/store/knowledge-base";
  import { useKnowledgeBaseStore } from "@/store/knowledge-base";

  const knowledgeBaseStore = useKnowledgeBaseStore();

  const { contentsMap, filteredCategories } = storeToRefs(knowledgeBaseStore);

  const route = useRoute();

  const id = route.params.id as string;

  const breadcrumbs = ref<KnowledgeBaseContentsMapValue[]>([]);

  const resolvePath = (id: string) => {
    if (!contentsMap.value[id]) {
      return;
    }

    breadcrumbs.value.unshift(contentsMap.value[id]);

    if (contentsMap.value[id].parent) {
      resolvePath(contentsMap.value[id].parent as string);
    }
  };

  resolvePath(id);

  const defaultBreadcrumbVisible = computed(() => {
    if (!filteredCategories.value) {
      return true;
    }

    return filteredCategories.value?.length > 1;
  });
</script>
