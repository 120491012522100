<template>
  <RatingThumbs
    :rating="hintRating"
    @rate-positive="ratePositive()"
    @rate-negative="rateNegative()"
  />
</template>

<script setup lang="ts">
  import { ref } from "vue";

  import type { Hint } from "@/modules/hints";

  import { useHttpClientApi } from "@/lib/http";

  import type { Rating } from "@/components/interfaces.d";
  import { RATING_NEGATIVE, RATING_POSITIVE } from "@/components/interfaces.d";

  import RatingThumbs from "@/components/RatingThumbs.vue";

  type Props = {
    hint: Hint;
  };

  const props = defineProps<Props>();

  const httpClientApi = useHttpClientApi();

  const hintRating = ref<Rating | undefined>(props.hint.rating);

  const ratePositive = () => {
    hintRating.value = RATING_POSITIVE;

    httpClientApi.rateHint(props.hint, RATING_POSITIVE);
  };

  const rateNegative = () => {
    hintRating.value = RATING_NEGATIVE;

    httpClientApi.rateHint(props.hint, RATING_NEGATIVE);
  };
</script>
