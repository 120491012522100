import { computed, readonly } from "vue";
class ParameterError extends Error {
}
const LOG_LEVEL_SILENT = 0;
const LOG_LEVEL_WARN = 1;
const LOG_LEVEL_ERROR = 2;
const LOG_LEVEL_INFO = 3;
const LOG_LEVEL_VERBOSE = 4;
const LOG_LEVEL_SILLY = 5;
const LOG_LEVELS = [
  LOG_LEVEL_SILENT,
  LOG_LEVEL_WARN,
  LOG_LEVEL_ERROR,
  LOG_LEVEL_INFO,
  LOG_LEVEL_VERBOSE,
  LOG_LEVEL_SILLY
];
const LOG_LEVELS_MAP = {
  [LOG_LEVEL_SILENT]: "silent",
  [LOG_LEVEL_WARN]: "warn",
  [LOG_LEVEL_ERROR]: "error",
  [LOG_LEVEL_INFO]: "info",
  [LOG_LEVEL_VERBOSE]: "log",
  [LOG_LEVEL_SILLY]: "debug"
};
const LOG_LEVELS_NAMES = {
  [LOG_LEVEL_SILENT]: "silent",
  [LOG_LEVEL_ERROR]: "error",
  [LOG_LEVEL_WARN]: "warn",
  [LOG_LEVEL_INFO]: "info",
  [LOG_LEVEL_VERBOSE]: "verbose",
  [LOG_LEVEL_SILLY]: "silly"
};
const useLogger = (name, storage, defaultLevel) => {
  const logIfPossible = (desiredLevel, message, data, namespace) => {
    if (desiredLevel === LOG_LEVEL_SILENT || desiredLevel > getLogLevel()) {
      return;
    }
    console[LOG_LEVELS_MAP[desiredLevel]](
      ...[
        `[InlineHelp:${name}${namespace ? `:${namespace}` : ""}] ${message}`,
        data
      ].filter(Boolean)
    );
  };
  const warn = (message, data, namespace) => logIfPossible(LOG_LEVEL_WARN, message, data, namespace);
  const error = (message, data, namespace) => logIfPossible(LOG_LEVEL_ERROR, message, data, namespace);
  const info = (message, namespace) => logIfPossible(LOG_LEVEL_INFO, message, null, namespace);
  const verbose = (message, data, namespace) => logIfPossible(LOG_LEVEL_VERBOSE, message, data, namespace);
  const silly = (message, data, namespace) => logIfPossible(LOG_LEVEL_SILLY, message, data, namespace);
  const getLogLevel = () => Number(storage.value);
  const logLevel = computed(() => getLogLevel());
  const setLogLevel = (level) => {
    if (!LOG_LEVELS.includes(level)) {
      throw new ParameterError(
        `Unable to set the log level, invalid level (${level}) provided.`
      );
    }
    storage.value = level;
  };
  if ((storage.value === void 0 || storage.value === null) && defaultLevel !== void 0) {
    storage.value = defaultLevel;
  }
  return {
    setLogLevel,
    getLogLevel,
    warn,
    error,
    info,
    verbose,
    silly,
    logLevel: readonly(logLevel)
  };
};
const getStorageItemName = (name, buildId) => `${name}${buildId ? `_${buildId}` : ""}`;
export {
  LOG_LEVELS,
  LOG_LEVELS_MAP,
  LOG_LEVELS_NAMES,
  LOG_LEVEL_ERROR,
  LOG_LEVEL_INFO,
  LOG_LEVEL_SILENT,
  LOG_LEVEL_SILLY,
  LOG_LEVEL_VERBOSE,
  LOG_LEVEL_WARN,
  getStorageItemName,
  useLogger
};
