<template>
  <div
    class="right-0 bg-[color:var(--ih-primary-color)] inline-flex items-center justify-center cursor-pointer transition duration-300 hover:scale-110"
    :class="iconClass"
  >
    <div
      v-if="isIconLogoDefault"
      v-html="iconLogo"
      class="text-white shrink-0 h-10 w-10"
    ></div>

    <img
      v-else
      class="h-10 w-10"
      :class="iconLogoClass"
      :src="iconLogo"
      alt=""
    />
  </div>
</template>

<script setup lang="ts">
  import type { DeepReadonly } from "vue";

  import type { Config } from "@/store/config";

  import defaultIconLogo from "@/assets/images/default-launcher.svg?raw";

  type Props = {
    config: DeepReadonly<Config>;
  };

  const props = defineProps<Props>();

  const isIconLogoDefault = !props.config.chatbot.icon;

  const iconLogo =
    props.config.widget.style.launcher.icon.src || defaultIconLogo;

  let iconClass = "squircle h-12 w-12 rounded-xl";

  let iconLogoClass = "rounded-xl";

  if (props.config.widget.style.launcher.icon.shape === "circle") {
    iconClass = "rounded-full h-14 w-14";
    iconLogoClass = "rounded-full";
  }

  if (props.config.widget.style.launcher.icon.shape === "square") {
    iconClass = "rounded-xl h-14 w-14";
    iconLogoClass = "rounded-md";
  }
</script>
