import { computed, readonly } from "vue";

import { FEATURE_EXPLAIN_THIS, useStateStore } from "@/store/state";

import logger from "@/core/logger";

import { useAppRouter } from "@/router";

import { getPathTo } from "@/util/xpath";

import { outlineAllowed, outlineDenied, removeAllOutlines } from "./outline";

import { handleClick, handleMouseOver } from "./events";

import { useConfigStore } from "@/store/config";

import { useTooltips } from "@/modules/hints";

const allowedStyle = `
  outline-style: solid;
  outline-width: 1px;
  outline-color: #16a34a;
  outline-offset: 2px;
  border-radius: 5px;
  background-color: rgba(22, 163, 74, 0.02);
`;

const deniedStyle = `
  outline-style: dotted;
  outline-width: 1px;
  outline-color: #f00;
  outline-offset: 2px;
  border-radius: 5px;
  background-color: rgba(255, 0, 0, 0.02);
`;

const hoveredStyle = `
  outline-style: dashed;
  outline-width: 2px;
  outline-color: #2563eb;
  outline-offset: 2px;
  border-radius: 5px;
`;

const LOGGER_NAMESPACE = "ExplainThis";

let eventController: AbortController;

export type IhHTMLElement = HTMLElement & {
  __ih_originalStyle?: string;
  __ih_styleHistory?: {
    style: string;
    temporal?: boolean;
  }[];
  __ih_originalAlreadySet?: boolean;
  __ih_originalPointerEvents?: string;
};

export const IH_NODE_NAME = "INLINE-HELP";

export const useExplainThis = () => {
  const stateStore = useStateStore();

  const configStore = useConfigStore();

  const tooltips = useTooltips();

  const { router } = useAppRouter();

  const isActive = computed(
    () => stateStore.activeFeature === FEATURE_EXPLAIN_THIS,
  );

  const allowList = configStore.config.explainThis.allowList;
  const denyList = configStore.config.explainThis.denyList;

  const clickHandler = (event: MouseEvent) =>
    handleClick(event, explain, allowList, denyList);

  const mouseEnterCallback = (element: IhHTMLElement) => {
    if (element.nodeName === IH_NODE_NAME) {
      return;
    }

    element.addEventListener("click", clickHandler, {
      signal: eventController.signal,
      capture: true,
    });
  };

  const mouseLeaveCallback = (element: IhHTMLElement) => {
    element.removeEventListener("click", clickHandler, { capture: true });
  };

  const mouseOverHandler = (event: MouseEvent) =>
    handleMouseOver(
      event,
      hoveredStyle,
      allowList,
      denyList,
      mouseEnterCallback,
      mouseLeaveCallback,
    );

  const activate = () => {
    if (stateStore.activeFeature === FEATURE_EXPLAIN_THIS) {
      return;
    }

    stateStore.widgetVisible = false;
    stateStore.launcherVisible = true;
    stateStore.explainThisToastVisible = true;

    tooltips.deactivate();

    stateStore.setActiveFeature(FEATURE_EXPLAIN_THIS);

    router.push("/");

    outlineAllowed(allowedStyle, allowList, denyList);

    outlineDenied(deniedStyle, denyList);

    eventController = new AbortController();

    document.addEventListener("mouseover", mouseOverHandler, {
      signal: eventController.signal,
      capture: true,
    });

    logger.verbose("Activated.", null, LOGGER_NAMESPACE);
  };

  const deactivate = () => {
    removeAllOutlines();

    stateStore.unsetActiveFeature();

    stateStore.explainThisToastVisible = false;

    stateStore.hideExplainThisTooltip();

    /**
     * remove event listener manually so Explain This properly works even on Customer
     * websites that do not support signals (like Angular ZoneJS v16)
     */
    document.removeEventListener("mouseover", mouseOverHandler, {
      capture: true,
    });

    eventController?.abort();

    logger.verbose("Deactivated.", null, LOGGER_NAMESPACE);
  };

  const explain = async (element: HTMLElement) => {
    deactivate();

    const selector = getPathTo(element);

    const metadata = {
      selector,
      html: element.outerHTML,
      title: document.title,
    };

    stateStore.displayExplainThisTooltip(element, metadata);

    tooltips.activate();
  };

  return { isActive: readonly(isActive), activate, deactivate };
};
