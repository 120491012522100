<template>
  <Transition
    appear
    enter-active-class="ease-in duration-300"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="ease-in duration-100"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <AppPopup
      v-if="stateStore.explainThisTooltip.visible"
      class="transition-opacity"
      :hover-trigger-ref="stateStore.explainThisTooltip.element"
      :config="popupConfig"
      @close="close()"
    >
      <AppLoader
        :loading="!hintBody"
        :retry-cb="retryHintFetch"
        :sync="false"
        classes="pb-5"
        :text="
          $pgettext(
            'Loading text displayed when Explain This Popup content is being fetched.',
            'Thinking',
          )
        "
        @state-changed="state => (loading = state)"
      >
        <div class="p-5 pt-0 text-sm" v-if="hintBody">
          <div data-explain-this-popup-content>
            <Component :is="hintBody" />
          </div>

          <button
            v-if="displayEmbed && hintWithContext"
            class="mt-6 p-2 w-full bg-white gap-x-2 flex items-center rounded-lg outline outline-1 -outline-offset-1 outline-gray-300 drop-shadow hover:bg-gray-50 cursor-pointer"
            @click="transferFunds()"
          >
            <span
              class="flex shrink-0 h-8 w-8 items-center justify-center rounded-full bg-[color:var(--ih-secondary-color)]"
            >
              <EyeIcon class="text-[color:var(--ih-primary-color)] size-6" />
            </span>

            <span
              class="text-sm font-semibold text-left leading-6 text-gray-900 grow"
            >
              Transfer funds
            </span>

            <ChevronRightIcon class="w-6 h-6 text-gray-500 shrink-0" />
          </button>

          <div
            class="text-xs font-medium text-gray-600 mt-6"
            v-if="configStore.config.explainThis.aiGeneratedBadgeEnabled"
          >
            <span
              class="inline-flex items-center bg-gray-300 px-1 mr-2 rounded cursor-help"
              :title="
                $pgettext(
                  'The Explain This Popup title of an AI badge informing the User that response has been generated by the AI.',
                  'AI generated',
                )
              "
            >
              {{
                $pgettext(
                  "The Explain This Popup AI badge informing the User that response has been generated by the AI.",
                  "AI",
                )
              }}
            </span>
          </div>

          <SourcesInline
            v-if="
              hintWithContext &&
              configStore.config.explainThis.sourcesEnabled &&
              hintWithContext.sources?.length
            "
            :sources="hintWithContext.sources"
            @click="close()"
            class="mt-6"
          />

          <div class="flex items-center gap-3 mt-6" v-if="hintWithContext">
            {{
              $pgettext(
                "Explain This Popup Rating thumbs heading.",
                "Was this helpful?",
              )
            }}

            <HintRatingThumbs
              v-if="
                !hintWithContext.ratingDisabled &&
                configStore.config.explainThis.messageRatingEnabled
              "
              :hint="hintWithContext"
            />
          </div>

          <div
            class="flex flex-col items-center gap-3 mt-6"
            v-if="hintWithContext"
          >
            <HintAskQuestionButton
              v-if="configStore.config.explainThis.askAboutThisEnabled"
              :hint-with-context="hintWithContext"
              @click="close()"
            />

            <button
              v-if="configStore.config.explainThis.explainAnotherPartEnabled"
              class="p-2 w-full bg-white gap-x-2 flex items-center rounded-lg outline outline-1 -outline-offset-1 outline-gray-300 drop-shadow hover:bg-gray-50 cursor-pointer"
              @click.prevent.stop="explainAnotherPart()"
            >
              <div
                class="flex shrink-0 h-8 w-8 items-center justify-center rounded-full bg-[color:var(--ih-secondary-color)]"
              >
                <CursorArrowRaysIcon
                  class="text-[color:var(--ih-primary-color)] h-6 w-6"
                />
              </div>

              <p
                class="text-sm font-semibold text-left leading-6 text-gray-900 grow"
              >
                {{
                  $pgettext(
                    "Explain This Popup button activating Explain This again.",
                    "Explain another part",
                  )
                }}
              </p>

              <ChevronRightIcon class="w-6 h-6 text-gray-500 shrink-0" />
            </button>
          </div>
        </div>
      </AppLoader>
    </AppPopup>
  </Transition>
</template>

<script setup lang="ts">
  import {
    computed,
    type DefineComponent,
    onMounted,
    onUnmounted,
    ref,
    shallowRef,
  } from "vue";
  import { CursorArrowRaysIcon } from "@heroicons/vue/24/outline";
  import { ChevronRightIcon } from "@heroicons/vue/20/solid";
  import { useMutationObserver } from "@vueuse/core";
  import { EyeIcon } from "@heroicons/vue/24/solid";
  import { activateEmbed } from "@/modules/chatbot/embeds";

  import type { HintWithContext } from "@/store/state";
  import { useStateStore } from "@/store/state";

  import { useConfigStore } from "@/store/config";

  import { HINT_TYPE_EXPLAIN_THIS, useExplainThis } from "@/modules/hints";

  import { useHttpClientApi } from "@/lib/http";

  import logger from "@/core/logger";

  import type { AppPopupConfig } from "@/components/AppPopup.vue";
  import AppPopup from "@/components/AppPopup.vue";
  import AppLoader from "@/components/AppLoader.vue";
  import HintAskQuestionButton from "@/modules/hints/HintAskQuestionButton.vue";
  import HintRatingThumbs from "@/modules/hints/HintRatingThumbs.vue";
  import SourcesInline from "@/components/SourcesInline.vue";

  import { generateUUID } from "@/util/uuid";

  import { processHintStream } from "@/modules/hints/shared";

  const stateStore = useStateStore();

  const configStore = useConfigStore();

  const explainThis = useExplainThis();

  const httpClientApi = useHttpClientApi();

  const popupConfig = computed<AppPopupConfig>(() => ({
    ...configStore.config.explainThis.popup,
    poweredBy: configStore.config.global.poweredBy,
  }));

  const hintWithContext = ref<HintWithContext>();
  const hintBody = shallowRef<DefineComponent>();

  const loading = ref(false);

  const displayEmbed = computed(() => {
    const isPoc = localStorage.getItem("poc") === "true";

    return (
      isPoc &&
      stateStore.explainThisTooltip.element?.innerText === "Quick Transfer"
    );
  });

  const transferFunds = () => {
    activateEmbed({
      type: "im_player_activate_topic",
      label: "Transfer funds",
      data: {
        topicId: 112841,
      },
    });

    close();
  };

  const fetchHint = async () => {
    try {
      const body = `Explain: ${stateStore.explainThisTooltip.element?.innerText}`;

      const metadata = stateStore.explainThisTooltip.metadata;

      const hintId = generateUUID();

      const stream = await httpClientApi.fetchHintStream(
        hintId,
        HINT_TYPE_EXPLAIN_THIS,
        body,
        metadata,
      );

      processHintStream(stream, body, metadata, hintBody, hintWithContext);
    } catch (e: unknown) {
      if (e instanceof Error) {
        logger.error(e.message, null, "ExplainThis");
      }
    }
  };

  const retryHintFetch = () => {
    fetchHint();
  };

  const close = () => {
    stateStore.hideExplainThisTooltip();

    hintWithContext.value = undefined;
    hintBody.value = undefined;
  };

  const observeElement = () => {
    const observer = useMutationObserver(
      (stateStore.explainThisTooltip.element as HTMLElement).parentElement,
      mutations => {
        for (const mutation of mutations) {
          for (const removedNode of mutation.removedNodes) {
            if (
              removedNode ===
              (stateStore.explainThisTooltip.element as HTMLElement)
            ) {
              observer.stop();

              close();
            }
          }
        }
      },
      {
        childList: true,
      },
    );
  };

  onMounted(() => {
    fetchHint();

    observeElement();
  });

  onUnmounted(() => {
    close();
  });

  const explainAnotherPart = () => {
    close();

    explainThis.activate();
  };
</script>
