<template>
  <div class="relative">
    <Transition
      appear
      enter-active-class="ease-in duration-200"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="showResults"
        class="absolute z-10 bottom-14 w-full p-3 bg-[color:var(--ih-secondary-color)] rounded-t-md"
      >
        <AppLoader
          :loading="searching"
          :sync="false"
          :retry-cb="() => search(query)"
          :text="
            $pgettext(
              'Loading text displayed when Knowledge Base Search is being performed.',
              'Searching',
            )
          "
        >
          <ul
            v-if="results.length"
            class="text-sm text-gray-800 bg-white border border-gray-100 rounded-md shadow max-h-80 overflow-y-auto"
            id="search-results"
            ref="resultsRef"
            role="listbox"
          >
            <li
              v-for="result in results"
              :key="result.id"
              class="cursor-pointer select-none px-4 py-2 hover:bg-[color:var(--ih-secondary-color)] hover:text-inherit flex items-center justify-between"
              :class="[
                result.active &&
                  'bg-[color:var(--ih-primary-color)] text-white',
              ]"
              :id="`result-${result.id}`"
              role="option"
              tabindex="-1"
              @click="openResult(result.id)"
            >
              {{ result.title }}

              <ArrowUturnRightIcon
                v-if="result.active"
                class="h-4 w-4 rotate-180"
              />
            </li>
          </ul>

          <div v-else class="p-3 absolute top-1/2 -translate-y-1/2">
            {{
              $pgettext(
                "Knowledge Base Search fallback text when no results could be found for the query.",
                "No results found for the query.",
              )
            }}
          </div>
        </AppLoader>
      </div>
    </Transition>

    <div class="relative bg-[color:var(--ih-secondary-color)] p-3">
      <MagnifyingGlassIcon
        class="pointer-events-none absolute left-6 top-5 translate-y-0.5 h-5 w-5 text-gray-400"
      />

      <input
        type="text"
        class="h-10 w-full border-none rounded pl-11 pr-4 text-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[color:var(--ih-primary-color)]"
        :placeholder="
          $pgettext('Knowledge Base Search input placeholder.', 'Search...')
        "
        role="combobox"
        ref="queryRef"
        :aria-expanded="showResults"
        aria-controls="search-results"
        @blur="blurInput()"
        @focus.prevent="openResults()"
        @input="search(($event.target as HTMLInputElement).value)"
        :value="query"
        @keyup.prevent.stop.esc="closeResults()"
        @keydown.prevent.stop.up="highlightPreviousResult()"
        @keydown.prevent.stop.down="highlightNextResult()"
        @keydown.enter.prevent=""
        @keyup.enter.prevent="activeResult?.id && openResult(activeResult.id)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, nextTick, onBeforeUnmount, onMounted, ref } from "vue";
  import {
    ArrowUturnRightIcon,
    MagnifyingGlassIcon,
  } from "@heroicons/vue/24/outline";
  import { storeToRefs } from "pinia";
  import { debounce } from "lodash-es";
  import { useRouter } from "vue-router";

  import { useKnowledgeBaseStore } from "@/store/knowledge-base";

  import { SECTION_TYPE_KNOWLEDGE_BASE } from "@/store/config";

  import focusElement from "@/util/focus-element";

  import AppLoader from "@/components/AppLoader.vue";

  const queryRef = ref();
  const resultsRef = ref();

  const query = ref();
  const showResults = ref(false);
  const searching = ref(false);
  const activeResultIndex = ref(0);

  const router = useRouter();

  const knowledgeBaseStore = useKnowledgeBaseStore();
  const { searchResults } = storeToRefs(knowledgeBaseStore);

  const results = computed(() =>
    searchResults.value.map((result, index) => ({
      ...result,
      active: activeResultIndex.value === index,
    })),
  );

  const activeResult = computed(
    () => searchResults.value[activeResultIndex.value],
  );

  onMounted(() => {
    focusInput();
  });

  onBeforeUnmount(() => {
    query.value = "";

    knowledgeBaseStore.clearSearchResults();
  });

  const search = debounce(value => {
    if (!value.trim()) {
      return;
    }

    query.value = value.trim();

    searching.value = false;

    closeResults();

    knowledgeBaseStore.clearSearchResults();

    nextTick(async () => {
      if (query.value) {
        searching.value = true;

        openResults();

        await knowledgeBaseStore.searchArticle(query.value);

        searching.value = false;
      }
    });
  }, 500);

  const scrollToActiveResult = () => {
    nextTick(() => {
      if (!resultsRef.value) {
        return;
      }

      const activeResultElement = resultsRef.value.querySelector(
        `#result-${activeResult.value.id}`,
      );

      resultsRef.value.scrollTop =
        activeResultElement.scrollHeight * activeResultIndex.value;
    });
  };

  const focusInput = () => {
    nextTick(() => {
      if (!queryRef.value) {
        return;
      }

      focusElement(queryRef.value);
    });
  };

  const blurInput = () => {
    closeResults();
  };

  const openResults = () => {
    if (query.value) {
      showResults.value = true;

      scrollToActiveResult();
    }
  };

  const closeResults = () => {
    showResults.value = false;
  };

  const highlightNextResult = () => {
    if (activeResultIndex.value < searchResults.value.length - 1) {
      activeResultIndex.value++;

      scrollToActiveResult();
    }
  };

  const highlightPreviousResult = () => {
    if (activeResultIndex.value > 0) {
      activeResultIndex.value--;

      scrollToActiveResult();
    }
  };

  const openResult = (id: string) => {
    router.push(`/${SECTION_TYPE_KNOWLEDGE_BASE}/article/${id}`);

    query.value = "";

    closeResults();
  };
</script>
