<template>
  <div class="h-full flex flex-col">
    <div
      class="p-3 flex justify-end items-center text-center bg-[color:var(--ih-primary-color)]"
    >
      <button
        type="button"
        class="rounded-md bg-[color:var(--ih-secondary-color)] px-3 py-2 leading-tight text-sm font-semibold shadow-md hover:bg-opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[color:var(--ih-secondary-color)]"
        @click="resetConversation()"
      >
        {{
          $pgettext(
            "Button starting new conversation located at Chat page in the Widget.",
            "Start new conversation",
          )
        }}
      </button>
    </div>

    <div class="flex flex-col grow w-full max-w-xl bg-white overflow-hidden">
      <div
        class="flex flex-col grow space-y-8 h-0 p-4 overflow-auto"
        ref="conversationRef"
      >
        <ChatbotMessage
          v-for="message in nonEmptyMessages"
          :key="message.id"
          :message="message"
          ref="messagesRef"
          @updated="maybeScrollToConversationBottom()"
        />

        <div
          v-if="!conversationScrollState.bottom"
          class="bg-white absolute bottom-20 left-3.5 motion-safe:animate-bounce rounded-full p-2 cursor-pointer group"
          @click="scrollConversationLittle()"
        >
          <ArrowDownCircleIcon
            class="w-7 h-7 shrink-0 text-[color:var(--ih-primary-color)] group-hover:fill-[color:var(--ih-secondary-color)]"
          />
        </div>

        <div
          v-if="waitingForResponse"
          class="w-full mt-2 max-w-xs flex space-x-3"
        >
          <div
            v-if="isBotIconDefault"
            v-html="botIcon"
            class="h-10 w-10 rounded-full bg-[color:var(--ih-secondary-color)] text-[color:var(--ih-primary-color)]"
          ></div>

          <img v-else class="h-10 w-10 rounded-full" :src="botIcon" alt="" />

          <p
            class="loading-dots w-[50px] h-[32px] bg-[--ih-secondary-color] rounded-bl-[theme(spacing.2)] rounded-tr-[theme(spacing.2)] rounded-br-[theme(spacing.2)]"
          ></p>
        </div>
      </div>

      <div
        v-if="showDisconnected"
        class="p-2 bg-yellow-200 text-xs text-gray-500"
      >
        {{
          $pgettext(
            "Warning displayed when Chat WebSocket has disconnected at Chat page in the Widget.",
            "Disconnected. Connecting ...",
          )
        }}
      </div>

      <div
        v-if="rejected"
        class="m-4 p-3 text-sm bg-gray-100 text-gray-500 rounded-lg"
      >
        <p>
          {{
            $pgettext(
              "Info displayed when conversation has been closed at Chat page in the Widget.",
              "This conversation has been closed.",
            )
          }}
        </p>

        <p>
          <span>
            {{
              $pgettext(
                "Part of the call-to-action displayed at Chat page in the Widget when conversation has been closed. Full text: 'Please start a new conversation.'",
                "Please ",
              )
            }}
          </span>

          <button
            type="button"
            class="text-[color:var(--ih-primary-color)] hover:underline focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[color:var(--ih-primary-color)]"
            @click="resetConversation()"
          >
            {{
              $pgettext(
                "Button part of the call-to-action displayed at Chat page in the Widget when conversation has been closed. Full text: 'Please start a new conversation.'",
                "start a new conversation",
              )
            }}
          </button>

          <span>.</span>
        </p>
      </div>

      <div class="bg-[color:var(--ih-secondary-color)] p-3">
        <form @submit.prevent.stop="send()">
          <input
            v-model.trim="question"
            ref="questionRef"
            class="flex items-center border-none h-10 w-full rounded px-3 text-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[color:var(--ih-primary-color)] disabled:bg-gray-100 disabled:cursor-not-allowed"
            type="text"
            :placeholder="
              $pgettext(
                'Chat question input placeholder text.',
                'Ask a question ...',
              )
            "
            :disabled="
              (!connected && hasActiveConversation) || waitingForResponse
            "
          />
        </form>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { ComponentPublicInstance, DeepReadonly } from "vue";
  import {
    computed,
    nextTick,
    onBeforeUnmount,
    onMounted,
    ref,
    watch,
  } from "vue";
  import { storeToRefs } from "pinia";
  import { ArrowDownCircleIcon } from "@heroicons/vue/24/outline";
  import { useScroll } from "@vueuse/core";

  import { useChatbot } from "@/modules/chatbot";

  import {
    CONVERSATION_SOURCE_CHAT,
    type Message,
    MESSAGE_ACTION_MESSAGE,
    MESSAGE_SENT_BY_BOT,
    MESSAGE_SENT_BY_CONTACT,
    MESSAGE_TYPE_CHAT,
    useConversationStore,
  } from "@/store/conversation";

  import { useConfigStore } from "@/store/config";

  import focusElement from "@/util/focus-element";

  import { generateUUID } from "@/util/uuid";

  import type { HintWithContext } from "@/store/state";
  import { useStateStore } from "@/store/state";

  import ChatbotMessage from "@/modules/chatbot/ChatbotMessage.vue";

  import defaultBotIcon from "@/assets/images/chatbot-icon.svg?raw";

  const conversationStore = useConversationStore();

  const {
    connected,
    rejected,
    waitingForResponse,
    messages,
    hasActiveConversation,
  } = storeToRefs(conversationStore);

  const configStore = useConfigStore();

  const stateStore = useStateStore();
  const { hintWithContext } = storeToRefs(stateStore);

  const chatbot = useChatbot();

  const conversationRef = ref();
  const questionRef = ref();
  const messagesRef = ref<ComponentPublicInstance[]>([]);

  const question = ref();

  const showDisconnected = ref(false);
  const showDisconnectedTimer = ref<ReturnType<typeof setTimeout>>();

  const nonEmptyMessages = computed(() =>
    messages.value.filter(message => message.body),
  );

  const lastMessage = computed<DeepReadonly<Message> | undefined>(
    () => messages.value[messages.value.length - 1],
  );

  const { arrivedState: conversationScrollState } = useScroll(conversationRef);

  watch(
    () => !connected.value && !rejected.value && hasActiveConversation.value,
    disconnected => {
      if (!disconnected) {
        clearTimeout(showDisconnectedTimer.value);

        showDisconnected.value = false;

        return;
      }

      showDisconnectedTimer.value = setTimeout(
        () => (showDisconnected.value = true),
        500,
      );
    },
    {
      immediate: true,
    },
  );

  onMounted(() => {
    if (hintWithContext.value) {
      conversationStore.$reset();

      conversationStore.addMessage(
        createHintRequestMessage(hintWithContext.value),
      );

      conversationStore.addMessage(
        createHintResponseMessage(hintWithContext.value),
      );
    } else {
      if (hasActiveConversation.value) {
        conversationStore.connectToActiveConversation();
      }

      if (!messages.value.length) {
        conversationStore.addMessage(createWelcomeMessage());
      }
    }

    maybeFocusQuestionInput();
  });

  onBeforeUnmount(() => {
    conversationStore.closeConversation();

    chatbot.deactivate();
  });

  const isBotIconDefault = !configStore.config.chatbot.icon;
  const botIcon = configStore.config.chatbot.icon || defaultBotIcon;

  const createHintRequestMessage = (
    hintWithContext: HintWithContext,
  ): Message => ({
    id: generateUUID(),
    action: MESSAGE_ACTION_MESSAGE,
    type: MESSAGE_TYPE_CHAT,
    context: {},
    metadata: hintWithContext.metadata,
    sentBy: MESSAGE_SENT_BY_CONTACT,
    body: hintWithContext.body,
    createdAt: new Date().toISOString(),
    ratingDisabled: true,
  });

  const createHintResponseMessage = (
    hintWithContext: HintWithContext,
  ): Message => ({
    id: generateUUID(),
    action: MESSAGE_ACTION_MESSAGE,
    type: MESSAGE_TYPE_CHAT,
    context: {
      hintId: hintWithContext.id,
    },
    metadata: {},
    sentBy: MESSAGE_SENT_BY_BOT,
    body: hintWithContext.content,
    createdAt: new Date().toISOString(),
    ratingDisabled: true,
  });

  const createWelcomeMessage = (): Message => ({
    id: generateUUID(),
    action: MESSAGE_ACTION_MESSAGE,
    type: MESSAGE_TYPE_CHAT,
    context: {},
    metadata: {},
    sentBy: MESSAGE_SENT_BY_BOT,
    body: configStore.config.chatbot.welcomeMessage,
    createdAt: new Date().toISOString(),
    ratingDisabled: true,
  });

  const maybeScrollToConversationBottom = () => {
    nextTick(() => {
      if (!conversationRef.value) {
        return;
      }

      const lastMessageRef = messagesRef.value[messagesRef.value.length - 1];

      if (!lastMessageRef) {
        conversationRef.value.scrollTop = conversationRef.value.scrollHeight;

        return;
      }

      const conversationHeight =
        conversationRef.value.getBoundingClientRect().height;
      const lastMessageHeight =
        lastMessageRef.$el.getBoundingClientRect().height;

      if (lastMessageHeight + 30 < conversationHeight) {
        conversationRef.value.scrollTop = conversationRef.value.scrollHeight;
      } else {
        conversationRef.value.scrollTop =
          conversationRef.value.scrollHeight - lastMessageHeight - 30;
      }
    });
  };

  const maybeFocusQuestionInput = () => {
    nextTick(() => questionRef.value && focusElement(questionRef.value));
  };

  watch(
    [connected, rejected, waitingForResponse],
    () => {
      maybeFocusQuestionInput();
      maybeScrollToConversationBottom();
    },
    {
      immediate: true,
    },
  );

  const send = async () => {
    if (!question.value) {
      return;
    }

    const hintId =
      hintWithContext.value?.id ??
      (lastMessage.value?.context?.hintId as string);

    if (!hasActiveConversation.value) {
      await conversationStore.createConversation(
        CONVERSATION_SOURCE_CHAT,
        hintId,
      );
    }

    conversationStore.sendChatMessage(question.value);

    question.value = null;
  };

  const resetConversation = () => {
    maybeFocusQuestionInput();

    stateStore.hintWithContext = undefined;

    conversationStore.$reset();

    conversationStore.addMessage(createWelcomeMessage());
  };

  const scrollConversationLittle = () => {
    if (!conversationRef.value) {
      return;
    }

    conversationRef.value.scrollTop += 50;
  };
</script>
