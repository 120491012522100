<template>
  <Transition
    appear
    enter-active-class="ease-in duration-200"
    enter-from-class="`opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="ease-out duration-100"
    leave-from-class="opacity-100"
    leave-to-class="`opacity-0"
  >
    <div
      class="absolute -top-1/2 -translate-y-full max-w-[theme(spacing.48)] w-max"
      :class="[
        isHorizontalPositionRight &&
          '-right-[min(75%,calc(theme(spacing.4)+calc(var(--ih-launcher-space-side)/2)))]',
        !isHorizontalPositionRight &&
          '-left-[min(75%,calc(theme(spacing.4)+calc(var(--ih-launcher-space-side)/2)))]',
      ]"
    >
      <div
        class="flex items-start gap-2 bg-white drop-shadow rounded-md p-2 after:absolute after:h-0 after:w-0 after:border-x-8 after:border-x-transparent after:border-t-8 after:border-t-white after:top-full"
        :class="[
          isHorizontalPositionRight &&
            'after:right-[min(calc(theme(spacing.12)+theme(spacing.1)),calc(theme(spacing.8)+calc(var(--ih-launcher-space-side)/2)))]',
          !isHorizontalPositionRight &&
            'after:left-[min(calc(theme(spacing.12)+theme(spacing.1)),calc(theme(spacing.8)+calc(var(--ih-launcher-space-side)/2)))]',
        ]"
      >
        <div>{{ config.widget.launcher.nudge.text }}</div>

        <XMarkIcon
          class="p-0.5 w-5 h-5 shrink-0 cursor-pointer hover:bg-gray-200 rounded-md"
          @click="close()"
        />
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
  import type { DeepReadonly } from "vue";
  import { XMarkIcon } from "@heroicons/vue/24/solid";

  import type { Config } from "@/store/config";

  type Props = {
    config: DeepReadonly<Config>;
    isHorizontalPositionRight: boolean;
  };

  defineProps<Props>();

  const emit = defineEmits<{
    close: [];
  }>();

  const close = () => emit("close");
</script>
