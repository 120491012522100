import type { DeepReadonly, DefineComponent } from "vue";
import { storeToRefs } from "pinia";
import { autoUpdate, computePosition, offset } from "@floating-ui/vue";

import { maybeRegisterCustomElement } from "@/core/web-component";

import logger from "@/core/logger";

import { useDomObserver } from "@/core/dom-observer";

import { render } from "@/modules/hints/tooltips/render";

import type { Config, Tooltip } from "@/store/config";
import { useConfigStore } from "@/store/config";

import { useAppRouter } from "@/router";

import { validateSelector } from "@/util/dom";
import { getPathTo } from "@/util/xpath";

import { useTranslations } from "@/translations/translations";

import TooltipLauncher from "@/modules/hints/tooltips/TooltipLauncher.ce.vue";

const LOG_NAMESPACE = "Tooltips";

let domObserver: ReturnType<typeof useDomObserver> | undefined;

let domObserverStop: (() => void) | undefined;

const renderedElements: {
  name: string;
  destroy: (immediate?: boolean) => void;
}[] = [];

const injectElement = (
  element: Element,
  name: string,
  tooltipConfig: Tooltip,
  style?: string,
) => {
  const selector = getPathTo(element as HTMLElement);

  const destroy = render(
    element as HTMLElement,
    {
      selector,
      html: element.outerHTML,
      text: element.textContent,
      tooltipConfig,
      style,
    },
    reference => {
      const cleanup = autoUpdate(element, reference, async () => {
        const cssPosition = await calculateLauncherCSSPosition(
          element,
          tooltipConfig,
        );

        reference.style.left = `${cssPosition.x}px`;
        reference.style.top = `${cssPosition.y}px`;
      });

      renderedElements.push({
        name,
        destroy: () => {
          destroy();

          cleanup();
        },
      });

      return cleanup;
    },
  );
};

const destroyElement = (name: string) => {
  const renderedElement = renderedElements.find(
    renderedElement => renderedElement.name === name,
  );

  if (renderedElement) {
    renderedElement.destroy();
  }
};

const findSelectorFromElement = (element: Element, selectors: string[]) => {
  for (const selector of selectors) {
    if (element.matches(selector)) {
      return selector;
    }
  }
};

const findTooltipConfigBySelector = (
  selector: string,
  tooltips: Readonly<Tooltip[]>,
) => {
  if (!validateSelector(selector)) {
    return;
  }

  for (const tooltip of tooltips) {
    for (const element of tooltip.elements) {
      if (element.selector === selector) {
        return tooltip;
      }
    }
  }
};

const extractTooltipSelectors = (tooltips: Readonly<Tooltip[]>) => {
  const selectors: string[] = [];

  for (const tooltip of tooltips) {
    for (const { selector } of tooltip.elements) {
      if (!validateSelector(selector)) {
        logger.error(
          "Ignored invalid Tooltip element selector.",
          { selector },
          LOG_NAMESPACE,
        );

        continue;
      }

      selectors.push(selector);
    }
  }

  return selectors;
};

const calculateLauncherCSSPosition = async (
  element: Element,
  tooltipConfig: Tooltip,
) => {
  const virtualElement = document.createElement("span");

  virtualElement.style.cssText = `display: inline-block; position: absolute; width: ${tooltipConfig.launcher.size.width}px; height: ${tooltipConfig.launcher.size.height}px; `;

  element.appendChild(virtualElement);

  const { x, y } = await computePosition(element, virtualElement, {
    placement: tooltipConfig.launcher.position,
    middleware: [offset(tooltipConfig.launcher.space)],
  });

  virtualElement.remove();

  return { x, y };
};

const initializeElement = async (
  element: Element,
  name: string,
  selectors: string[],
  config: DeepReadonly<Config>,
) => {
  logger.verbose("Injecting...", { element }, `${LOG_NAMESPACE}:${name}`);

  const selector = findSelectorFromElement(element, selectors) as string;
  const tooltipConfig = findTooltipConfigBySelector(
    selector,
    config.tooltips.tooltips as Readonly<Tooltip[]>,
  ) as Tooltip;

  const cssPosition = await calculateLauncherCSSPosition(
    element,
    tooltipConfig,
  );

  injectElement(
    element,
    name,
    tooltipConfig,
    `position: absolute; z-index: ${tooltipConfig.launcher.zIndex}; left: ${cssPosition.x}px; top: ${cssPosition.y}px;`,
  );

  logger.verbose("Injected.", { element }, `${LOG_NAMESPACE}:${name}`);
};

export const useTooltips = () => {
  const configStore = useConfigStore();

  const { config } = storeToRefs(configStore);

  const { router } = useAppRouter();

  const activate = () => {
    logger.verbose("Activating...", null, LOG_NAMESPACE);

    maybeRegisterCustomElement(
      "inline-help-tooltip",
      TooltipLauncher as unknown as DefineComponent,
      [router, useTranslations()],
    );

    if (!config.value.tooltips.tooltips.length) {
      logger.warn(
        "No tooltips configuration has been found, no tooltips would be displayed.",
        null,
        LOG_NAMESPACE,
      );

      return;
    }

    const selectors = extractTooltipSelectors(
      config.value.tooltips.tooltips as Readonly<Tooltip[]>,
    );

    if (!selectors.length) {
      logger.verbose(
        "No selectors has been found, no tooltips would be displayed.",
        null,
        LOG_NAMESPACE,
      );

      return;
    }

    domObserver = useDomObserver(selectors, "inline-help-tooltip");

    domObserverStop = domObserver.observe(
      (element, name) =>
        initializeElement(element, name, selectors, config.value),
      name => {
        logger.verbose("Destroying...", null, `${LOG_NAMESPACE}:${name}`);

        destroyElement(name);

        logger.verbose("Destroyed.", null, `${LOG_NAMESPACE}:${name}`);
      },
    );

    domObserver.mapUninitialized((element, name) =>
      initializeElement(element, name, selectors, config.value),
    );

    logger.verbose("Activated.", null, LOG_NAMESPACE);
  };

  const deactivate = () => {
    logger.verbose("Deactivating...", null, LOG_NAMESPACE);

    domObserverStop?.();

    domObserver?.unmapInitialized(name => {
      logger.verbose("Destroying...", null, `${LOG_NAMESPACE}:${name}`);

      destroyElement(name);

      logger.verbose("Destroyed.", null, `${LOG_NAMESPACE}:${name}`);
    });

    logger.verbose("Deactivated.", null, LOG_NAMESPACE);
  };

  return { activate, deactivate };
};
