import type { DeepReadonly } from "vue";
import { computed, readonly, ref } from "vue";
import { defineStore, storeToRefs } from "pinia";
import { StorageSerializers, useStorage } from "@vueuse/core";
import dayjs from "dayjs/esm";

import type { JSONObject } from "@/types";

import { useWSClientApi } from "@/lib/websocket";
import { useHttpClientApi } from "@/lib/http";

import logger from "@/core/logger";
import { getContext } from "@/core/context";

import {
  convertKeysToCamelCaseRecursive,
  convertKeysToSnakeCaseRecursive,
} from "@/util/object";

import { useUserStore } from "@/store/user";

import { getStorageItemName } from "@/lib/storage";

export const CONVERSATION_SOURCE_CHAT = "chat";

export type ConversationSource = typeof CONVERSATION_SOURCE_CHAT;

export const MESSAGE_TYPE_CHAT = "chat";

export type MessageType = typeof MESSAGE_TYPE_CHAT;

export const MESSAGE_ACTION_MESSAGE = "message";
export const MESSAGE_ACTION_REPLY = "reply";
export const MESSAGE_ACTION_STREAMING = "streaming";

export const MESSAGE_FINISH_REASON_STOP = "stop";

export const MESSAGE_SENT_BY_CONTACT = "contact";
export const MESSAGE_SENT_BY_BOT = "bot";

export const MESSAGE_RATING_POSITIVE = "positive";
export const MESSAGE_RATING_NEGATIVE = "negative";

export const MESSAGE_SOURCE_ARTICLE = "article";

export type MessageAction =
  | typeof MESSAGE_ACTION_MESSAGE
  | typeof MESSAGE_ACTION_REPLY
  | typeof MESSAGE_ACTION_STREAMING;

export type MessageFinishReason = typeof MESSAGE_FINISH_REASON_STOP;

export type MessageSentBy =
  | typeof MESSAGE_SENT_BY_CONTACT
  | typeof MESSAGE_SENT_BY_BOT;

export type MessageRating =
  | typeof MESSAGE_RATING_POSITIVE
  | typeof MESSAGE_RATING_NEGATIVE;

export type MessageSource = typeof MESSAGE_SOURCE_ARTICLE;

export type MessageBase = {
  body: string;
  context?: JSONObject;
  author?: Partial<{
    id: string;
    userId: string;
    name: string;
    email: string;
  }>;
};

export type ChatMessage = MessageBase;

type AnyMessage = ChatMessage;

export type MessageMetadataEmbed = {
  type: string;
  label: string;
  data?: JSONObject;
};

export type Message = AnyMessage & {
  id: string;
  type: MessageType;
  sentBy: MessageSentBy;
  createdAt: string;
  action: MessageAction;
  finishReason?: MessageFinishReason;
  ratingDisabled?: boolean;
  rating?: MessageRating;
  metadata: {
    sources?: [
      {
        id: string;
        type: MessageSource;
        url: string;
        title: string;
      },
    ];
    embeds?: MessageMetadataEmbed[];
  };
};

type StatusMessage = {
  status: "closed";
};

type ResponseMessage =
  | StatusMessage
  | {
      id: string;
      type: MessageType;
      body: string;
      action: MessageAction;
      finish_reason?: MessageFinishReason;
      context?: JSONObject;
      sent_by: MessageSentBy;
      created_at: string;
      rating?: MessageRating;
      author?: Omit<Message["author"], "userId"> & {
        user_id?: string;
      };
      metadata?: {
        selector: string;
        html: string;
        sources?: Message["metadata"]["sources"];
      };
    };

const LOGGER_NAMESPACE = "ConversationStore";

export const useConversationStore = defineStore("conversation", () => {
  const { consumer } = useWSClientApi();
  const httpClientApi = useHttpClientApi();

  const userStore = useUserStore();
  const { user } = storeToRefs(userStore);

  const conversationId = useStorage(
    getStorageItemName("ih_conversation_id"),
    null,
    localStorage,
    {
      serializer: StorageSerializers.string,
    },
  );

  const messages = useStorage<Message[]>(
    getStorageItemName("ih_conversation_messages"),
    [],
    localStorage,
  );

  const subscription = ref();

  const connected = ref(false);

  const rejected = ref(false);

  const buffer = ref<(() => void)[]>([]);

  const waitingForResponse = ref(false);

  const hasActiveConversation = computed(() => !!conversationId.value);

  const addToBuffer = (cb: () => void) => {
    buffer.value.push(cb);
  };

  const perform = (action: string, data?: JSONObject, cb?: () => void) => {
    if (connected.value) {
      logger.silly("Sending message.", data, LOGGER_NAMESPACE);

      waitingForResponse.value = true;

      subscription.value.perform(action, data);

      cb?.();

      return;
    }

    logger.silly(
      "Subscription not connected. Buffering the message.",
      data,
      LOGGER_NAMESPACE,
    );

    addToBuffer(() => perform(action, data, cb));
  };

  const $reset = () => {
    messages.value = [];

    buffer.value = [];

    conversationId.value = null;

    disconnectFromWebSocket();
  };

  const disconnectFromWebSocket = () => {
    connected.value = false;
    rejected.value = false;
    waitingForResponse.value = false;

    subscription.value?.unsubscribe();

    consumer.disconnect();
  };

  const send = (type: MessageType, message: AnyMessage, cb?: () => void) => {
    const readyToSendMessage = convertKeysToSnakeCaseRecursive<
      JSONObject,
      AnyMessage
    >({
      ...message,
      ...(user.value?.userId
        ? {
            author: {
              id: user.value.id,
              userId: user.value.userId,
              // @ts-expect-error We're expecting recursive object
              ...(user.value?.traits || {}),
            },
          }
        : {}),
      context: getContext(),
    });

    perform(
      "send_message",
      {
        ...readyToSendMessage,
        type,
      },
      cb,
    );

    // FIXME: https://3.basecamp.com/4494358/buckets/33185741/todos/6307699824
    // addMessage({
    //   ...readyToSendMessage,
    //   id: generateUUID(),
    //   sentBy: MESSAGE_SENT_BY_CONTACT,
    //   createdAt: new Date(),
    //   type,
    // });
  };

  const clearExpiredMessages = () => {
    messages.value = messages.value.filter(message => {
      const hoursDiff = dayjs().diff(message.createdAt, "hours");

      return hoursDiff <= 24;
    });
  };

  const addMessage = (message: Message) => {
    const existingMessageIndex = messages.value.findIndex(
      m => m.id === message.id,
    );

    const isPoc = localStorage.getItem("poc") === "true";

    if (existingMessageIndex !== -1) {
      if (message.action === MESSAGE_ACTION_STREAMING) {
        messages.value[existingMessageIndex].body += message.body;
      }

      // Replace the whole message if it's a reply.
      // This is the final reply with whole message.
      if (message.action === MESSAGE_ACTION_REPLY) {
        if (
          isPoc &&
          messages.value[existingMessageIndex - 1].body === "How to send money?"
        ) {
          message.metadata = {
            ...message.metadata,
            embeds: [
              {
                label: "Guide me",
                type: "im_player_activate_topic",
                data: {
                  topicId: 112842,
                },
              },
            ],
          };
        }

        messages.value[existingMessageIndex] = message;
      }
    } else {
      messages.value.push(message);
    }
  };

  const createConversation = async (
    source: ConversationSource,
    hintId?: string,
  ) => {
    clearExpiredMessages();

    if (!conversationId.value) {
      const response = await httpClientApi.createConversation(source, hintId);

      conversationId.value = response?.conversation?.id ?? response?.id;
    }

    disconnectFromWebSocket();

    if (conversationId.value) {
      connectToWebSocket();
    }
  };

  const connectToWebSocket = () => {
    subscription.value?.unsubscribe?.();

    const channelNameWithParams = {
      channel: "Client::ConversationChannel",
      id: conversationId.value,
    };

    subscription.value = consumer.subscriptions.create(channelNameWithParams, {
      connected(): void {
        connected.value = true;
        rejected.value = false;

        logger.verbose(
          "Subscription connected.",
          channelNameWithParams,
          LOGGER_NAMESPACE,
        );

        if (buffer.value.length) {
          logger.silly(
            "Found buffered messages. Sending.",
            null,
            LOGGER_NAMESPACE,
          );
        }

        for (const cb of buffer.value) {
          cb();
        }

        buffer.value = [];
      },
      received(message: ResponseMessage): void {
        logger.silly("Data received.", message, LOGGER_NAMESPACE);

        if ((message as StatusMessage).status === "closed") {
          disconnectFromWebSocket();

          rejected.value = true;

          return;
        }

        const mappedMessage = convertKeysToCamelCaseRecursive<
          ResponseMessage,
          Message
        >(message as ResponseMessage);

        if (mappedMessage.sentBy !== MESSAGE_SENT_BY_CONTACT) {
          waitingForResponse.value = false;
        }

        if (mappedMessage.finishReason === "stop") {
          return;
        }

        addMessage(mappedMessage);
      },
      rejected(): void {
        rejected.value = true;
      },
      disconnected(): void {
        connected.value = false;

        waitingForResponse.value = false;

        logger.verbose("Subscription disconnected.", null, LOGGER_NAMESPACE);
      },
    });
  };

  const connectToActiveConversation = () => {
    disconnectFromWebSocket();

    connectToWebSocket();
  };

  const sendChatMessage = (message: string) => {
    send(MESSAGE_TYPE_CHAT, {
      body: message,
    });
  };

  const rateMessage = (
    message: DeepReadonly<Message>,
    rating: MessageRating,
  ) => {
    httpClientApi.rateMessage(
      conversationId.value as string,
      message.id,
      rating,
    );

    messages.value.map(m => {
      if (m.id === message.id) {
        m.rating = rating;
      }

      return m;
    });
  };

  return {
    $reset,
    messages: readonly(messages),
    connected: readonly(connected),
    rejected: readonly(rejected),
    waitingForResponse,
    conversationId: readonly(conversationId),
    hasActiveConversation: readonly(hasActiveConversation),
    addMessage,
    sendChatMessage,
    createConversation,
    connectToActiveConversation,
    closeConversation() {
      disconnectFromWebSocket();
    },
    rateMessage,
  };
});
