<template>
  <div class="p-4 relative overflow-y-auto">
    <div v-if="success" class="p-2 mb-4 bg-green-200 text-xs text-gray-500">
      {{
        $pgettext(
          "Ticket Form ticket sent successfully message.",
          "Ticket has been successfully sent.",
        )
      }}
    </div>

    <div v-if="failure" class="p-2 mb-4 bg-red-200 text-xs text-gray-900">
      {{
        $pgettext(
          "Ticket Form ticket sending failed message.",
          "Ticket could not be sent. Please, try again later.",
        )
      }}
    </div>

    <Transition
      appear
      enter-active-class="ease-in duration-200"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <AppLoader
        v-if="sending"
        class="inset-0 !absolute bg-white bg-opacity-75 z-20 flex items-center justify-center"
        :loading="sending"
        :text="$pgettext('Ticket Form sending loader text.', 'Sending')"
      />
    </Transition>

    <form class="space-y-4" @submit.prevent.stop="send()" novalidate>
      <div>
        <label
          for="full-name"
          class="block text-sm font-medium leading-6 text-gray-900"
          >{{ $pgettext("Ticket Form Name input label.", "Name") }}</label
        >

        <div class="mt-1 relative">
          <input
            type="text"
            name="full-name"
            id="full-name"
            autocomplete="name"
            required
            class="block w-full rounded-md border-0 h-10 px-3 text-gray-900 shadow-sm text-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[color:var(--ih-primary-color)]"
            v-model.trim="formData.name"
            :class="[v$.name.$error && 'ring-red-500']"
            :aria-invalid="v$.name.$error ? 'true' : undefined"
            :aria-describedby="v$.name.$error ? 'name-error' : undefined"
            :readonly="sending"
            ref="nameRef"
          />

          <div
            v-if="v$.name.$error"
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
          >
            <ExclamationCircleIcon class="h-5 w-5 text-red-500" />
          </div>
        </div>

        <p
          v-if="v$.name.required.$invalid"
          class="mt-2 text-sm text-red-600"
          id="name-error"
        >
          {{
            $pgettext(
              "Ticket Form Name input empty error text.",
              "Name is required.",
            )
          }}
        </p>
      </div>

      <div>
        <label
          for="email"
          class="block text-sm font-medium leading-6 text-gray-900"
          >{{ $pgettext("Ticket Form Email input label.", "Email") }}</label
        >

        <div class="mt-1 relative">
          <input
            type="text"
            name="email"
            id="email"
            autocomplete="email"
            required
            class="block w-full rounded-md border-0 h-10 px-3 text-gray-900 shadow-sm text-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[color:var(--ih-primary-color)]"
            v-model="formData.email"
            :class="[v$.email.$error && 'ring-red-500']"
            :aria-invalid="v$.email.$error ? 'true' : undefined"
            :aria-describedby="v$.email.$error ? 'email-error' : undefined"
            :readonly="sending"
            ref="emailRef"
          />

          <div
            v-if="v$.email.$error"
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
          >
            <ExclamationCircleIcon class="h-5 w-5 text-red-500" />
          </div>
        </div>

        <p
          v-if="v$.email.required.$invalid"
          class="mt-2 text-sm text-red-600"
          id="email-error"
        >
          {{
            $pgettext(
              "Ticket Form Email input empty error text.",
              "Email is required.",
            )
          }}
        </p>

        <p
          v-if="v$.email.email.$invalid"
          class="mt-2 text-sm text-red-600"
          id="email-error"
        >
          {{
            $pgettext(
              "Ticket Form Email input invalid error text.",
              "Email is invalid.",
            )
          }}
        </p>
      </div>

      <div>
        <label
          for="subject"
          class="block text-sm font-medium leading-6 text-gray-900"
          >{{ $pgettext("Ticket Form Subject input label.", "Subject") }}</label
        >

        <div class="mt-1 relative">
          <input
            type="text"
            name="subject"
            id="subject"
            required
            class="block w-full rounded-md border-0 h-10 px-3 text-gray-900 shadow-sm text-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[color:var(--ih-primary-color)]"
            v-model="formData.subject"
            :class="[v$.subject.$error && 'ring-red-500']"
            :aria-invalid="v$.subject.$error ? 'true' : undefined"
            :aria-describedby="v$.subject.$error ? 'subject-error' : undefined"
            :readonly="sending"
            ref="subjectRef"
          />

          <div
            v-if="v$.subject.$error"
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
          >
            <ExclamationCircleIcon class="h-5 w-5 text-red-500" />
          </div>
        </div>

        <p
          v-if="v$.subject.required.$invalid"
          class="mt-2 text-sm text-red-600"
          id="subject-error"
        >
          {{
            $pgettext(
              "Ticket Form Subject input empty error text.",
              "Subject is required.",
            )
          }}
        </p>
      </div>

      <div>
        <label
          for="message"
          class="block text-sm font-medium leading-6 text-gray-900"
          >{{ $pgettext("Ticket Form Message input label.", "Message") }}</label
        >

        <div class="mt-1 relative">
          <textarea
            name="message"
            id="message"
            required
            class="block w-full min-h-[2.5rem] max-h-48 rounded-md border-0 p-3 text-gray-900 shadow-sm text-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[color:var(--ih-primary-color)]"
            rows="5"
            v-model="formData.message"
            :class="[v$.message.$error && 'ring-red-500']"
            :aria-invalid="v$.message.$error ? 'true' : undefined"
            :aria-describedby="v$.message.$error ? 'message-error' : undefined"
            :readonly="sending"
            ref="messageRef"
          />

          <div
            v-if="v$.message.$error"
            class="pointer-events-none absolute top-0 right-0 flex items-center pr-3 pt-3"
          >
            <ExclamationCircleIcon class="h-5 w-5 text-red-500" />
          </div>
        </div>

        <p
          v-if="v$.message.required.$invalid"
          class="mt-2 text-sm text-red-600"
          id="message-error"
        >
          {{
            $pgettext(
              "Ticket Form Message input empty error text.",
              "Message is required.",
            )
          }}
        </p>
      </div>

      <button
        type="submit"
        class="float-right rounded-md bg-[color:var(--ih-secondary-color)] min-w-min w-1/2 px-3 py-2 leading-tight text-sm font-semibold shadow-md hover:bg-[color:var(--ih-secondary-color)] hover:bg-opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[color:var(--ih-secondary-color)]"
        :disabled="sending"
      >
        {{ $pgettext("Ticket Form send button text.", "Send") }}
      </button>
    </form>
  </div>
</template>

<script setup lang="ts">
  import { nextTick, onBeforeUnmount, onMounted, reactive, ref } from "vue";
  import { ExclamationCircleIcon } from "@heroicons/vue/24/outline";
  import { useVuelidate } from "@vuelidate/core";
  import { email, required } from "@vuelidate/validators";

  import type { TicketFormMetadata } from "@/modules/ticket-form";
  import { useTicketForm } from "@/modules/ticket-form";

  import focusElement from "@/util/focus-element";

  import { useUserStore } from "@/store/user";

  import { useHttpClientApi } from "@/lib/http";

  import AppLoader from "@/components/AppLoader.vue";

  const ticketForm = useTicketForm();

  const userStore = useUserStore();

  const httpClientApi = useHttpClientApi();

  const nameRef = ref<HTMLInputElement>();
  const emailRef = ref<HTMLInputElement>();
  const subjectRef = ref<HTMLInputElement>();
  const messageRef = ref<HTMLInputElement>();

  const refs = {
    name: nameRef,
    email: emailRef,
    subject: subjectRef,
    message: messageRef,
  };

  type RefKeys = keyof typeof refs;

  const formData = reactive<TicketFormMetadata>({
    name: (userStore.user?.traits?.name as string) ?? "",
    email: (userStore.user?.traits?.email as string) ?? "",
    subject: "",
    message: "",
  });

  const sending = ref(false);
  const success = ref(false);
  const failure = ref(false);

  const v$ = useVuelidate(
    {
      name: { required, $lazy: true },
      email: { required, email, $lazy: true },
      subject: { required, $lazy: true },
      message: { required, $lazy: true },
    },
    formData,
  );

  onMounted(() => {
    ticketForm.activate();

    nextTick(() => {
      let focusRef = subjectRef;

      switch (true) {
        case formData.name === "":
          focusRef.value = nameRef.value;
          break;
        case formData.email === "":
          focusRef.value = emailRef.value;
          break;
      }

      focusElement(focusRef.value as HTMLInputElement);
    });
  });

  onBeforeUnmount(() => {
    ticketForm.deactivate();
  });

  const send = async () => {
    success.value = false;
    failure.value = false;

    const valid = await v$.value.$validate();

    if (!valid) {
      focusElement(
        refs[v$.value.$errors.at(0)?.$property as RefKeys]
          .value as HTMLInputElement,
      );

      return;
    }

    sending.value = true;

    try {
      await httpClientApi.sendTicketForm(
        `Ticket: (${formData.subject}) ${formData.message}`,
        formData,
      );

      success.value = true;

      v$.value.$reset();

      for (const key in formData) {
        formData[key as RefKeys] = "";
      }

      focusElement(nameRef.value as HTMLInputElement);
    } catch {
      failure.value = true;
    } finally {
      sending.value = false;
    }
  };
</script>
