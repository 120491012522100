import type {
  RouteLocationNormalized,
  Router,
  RouteRecordRaw,
} from "vue-router";
import { createRouter } from "vue-router";
import { isArray, startCase } from "lodash-es";

import { createLocalStorageHistory } from "@/router/local-storage-history";

import type { Section, SectionType } from "@/store/config";
import {
  SECTION_TYPE_CHATBOT,
  SECTION_TYPE_KNOWLEDGE_BASE,
  SECTION_TYPE_TICKET_FORM,
} from "@/store/config";

import type { DeepPartial } from "@/types";

import HomeSection from "@/views/HomeSection.vue";
import NotFoundSection from "@/views/NotFoundSection.vue";
import ChatbotModule from "@/modules/chatbot/ChatbotModule.vue";
import TicketFormModule from "@/modules/ticket-form/TicketFormModule.vue";
import KnowledgeBaseModule from "@/modules/knowledge-base/KnowledgeBaseModule.vue";
import KnowledgeBaseContentsDetailModule from "@/modules/knowledge-base/KnowledgeBaseContentsDetailModule.vue";
import KnowledgeBaseArticleDetailModule from "@/modules/knowledge-base/KnowledgeBaseArticleDetailModule.vue";

type RouterOptions = {
  sections: Readonly<DeepPartial<Section>[]>;
};

let router: Router | undefined;

const getSectionTitle = (
  sections: Readonly<DeepPartial<Section>[]>,
  type: SectionType,
) => sections.find(section => section.type === type)?.title;

const initRouter = (options?: RouterOptions) => {
  if (!options) {
    throw new Error(
      "Options are required to initialize the router. Have you forgot to pass options parameter to useAppRouter?",
    );
  }

  const { sections } = options;

  const routes: RouteRecordRaw[] = [
    {
      name: "home",
      path: "/",
      component: HomeSection,
    },
    {
      name: "chatbot",
      path: `/${SECTION_TYPE_CHATBOT}`,
      component: ChatbotModule,
      meta: {
        title: getSectionTitle(sections, SECTION_TYPE_CHATBOT),
      },
    },
    {
      name: "ticket_form",
      path: `/${SECTION_TYPE_TICKET_FORM}`,
      component: TicketFormModule,
      meta: {
        title: getSectionTitle(sections, SECTION_TYPE_TICKET_FORM),
      },
    },
    {
      name: "knowledge_base",
      path: `/${SECTION_TYPE_KNOWLEDGE_BASE}`,
      component: {
        template: "<RouterView />",
      },
      children: [
        {
          name: "knowledge_base_root",
          path: "",
          component: KnowledgeBaseModule,
          meta: {
            title: getSectionTitle(sections, SECTION_TYPE_KNOWLEDGE_BASE),
          },
        },
        {
          name: "knowledge_base_contents_detail",
          path: `contents/:type/:id`,
          component: KnowledgeBaseContentsDetailModule,
          meta: {
            title: getSectionTitle(sections, SECTION_TYPE_KNOWLEDGE_BASE),
          },
        },
        {
          name: "knowledge_base_article_detail",
          path: `article/:id`,
          component: KnowledgeBaseArticleDetailModule,
          meta: {
            title: getSectionTitle(sections, SECTION_TYPE_KNOWLEDGE_BASE),
            widgetExpandable: true,
          },
        },
      ],
    },
    {
      name: "not_found",
      path: "/:pathMatch(.*)*",
      component: NotFoundSection,
      beforeEnter(to: RouteLocationNormalized) {
        const pathMatch = isArray(to.params.pathMatch)
          ? to.params.pathMatch
          : [to.params.pathMatch];

        to.meta.title = startCase(pathMatch.join(" "));
      },
    },
  ];

  router = createRouter({
    history: createLocalStorageHistory(),
    routes,
  });
};

export const useAppRouter = (options?: RouterOptions) => {
  if (!router) {
    initRouter(options);
  }

  const $reset = () => {
    (router as Router).push("/");
  };

  return { router: router as Router, $reset };
};
