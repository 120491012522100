import type {
  ComponentOptions,
  ComponentPropsOptions,
  DefineComponent,
  SetupContext,
} from "vue";
import { defineComponent } from "vue";
import { defaults, flow } from "lodash-es";
import type { NavigationFailure } from "vue-router";
import { Marked } from "marked";
import DOMPurify from "dompurify";

import { SECTION_TYPE_KNOWLEDGE_BASE } from "@/store/config";
import {
  KNOWLEDGE_BASE_CONTENTS_ARTICLE,
  KNOWLEDGE_BASE_CONTENTS_CATEGORY,
  KNOWLEDGE_BASE_CONTENTS_FOLDER,
} from "@/store/knowledge-base";

import logger from "@/core/logger";

import { useKnowledgeBase } from "@/modules/knowledge-base";

import { useStateStore } from "@/store/state";

import ExpandableImage from "@/components/ExpandableImage.vue";
import ExplainThisButton from "@/components/ExplainThisButton.vue";

type ComponentWithTemplate = ComponentOptions & { template: string };

type Options = {
  escapeVueTemplateCharacters: boolean;
  replaceExplainThisLink: boolean;
  replaceKnowledgeBaseLinks: boolean;
  replaceExpandableImages: boolean;
  preserveNewLines: boolean;
  parseMarkdown: boolean;
};

const noop = (component: ComponentWithTemplate) => component;

const wrapContentToComponent = (component: ComponentWithTemplate) =>
  defineComponent(component) as DefineComponent;

const replaceExpandableImages = (component: ComponentWithTemplate) => {
  return {
    ...component,
    template: component.template.replaceAll(
      /<img.*?src=["'](.*?)["'].*?\/?>/gis,
      '<ExpandableImage src="$1">$&</ExpandableImage>',
    ),
    components: { ...component.components, ExpandableImage },
  };
};

const replaceKnowledgeBaseLinks = (component: ComponentWithTemplate) => {
  const matches = component.template.matchAll(
    /\[(\w+?):([\w-]+?)]\((.*?)\)/gis,
  );

  let replacedContent = component.template;

  for (const match of matches) {
    const [original, type, id, title] = match;

    if (!title) {
      logger.warn(
        "Matched internal link for replacement, but looks like it has no title. Link would still be replaced, but inaccessible to the Users.",
        {
          type,
          id,
        },
        "ContentResolvers:KnowledgeBaseLinks",
      );
    }

    let route = `/${SECTION_TYPE_KNOWLEDGE_BASE}`;

    switch (type) {
      case KNOWLEDGE_BASE_CONTENTS_ARTICLE: {
        route += `/article/${id}`;

        break;
      }
      case KNOWLEDGE_BASE_CONTENTS_CATEGORY:
      case KNOWLEDGE_BASE_CONTENTS_FOLDER: {
        route += `/contents/${type}/${id}`;

        break;
      }
    }

    replacedContent = replacedContent.replace(
      original,
      `<RouterLink custom v-slot="{navigate}" to="${route}"><a href="${route}" class="underline hover:no-underline" @click.prevent.stop="customNavigate(navigate)">${title}</a></RouterLink>`,
    );
  }

  return {
    ...component,
    template: replacedContent,
    setup(props: ComponentPropsOptions, ctx: SetupContext) {
      const stateStore = useStateStore();

      const knowledgeBase = useKnowledgeBase();

      const customNavigate = (
        navigate: (e?: MouseEvent) => Promise<void | NavigationFailure> | void,
      ) => {
        stateStore.hideExplainThisTooltip();

        knowledgeBase.activate();

        navigate();
      };

      return { ...component?.setup?.(props, ctx), customNavigate };
    },
  };
};

const replaceExplainThisLink = (component: ComponentWithTemplate) => {
  const matches = component.template.matchAll(/\[explain_this:(.+?)]/gis);

  let replacedContent = component.template;

  for (const match of matches) {
    replacedContent = replacedContent.replace(
      match[0],
      `<ExplainThisButton>${match[1]}</ExplainThisButton>`,
    );
  }

  return {
    ...component,
    template: replacedContent,
    components: { ...component.components, ExplainThisButton },
  };
};

const escapeVueTemplateCharacters = (component: ComponentWithTemplate) => {
  return {
    ...component,
    template: component.template.replaceAll("{{", "&#123;&#123;"),
  };
};

const preserveNewLines = (component: ComponentWithTemplate) => {
  return {
    ...component,
    template: component.template.replaceAll(/\r\n|\n|\r/gis, "<br>"),
  };
};

const parseMarkdown = (component: ComponentWithTemplate) => {
  const marked = new Marked();

  return {
    ...component,
    template: DOMPurify.sanitize(marked.parse(component.template) as string),
  };
};

export const replacePlaceholders = (
  template: string,
  options?: Partial<Options>,
) => {
  const defaultOptions = {
    escapeVueTemplateCharacters: true,
    replaceExplainThisLink: true,
    replaceKnowledgeBaseLinks: true,
    replaceExpandableImages: true,
    preserveNewLines: true,
    parseMarkdown: true,
  };

  const resolvedOptions = defaults(options, defaultOptions);

  const parser = new DOMParser();

  if (resolvedOptions.parseMarkdown) {
    template = parseMarkdown({ template }).template;
  }

  template = parser.parseFromString(template, "text/html").body.innerHTML;

  const replacers = [
    resolvedOptions.escapeVueTemplateCharacters
      ? escapeVueTemplateCharacters
      : noop,
    resolvedOptions.replaceExplainThisLink ? replaceExplainThisLink : noop,
    resolvedOptions.replaceKnowledgeBaseLinks
      ? replaceKnowledgeBaseLinks
      : noop,
    resolvedOptions.replaceExpandableImages ? replaceExpandableImages : noop,
    resolvedOptions.preserveNewLines && !resolvedOptions.parseMarkdown
      ? preserveNewLines
      : noop,
  ];

  return wrapContentToComponent(flow(replacers)({ template }));
};
