import type { App, DefineComponent, Ref } from "vue";
import {
  createApp,
  defineCustomElement,
  getCurrentInstance,
  h,
  ref,
} from "vue";

import type { JSONObject } from "@/types";

import logger from "@/core/logger";

import { convertPropsToHtmlAttributes } from "@/util/component";

import appStyles from "@/assets/styles/index.scss?inline";

type MountOptions = {
  app: App;
  unmountRequest: Ref<boolean>;
  unmountReady: Ref<() => void>;
  target?: HTMLElement;
  cb?: (domElement: HTMLElement) => () => void;
};

export const createAppWithDelayedUnmount = (
  tag: string,
  name: string,
  props?: Ref<JSONObject> | JSONObject,
) => {
  const unmountRequest = ref(false);
  const unmountReady = ref(() => {});

  const refProps = ref(props);

  const app = createApp({
    name,
    render: () =>
      h(tag, {
        ...(refProps.value ? convertPropsToHtmlAttributes(refProps.value) : {}),
        unmountRequest: unmountRequest.value,
        unmountReady: unmountReady.value,
      }),
  });

  return { app, unmountRequest, unmountReady };
};

export const mountWithDelayedUnmount = ({
  app,
  unmountRequest,
  unmountReady,
  target,
  cb,
}: MountOptions) => {
  const fragment = document.createDocumentFragment();

  app.mount(fragment);

  const domElement = fragment.children.item(0) as Element;

  domElement.id = new Date().getTime().toString();

  target = target ?? document.body;

  target.appendChild(domElement);

  const unmountCallback = cb?.(domElement as HTMLElement);

  const unmount = async () => {
    app.unmount();

    domElement.remove();

    unmountCallback?.();
  };

  unmountReady.value = unmount;

  return async (immediate = false) => {
    if (immediate) {
      await unmount();

      return;
    }

    unmountRequest.value = true;
  };
};

const isCustomElementRegistered = (tag: string) => {
  return window.customElements.get(tag) !== undefined;
};

const getNameFromFilePath = (filePath: string) => {
  return filePath
    .split("/")
    .pop()
    ?.replace(/(\.global|\.ce)?\.vue/, "") as string;
};

const importGlobalComponents = () => {
  const globalComponents = import.meta.glob<DefineComponent>(
    "../(modules|components)/**/*.global.vue",
    {
      eager: true,
      import: "default",
    },
  );

  return [
    ...Object.entries(globalComponents).map(([filePath, component]) => ({
      name: getNameFromFilePath(filePath),
      component: component as DefineComponent,
    })),
  ];
};

const registerGlobalComponents = (
  app: App,
  globalComponents: {
    name: string;
    component: DefineComponent;
  }[],
) => {
  for (const { name, component } of globalComponents) {
    logger.silly("Registered.", null, `WebComponent:GlobalComponent:${name}`);

    app.component(name, component);
  }
};

export const maybeRegisterCustomElement = (
  tag: string,
  component: DefineComponent,
  plugins: any[] = [],
) => {
  const globalComponents = importGlobalComponents();

  if (isCustomElementRegistered(tag)) {
    return { globalComponents };
  }

  // until https://github.com/vuejs/core/issues/4635 is fixed we need to use the fake createApp to be able to install plugins to the customElement components
  window.customElements.define(
    tag,
    defineCustomElement({
      props: component.props,
      styles: [appStyles, component.styles],
      render() {
        return h(component, this.$props);
      },
      setup() {
        // @ts-expect-error We're applying a workaround and need just the Vue App skeleton
        const app = createApp();

        plugins.forEach(app.use);

        registerGlobalComponents(app, globalComponents);

        const inst = getCurrentInstance();

        if (inst) {
          inst.appContext = app._context;

          // @ts-expect-error We're applying a workaround and are accessing Vue App internals
          inst.provides = app._context.provides;
        }
      },
    }),
  );

  logger.verbose("Registered.", { plugins, component }, `WebComponent:${tag}`);

  return { globalComponents };
};
