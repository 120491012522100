<template>
  <Transition
    appear
    enter-active-class="ease-in duration-200"
    :enter-from-class="`opacity-0 ${
      isHorizontalPositionRight ? 'translate-x-1/4' : '-translate-x-1/4'
    }`"
    enter-to-class="opacity-100"
    leave-active-class="ease-out duration-100"
    leave-from-class="opacity-100"
    :leave-to-class="`opacity-0 ${
      isHorizontalPositionRight ? 'translate-x-1/4' : '-translate-x-1/4'
    }`"
  >
    <div
      class="sticky p-2 bg-white flex items-center gap-x-4 rounded-xl outline outline-1 -outline-offset-1 outline-gray-300 drop-shadow"
    >
      <CursorArrowRaysIcon class="h-8 w-8 rounded-full opacity-30" />

      <div class="min-w-0 text-xs">
        <p class="text-gray-900">
          <span>
            {{
              $pgettext(
                "Part of the Launcher Explain This Toast message. Full message: 'Click on (any|allowed) part(s) of the webpage to get and explanation.'",
                "Click on ",
              )
            }}
          </span>

          <span v-if="!hasAllowed">
            {{
              $pgettext(
                "Part of the Launcher Explain This Toast message. Full message: 'Click on (any|allowed) part(s) of the webpage to get and explanation.'",
                "any",
              )
            }}
          </span>

          <span v-if="hasAllowed" class="text-green-600">
            {{
              $pgettext(
                "Part of the Launcher Explain This Toast message. Full message: 'Click on (any|allowed) part(s) of the webpage to get and explanation.'",
                "allowed",
              )
            }}
          </span>

          <span>
            {{
              $pgettext(
                "Part of the Launcher Explain This Toast message. Full message: 'Click on (any|allowed) part(s) of the webpage to get and explanation.'",
                " part(s) of the webpage to get and explanation.",
              )
            }}
          </span>
        </p>

        <p v-if="hasDenied">
          <span>
            {{
              $pgettext(
                "Part of the Launcher Explain This Toast message. Full message: 'Some part(s) are disallowed.'",
                "Some part(s) are ",
              )
            }}
          </span>

          <span class="text-red-600">
            {{
              $pgettext(
                "Part of the Launcher Explain This Toast message. Full message: 'Some part(s) are disallowed.'",
                "disallowed.",
              )
            }}
          </span>
        </p>

        <button
          class="text-[color:var(--ih-primary-color)] underline cursor-pointer hover:no-underline focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[color:var(--ih-primary-color)]"
          @click="close()"
        >
          {{
            $pgettext(
              "The Launcher Explain This Toast message cancel button which deactivates the Explain This.",
              "Cancel",
            )
          }}
        </button>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
  import type { DeepReadonly } from "vue";
  import { CursorArrowRaysIcon } from "@heroicons/vue/20/solid";

  import type { Config } from "@/store/config";

  type Props = {
    config: DeepReadonly<Config>;
    isHorizontalPositionRight: boolean;
  };

  const props = defineProps<Props>();

  const emit = defineEmits<{
    close: [];
  }>();

  const hasAllowed = props.config.explainThis.allowList.length > 0;
  const hasDenied = props.config.explainThis.denyList.length > 0;

  const close = () => emit("close");
</script>
