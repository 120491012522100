<template>
  <DefaultHomeSectionListItem
    :navigate="() => chatbot.activate()"
    :section="section"
  >
    <template #image>
      <ChatBubbleLeftRightIcon
        class="text-[color:var(--ih-primary-color)] h-8 w-8"
      />
    </template>
  </DefaultHomeSectionListItem>
</template>

<script setup lang="ts">
  import { ChatBubbleLeftRightIcon } from "@heroicons/vue/24/outline";

  import type { Section } from "@/store/config";

  import { useChatbot } from "@/modules/chatbot";

  import DefaultHomeSectionListItem from "@/components/DefaultHomeSectionListItem.global.vue";

  defineOptions({ inheritAttrs: false });

  type Props = {
    section: Section;
  };

  defineProps<Props>();

  const chatbot = useChatbot();
</script>
