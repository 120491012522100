<template>
  <div class="text-xs text-gray-500 leading-none relative group">
    <div ref="sourcesTriggerRef" class="flex gap-x-1 items-center">
      <span>{{
        $pgettext(
          "Sources dropdown label which opens sources list when clicked on.",
          "Source",
        )
      }}</span>

      <ChevronDownIcon class="w-4 h-4" />
    </div>

    <div
      class="shrink-0 z-10 bg-white max-w-[theme(spacing.64)] w-max break-words rounded-md p-2 shadow-md border border-gray-100 items-start hidden group-hover:flex group-hover:flex-col gap-2"
      ref="sourcesListRef"
      :style="floatingStyles"
    >
      <template v-for="source in sources" :key="source.id">
        <RouterLink
          custom
          v-if="source.type === MESSAGE_SOURCE_ARTICLE"
          :to="`/${SECTION_TYPE_KNOWLEDGE_BASE}/article/${source.id}`"
          v-slot="{ navigate }"
        >
          <button
            type="button"
            @click.prevent.stop="openWidgetAndNavigateToSource(navigate)"
            class="hover:underline cursor-pointer w-full leading-4 text-left"
          >
            {{ source.title }}
          </button>
        </RouterLink>

        <button
          v-else
          type="button"
          @click.prevent.stop="openSourceUrl(source.url)"
          class="hover:underline cursor-pointer w-full inline-block leading-4 text-left relative"
        >
          <span class="pr-6">{{ source.title }}</span>

          <ArrowTopRightOnSquareIcon
            class="w-4 h-4 shrink-0 absolute top-1 right-0"
          />
        </button>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { DeepReadonly } from "vue";
  import { ref } from "vue";
  import {
    ArrowTopRightOnSquareIcon,
    ChevronDownIcon,
  } from "@heroicons/vue/24/outline";
  import { autoUpdate, flip, useFloating } from "@floating-ui/vue";
  import type { NavigationFailure } from "vue-router";

  import type { Message } from "@/store/conversation";
  import { MESSAGE_SOURCE_ARTICLE } from "@/store/conversation";

  import { SECTION_TYPE_KNOWLEDGE_BASE } from "@/store/config";

  import { useKnowledgeBase } from "@/modules/knowledge-base";

  type Props = {
    sources: DeepReadonly<Message>["metadata"]["sources"];
  };

  defineProps<Props>();

  const emit = defineEmits<{
    click: [];
  }>();

  const sourcesTriggerRef = ref(null);

  const sourcesListRef = ref(null);

  const { floatingStyles } = useFloating(sourcesTriggerRef, sourcesListRef, {
    whileElementsMounted: autoUpdate,
    middleware: [flip()],
  });

  const knowledgeBase = useKnowledgeBase();

  const openWidgetAndNavigateToSource = (
    navigate: (e?: MouseEvent | undefined) => Promise<void | NavigationFailure>,
  ) => {
    knowledgeBase.activate();

    navigate();

    emit("click");
  };

  const openSourceUrl = (url: string) => {
    window.open(url, "_blank");

    emit("click");
  };
</script>
