<template>
  <button
    class="text-[color:var(--ih-primary-color)] hover:underline focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[color:var(--ih-primary-color)]"
    @click="activateExplainThis()"
  >
    <slot />
  </button>
</template>

<script setup lang="ts">
  import { useExplainThis } from "@/modules/hints";

  const activateExplainThis = () => {
    const explainThis = useExplainThis();

    explainThis.activate();
  };
</script>
