<template>
  <Transition
    appear
    enter-active-class="ease-out duration-200"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="ease-in duration-100"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
    @after-enter="afterEnter"
  >
    <div
      v-if="launcherVisible"
      class="fixed bottom-10 flex items-center gap-4"
      :class="[horizontalPositionClass, spaceSideClass, spaceBottomClass]"
    >
      <TheLauncherExplainThisToast
        v-if="stateStore.explainThisToastVisible"
        :config="configStore.config"
        :is-horizontal-position-right="isHorizontalPositionRight"
        :class="[!isHorizontalPositionRight && 'order-1']"
        @close="closeExplainThisToast()"
      />

      <template v-if="configStore.config.widget.launcher.enabled">
        <TheLauncherNudge
          v-if="nudgeVisible"
          :config="configStore.config"
          :is-horizontal-position-right="isHorizontalPositionRight"
          @close="closeNudge()"
        />

        <TheLauncherIcon
          :config="configStore.config"
          :class="[!isHorizontalPositionRight && 'order-0']"
          @click="click()"
        />
      </template>
    </div>
  </Transition>
</template>

<script setup lang="ts">
  import { nextTick, onMounted, ref, watch } from "vue";
  import { useStorage } from "@vueuse/core";

  import { useExplainThis, useTooltips } from "@/modules/hints";

  import { useConfigStore } from "@/store/config";

  import { useStateStore } from "@/store/state";

  import { getStorageItemName } from "@/lib/storage";

  import TheLauncherIcon from "./TheLauncherIcon.vue";
  import TheLauncherNudge from "./TheLauncherNudge.vue";
  import TheLauncherExplainThisToast from "./TheLauncherExplainThisToast.vue";

  type Props = {
    visible: boolean;
  };

  const props = withDefaults(defineProps<Props>(), {
    visible: false,
  });

  const configStore = useConfigStore();

  const stateStore = useStateStore();

  const tooltips = useTooltips();

  const launcherVisible = ref(false);

  const nudgeVisible = ref(false);
  const nudgeEnabled = useStorage<boolean>(
    getStorageItemName("ih_nudge_enabled"),
    configStore.config.widget.launcher.nudge.enabled,
    localStorage,
  );
  const nudgeTimer = ref();

  watch(
    () => props.visible,
    value => {
      nextTick(() => {
        if (value) {
          launcherVisible.value = true;
        }

        if (!value) {
          launcherVisible.value = false;
          stateStore.explainThisToastVisible = false;
        }
      });
    },
    { immediate: true },
  );

  const emit = defineEmits<{
    click: [];
  }>();

  const horizontalPosition = configStore.config.widget.style.launcher.position;
  const isHorizontalPositionRight = horizontalPosition === "right";
  const horizontalPositionClass = isHorizontalPositionRight
    ? "right-10"
    : "left-10";

  const spaceSideClass = configStore.config.widget.style.launcher.space.side
    ? isHorizontalPositionRight
      ? "-translate-x-[--ih-launcher-space-side]"
      : "translate-x-[--ih-launcher-space-side]"
    : "";

  const spaceBottomClass = configStore.config.widget.style.launcher.space.bottom
    ? "-translate-y-[--ih-launcher-space-bottom]"
    : "";

  const explainThis = useExplainThis();

  onMounted(() => {
    if (nudgeEnabled.value) {
      nudgeTimer.value = setTimeout(() => {
        nudgeVisible.value = true;
      }, configStore.config.widget.launcher.nudge.timeout);
    }
  });

  const click = () => {
    clearTimeout(nudgeTimer.value);

    closeNudge();

    stateStore.hideExplainThisTooltip();

    if (explainThis.isActive.value) {
      explainThis.deactivate();
    }

    emit("click");
  };

  const closeExplainThisToast = () => {
    stateStore.explainThisToastVisible = false;

    explainThis.deactivate();

    tooltips.activate();
  };

  const afterEnter = () => {
    if (explainThis.isActive.value) {
      stateStore.explainThisToastVisible = true;
    }
  };

  const closeNudge = () => {
    nudgeVisible.value = false;

    nudgeEnabled.value = false;
  };
</script>
