import type { MaybeRef } from "vue";
import { toValue } from "vue";

import type { MessageMetadataEmbed } from "@/store/conversation";

import logger from "@/core/logger";

import { FEATURE_CHATBOT, useStateStore } from "@/store/state";

const LOGGER_NAMESPACE = "ChatEmbed";

export const activateEmbed = (embed: MaybeRef<MessageMetadataEmbed>) => {
  const stateStore = useStateStore();

  embed = toValue(embed);

  logger.verbose("Activating embed.", { embed }, LOGGER_NAMESPACE);

  switch (embed.type) {
    case "im_player_activate_topic": {
      if (!window.inline_manual_player) {
        logger.error(
          "Inline Manual Player is not integrated. Unable to activate the embed.",
          null,
          LOGGER_NAMESPACE,
        );

        break;
      }

      if (embed.data?.topicId === undefined || embed.data.topicId === null) {
        logger.error(
          "Inline Manual Topic ID is not defined. Unable to activate the embed.",
          null,
          LOGGER_NAMESPACE,
        );

        break;
      }

      window.inline_manual_player?.activateTopic?.(
        embed.data.topicId as string,
      );

      if (stateStore.activeFeature === FEATURE_CHATBOT) {
        stateStore.toggleLauncherWidgetVisibility();
      }

      break;
    }
  }
};
