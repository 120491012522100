import {
  camelCase,
  fromPairs,
  isArray,
  isObject,
  kebabCase,
  map,
  snakeCase,
  toPairs,
} from "lodash-es";

import type { JSONObject } from "@/types";

export const convertKeysToCamelCaseRecursive = <I, O>(response: I): O => {
  if (isArray(response)) {
    return map(response, value => convertKeysToCamelCaseRecursive(value)) as O;
  }

  if (isObject(response)) {
    const pairs = toPairs(response);

    const res: [string, any][] = map(pairs, ([key, value]) => [
      camelCase(key),
      convertKeysToCamelCaseRecursive(value),
    ]);

    return fromPairs(res) as O;
  }

  return response as unknown as O;
};

export const convertKeysToSnakeCaseRecursive = <I, O>(response: I): O => {
  if (isArray(response)) {
    return map(response, value => convertKeysToSnakeCaseRecursive(value)) as O;
  }

  if (isObject(response)) {
    const pairs = toPairs(response);

    const res: [string, any][] = map(pairs, ([key, value]) => [
      snakeCase(key),
      convertKeysToSnakeCaseRecursive(value),
    ]);

    return fromPairs(res) as O;
  }

  return response as unknown as O;
};

export const convertObjectKeysToKebabCase = <T extends JSONObject>(
  props: T,
) => {
  return Object.fromEntries(
    Object.entries(props).map(([property, value]) => [
      kebabCase(property),
      value,
    ]),
  );
};
