<template>
  <div @click="toggle()" class="cursor-pointer">
    <slot />
  </div>

  <Transition
    appear
    enter-active-class="ease-in duration-100"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="ease-in duration-50"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <Teleport
      :to="hostTeleportTargetRef"
      :disabled="!hostTeleportTargetRef"
      v-if="expanded"
    >
      <div
        class="fixed inset-0 bg-gray-500 z-highest backdrop-blur bg-opacity-50 transition-opacity"
        key="backdrop"
      ></div>

      <div
        class="fixed m-3 inset-0 cursor-pointer z-highest flex items-center justify-center transition-all"
        key="image"
        @click="toggle()"
      >
        <img :src="src" alt="" class="max-h-full object-contain" />
      </div>
    </Teleport>
  </Transition>
</template>

<script setup lang="ts">
  import { inject, ref } from "vue";

  type Props = {
    src: string;
  };

  defineProps<Props>();

  const expanded = ref(false);

  const toggle = () => {
    expanded.value = !expanded.value;
  };

  const hostTeleportTargetRef = inject<HTMLElement>("hostTeleportTargetRef");
</script>
