import anime from "animejs";
import { debounce } from "lodash-es";

import { tailwindConfig } from "@/util/tailwind";

import type { IhHTMLElement } from "./index";
import { IH_NODE_NAME } from "./index";

import { isElementInAllowedScope, isElementInDeniedScope } from "./scope";

let floatingOutlineElement: HTMLElement | undefined;

export const moveFloatingOutline = debounce(
  (element: HTMLElement, style: string) => {
    const elementRect = element.getBoundingClientRect();

    if (!floatingOutlineElement) {
      floatingOutlineElement = document.createElement("div");

      floatingOutlineElement.style.cssText = `${style} position: absolute; pointer-events: none; opacity: 0; z-index: ${tailwindConfig.theme.zIndex.highest};`;

      document.body.append(floatingOutlineElement);
    }

    anime({
      targets: floatingOutlineElement,
      left: elementRect.left,
      top: elementRect.top + window.scrollY,
      width: elementRect.width,
      height: elementRect.height,
      easing: "linear",
      duration: 200,
      opacity: 1,
    });
  },
  125,
);

export const addOutline = (
  element: IhHTMLElement,
  style: string,
  temporal = false,
) => {
  if (element.nodeName === IH_NODE_NAME) {
    return;
  }

  if (!element.__ih_originalAlreadySet) {
    element.__ih_originalAlreadySet = true;

    element.__ih_originalStyle = element.style.cssText;
  }

  element.__ih_styleHistory = element.__ih_styleHistory || [];

  element.__ih_styleHistory.push({ style, temporal });

  element.style.cssText = `${element.__ih_originalStyle} ${style}`;

  if (!temporal) {
    element.setAttribute("ih-outlined", "");
  }
};

export const removeOutline = (element: IhHTMLElement) => {
  if (!element) {
    return;
  }

  const styleHistoryItem = element.__ih_styleHistory?.pop();

  if (styleHistoryItem?.temporal) {
    removeOutline(element);

    return;
  }

  if (styleHistoryItem) {
    element.__ih_styleHistory?.push(styleHistoryItem);
  }

  element.style.cssText =
    styleHistoryItem?.style || element.__ih_originalStyle || "";
};

export const removeAllOutlines = () => {
  const elements = document.querySelectorAll<IhHTMLElement>("[ih-outlined]");

  if (floatingOutlineElement) {
    floatingOutlineElement.remove();

    floatingOutlineElement = undefined;
  }

  for (const element of elements) {
    element.style.cssText = element.__ih_originalStyle || "";

    element.__ih_originalAlreadySet = false;

    element.removeAttribute("ih-outlined");
  }
};

export const outlineAllowed = (
  style: string,
  allowList: Readonly<string[]>,
  denyList: Readonly<string[]>,
) => {
  if (!allowList.length) {
    return;
  }

  const elements = document.querySelectorAll<HTMLElement>(allowList.join(","));

  for (const element of elements) {
    if (
      isElementInDeniedScope(element, denyList) ||
      isElementInAllowedScope(element, allowList)
    ) {
      continue;
    }

    addOutline(element, style);
  }
};

export const outlineDenied = (style: string, denyList: Readonly<string[]>) => {
  if (!denyList.length) {
    return;
  }

  const elements = document.querySelectorAll<HTMLElement>(denyList.join(","));

  for (const element of elements) {
    if (isElementInDeniedScope(element, denyList)) {
      continue;
    }

    addOutline(element, style);
  }
};
