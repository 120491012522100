<template>
  <div
    class="flex rating-wrapper relative"
    :class="[
      ratingPositive && 'rating-positive',
      ratingNegative && 'rating-negative',
    ]"
  >
    <div class="group relative w-5 h-5" @click.prevent.stop="ratePositive()">
      <HandThumbUpIconOutline
        class="w-5 h-5 text-blue-500 cursor-pointer icon-positive absolute"
      />
      <HandThumbUpIconSolid
        class="w-5 h-5 text-blue-400 stroke-1 stroke-blue-500 group-hover:block cursor-pointer icon-positive absolute"
        :class="[rating === RATING_POSITIVE ? 'block' : 'hidden']"
      />
    </div>

    <div class="spark">
      <span v-for="i in 19" :key="i"></span>
    </div>

    <div class="group relative w-5 h-5" @click.prevent.stop="rateNegative()">
      <HandThumbDownIconOutline
        class="ml-2 w-5 h-5 text-red-500 cursor-pointer icon-negative absolute"
      />
      <HandThumbDownIconSolid
        class="ml-2 w-5 h-5 text-red-400 stroke-1 stroke-red-500 group-hover:block cursor-pointer icon-negative absolute"
        :class="[rating === RATING_NEGATIVE ? 'block' : 'hidden']"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref } from "vue";
  import {
    HandThumbDownIcon as HandThumbDownIconSolid,
    HandThumbUpIcon as HandThumbUpIconSolid,
  } from "@heroicons/vue/24/solid";
  import {
    HandThumbDownIcon as HandThumbDownIconOutline,
    HandThumbUpIcon as HandThumbUpIconOutline,
  } from "@heroicons/vue/24/outline";

  import type { Rating } from "@/components/interfaces.d";
  import { RATING_NEGATIVE, RATING_POSITIVE } from "@/components/interfaces.d";

  type Props = {
    rating?: Rating;
  };

  defineProps<Props>();

  const emit = defineEmits<{
    ratePositive: [];
    rateNegative: [];
  }>();

  const ratingPositive = ref(false);
  const ratingNegative = ref(false);

  const ratePositive = () => {
    ratingPositive.value = true;

    emit("ratePositive");

    setTimeout(() => {
      ratingPositive.value = false;
    }, 1_000);
  };

  const rateNegative = () => {
    ratingNegative.value = true;

    emit("rateNegative");

    setTimeout(() => {
      ratingNegative.value = false;
    }, 1_000);
  };
</script>
