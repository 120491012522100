export const getPathTo = (element: HTMLElement): string => {
  if (element.id !== "") {
    return 'id("' + element.id + '")';
  }

  if (element === document.body) {
    return element.tagName.toLowerCase();
  }

  let ix = 0;

  const siblings = element.parentNode?.childNodes || [];

  for (let i = 0; i < siblings.length; i++) {
    const sibling = siblings[i];

    if (sibling === element) {
      return `${getPathTo(
        element.parentElement || document.body,
      )}/${element.tagName.toLowerCase()}[${ix + 1}]`;
    }

    if (sibling.nodeType === 1 && sibling.nodeName === element.tagName) {
      ix++;
    }
  }

  return "";
};
