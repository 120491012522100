// locales to load for DayJS, have to correspond to AvailableLanguages in client/src/translations/gettext.ts
import "dayjs/esm/locale/en";
import "dayjs/esm/locale/fr";
import "dayjs/esm/locale/de";
import "dayjs/esm/locale/it";
import "dayjs/esm/locale/ja";
import "dayjs/esm/locale/pt-br";
import "dayjs/esm/locale/es";

import dayjs from "dayjs/esm";

import type { AvailableLanguages } from "@/translations/gettext";

export const useDayJS = (language: AvailableLanguages) => {
  let locale = language.toLowerCase().replace("_", "-");

  dayjs.locale(locale);

  if (dayjs.locale() !== locale) {
    locale = locale.split("-").at(0) as string;

    dayjs.locale(locale);
  }

  return dayjs;
};
