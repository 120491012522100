import type { App } from "vue";

import { useGettext } from "@/translations/gettext";
import { useDayJS } from "@/translations/dayjs";

import { useConfigStore } from "@/store/config";

export const useTranslations = () => {
  const configStore = useConfigStore();

  const language = configStore.config.global.language;

  const gettext = useGettext(language);

  const dayjs = useDayJS(language);

  return {
    ...gettext,
    install(app: App) {
      app.use(gettext);
    },
    dayjs,
  };
};
