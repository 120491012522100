<template>
  <div
    ref="launcherRef"
    class="launcher flex aspect-square items-center justify-center cursor-pointer"
    @click.prevent.stop="onClick()"
    @mouseover.prevent.stop="onMouseOver()"
    @mouseout.prevent.stop="onMouseOut()"
  >
    <div class="launcher-icon relative group">
      <div
        class="hidden group-hover:block effect absolute rounded-full h-full w-full scale-125 opacity-50 inset-0"
      ></div>
      <div class="relative z-10" v-html="questionMarkCircleIcon" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, ref, watch } from "vue";

  import type { Tooltip } from "@/store/config";

  import { useStateStore } from "@/store/state";

  import questionMarkCircleIcon from "@/assets/images/tooltip-launcher.svg?raw";

  defineOptions({ inheritAttrs: false });

  type Props = {
    unmountRequest: boolean;
    unmountReady: () => void;
    selector: string;
    html: string;
    text: string;
    tooltipConfig: string;
  };

  const stateStore = useStateStore();

  const props = defineProps<Props>();

  const parsedConfig = computed<Tooltip>(
    () => JSON.parse(props.tooltipConfig) || {},
  );

  const launcherRef = ref();

  watch(
    () => props.unmountRequest,
    state => {
      if (state) {
        props.unmountReady();
      }
    },
  );

  const launcherActivated = ref(false);

  const onClick = () => {
    if (launcherActivated.value) {
      return;
    }

    launcherActivated.value = true;

    stateStore.displayTooltip(
      launcherRef.value,
      parsedConfig.value.popup,
      props.text,
      {
        selector: props.selector,
        html: props.html,
      },
    );
  };

  const onMouseOver = () => {
    clearTimeout(stateStore.tooltipCloseTimer);
  };

  const onMouseOut = () => {
    clearTimeout(stateStore.tooltipCloseTimer);

    stateStore.tooltipCloseTimer = setTimeout(() => {
      stateStore.hideTooltip();

      launcherActivated.value = false;
    }, 1_000);
  };

  watch(
    () => stateStore.tooltip.visible,
    state => {
      if (!state) {
        launcherActivated.value = false;
      }
    },
  );

  const launcherCircleColor = computed(() =>
    launcherActivated.value
      ? parsedConfig.value.launcher.circleColorHover
      : parsedConfig.value.launcher.circleColor,
  );
  const launcherCircleFillColor = computed(() =>
    launcherActivated.value
      ? parsedConfig.value.launcher.circleFillColorHover
      : parsedConfig.value.launcher.circleFillColor,
  );
  const launcherQuestionMarkColor = computed(() =>
    launcherActivated.value
      ? parsedConfig.value.launcher.questionMarkColorHover
      : parsedConfig.value.launcher.questionMarkColor,
  );
  const launcherWidth = computed(
    () => `${parsedConfig.value.launcher.size.width}px`,
  );
  const launcherHeight = computed(
    () => `${parsedConfig.value.launcher.size.height}px`,
  );
</script>

<style lang="scss">
  :host {
    & > * {
      font-family: var(--ih-font-family);
    }
  }

  .launcher-icon {
    .effect {
      background-color: v-bind(launcherCircleFillColor);
    }

    svg {
      width: v-bind(launcherWidth);
      height: v-bind(launcherHeight);

      .circle {
        stroke: v-bind(launcherCircleColor);
        fill: v-bind(launcherCircleFillColor);
      }

      .question-mark {
        stroke: v-bind(launcherQuestionMarkColor);
      }
    }
  }
</style>
