/** @type {import("tailwindcss").Config} */
/** @type {import("tailwindcss").ThemeConfig} */

import defaultTheme from "tailwindcss/defaultTheme";
import tailwindForms from "@tailwindcss/forms";
import tailwindTypography from "@tailwindcss/typography";

const calc = num => `calc(${num} * var(--srem))`;

const isString = value => typeof value === "string";

const replaceRem = value => {
  if (!value.includes("rem")) {
    return value;
  }

  value = value.replace(/(\d*\.?\d+)rem$/, "$1");

  return calc(value);
};

const recursivelyReplaceRems = input => {
  if (isString(input)) {
    return replaceRem(input);
  }

  if (Array.isArray(input)) {
    return input.map(val => recursivelyReplaceRems(val));
  }

  return Object.fromEntries(
    Object.entries(input).map(([key, value]) => {
      return [key, recursivelyReplaceRems(value)];
    }),
  );
};

export default {
  content: ["./src/**/*.{vue,js,ts}"],
  theme: {
    columns: recursivelyReplaceRems(defaultTheme.columns),
    spacing: recursivelyReplaceRems(defaultTheme.spacing),
    borderRadius: recursivelyReplaceRems(defaultTheme.borderRadius),
    fontSize: recursivelyReplaceRems(defaultTheme.fontSize),
    lineHeight: recursivelyReplaceRems(defaultTheme.lineHeight),
    maxWidth: ({ theme, breakpoints }) =>
      recursivelyReplaceRems(defaultTheme.maxWidth({ theme, breakpoints })),
    extend: {
      zIndex: {
        highest: 2147483647,
      },
    },
  },
  plugins: [tailwindForms, tailwindTypography],
};
