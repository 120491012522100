import { FEATURE_KNOWLEDGE_BASE, useStateStore } from "@/store/state";

import logger from "@/core/logger";

import { useAppRouter } from "@/router";

const LOGGER_NAMESPACE = "KnowledgeBase";

export const useKnowledgeBase = () => {
  const stateStore = useStateStore();

  const { router } = useAppRouter();

  const activate = (path = "") => {
    if (stateStore.activeFeature === FEATURE_KNOWLEDGE_BASE) {
      return;
    }

    stateStore.setActiveFeature(FEATURE_KNOWLEDGE_BASE);

    stateStore.launcherVisible = false;
    stateStore.widgetVisible = true;

    router.push(path);

    logger.verbose("Activated.", null, LOGGER_NAMESPACE);
  };

  const deactivate = () => {
    if (stateStore.activeFeature !== FEATURE_KNOWLEDGE_BASE) {
      return;
    }

    stateStore.unsetActiveFeature();

    logger.verbose("Deactivated.", null, LOGGER_NAMESPACE);
  };

  return {
    activate,
    deactivate,
  };
};
