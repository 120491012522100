import type { LogLevels } from "@inline-help/shared";
import { LOG_LEVEL_SILENT } from "@inline-help/shared";

export const httpApiUrl = import.meta.env.CLIENT_HTTP_API_URL;

export const wsApiUrl = import.meta.env.CLIENT_WS_API_URL;

export const clientVersion = import.meta.env.CLIENT_VERSION;

export const defaultLogLevel: LogLevels = Number(
  import.meta.env.CLIENT_DEFAULT_LOG_LEVEL || LOG_LEVEL_SILENT,
) as LogLevels;

export const buildId =
  import.meta.env.MODE === "production" ? "" : import.meta.env.BUILD_ID;

export const mockHttpApi = import.meta.env.CLIENT_MOCK_HTTP_API === "true";
