import {
  FEATURE_CHATBOT,
  FEATURE_EXPLAIN_THIS,
  FEATURE_KNOWLEDGE_BASE,
  FEATURE_TICKET_FORM,
  useStateStore,
} from "@/store/state";
import { useChatbot } from "@/modules/chatbot";
import { useExplainThis, useTooltips } from "@/modules/hints";
import { useTicketForm } from "@/modules/ticket-form";
import { useKnowledgeBase } from "@/modules/knowledge-base";
import { useAppRouter } from "@/router";

import logger from "@/core/logger";

export const deactivateAllModules = () => {
  const chatbot = useChatbot();
  const tooltips = useTooltips();
  const explainThis = useExplainThis();
  const ticketForm = useTicketForm();

  chatbot.deactivate();
  tooltips.deactivate();
  explainThis.deactivate();
  ticketForm.deactivate();
};

export const activateRememberedModule = () => {
  const chatbot = useChatbot();
  const explainThis = useExplainThis();
  const knowledgeBase = useKnowledgeBase();
  const ticketForm = useTicketForm();

  const { router } = useAppRouter();

  const stateStore = useStateStore();

  const moduleName = stateStore.activeFeature;

  logger.verbose("Activating module after reload.", { moduleName }, "Modules");

  switch (moduleName) {
    case FEATURE_EXPLAIN_THIS: {
      explainThis.deactivate();
      break;
    }
    case FEATURE_CHATBOT: {
      chatbot.activate();
      break;
    }
    case FEATURE_KNOWLEDGE_BASE: {
      knowledgeBase.activate(router.options.history.location);
      break;
    }
    case FEATURE_TICKET_FORM: {
      ticketForm.activate();
      break;
    }
  }
};
