import { getStorageItemName as sharedGetStorageItemName } from "@inline-help/shared";

import { buildId } from "@/core/environment";
import { useConfigStore } from "@/store/config";
import { hashCode } from "@/util/string";

export const resetLocalStorage = () => {
  const keys = Object.keys(localStorage);

  for (const key of keys) {
    const shouldBeRemoved = new RegExp(`ih_.+?${buildId}`, "i").test(key);

    if (shouldBeRemoved) {
      localStorage.removeItem(key);
    }
  }
};

export const getStorageItemName = (name: string) => {
  const configStore = useConfigStore();

  const clientApiKeyHash = hashCode(configStore.clientApiKey as string);

  return sharedGetStorageItemName(name, clientApiKeyHash);
};
