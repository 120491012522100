import { createGettext } from "vue3-gettext";

import translations from "./locales/translations.json";

// Note: do not forget to update also the locales in client/gettext.config.ts when changing the available languages
export const availableLanguages = {
  en_US: "English",
  fr_FR: "French",
  de_DE: "German",
  it_IT: "Italian",
  ja_JP: "Japanese",
  pt_BR: "Portuguese",
  es_ES: "Spanish",
};

export type AvailableLanguages = keyof typeof availableLanguages;

export const defaultLanguage: AvailableLanguages = "en_US";

let gettext: ReturnType<typeof createGettext>;

export const useGettext = (language: AvailableLanguages = defaultLanguage) => {
  if (gettext) {
    return gettext;
  }

  gettext = createGettext({
    availableLanguages,
    defaultLanguage: language,
    translations,
    setGlobalProperties: true,
  });

  return gettext;
};
