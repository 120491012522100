<template>
  <DefaultHomeSectionListItem
    :navigate="() => explainThis.activate()"
    :section="section"
  >
    <template #image>
      <CursorArrowRaysIcon
        class="text-[color:var(--ih-primary-color)] h-8 w-8"
      />
    </template>
  </DefaultHomeSectionListItem>
</template>

<script setup lang="ts">
  import { CursorArrowRaysIcon } from "@heroicons/vue/24/outline";

  import type { Section } from "@/store/config";

  import { useExplainThis } from "@/modules/hints";

  import DefaultHomeSectionListItem from "@/components/DefaultHomeSectionListItem.global.vue";

  defineOptions({ inheritAttrs: false });

  type Props = {
    section: Section;
  };

  defineProps<Props>();

  const explainThis = useExplainThis();
</script>
