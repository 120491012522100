import { computed, readonly, ref } from "vue";
import { defineStore } from "pinia";
import { StorageSerializers, useStorage } from "@vueuse/core";

import type { FetchPageResourcesContext } from "@/lib/http";
import { useHttpClientApi } from "@/lib/http";

import { getStorageItemName } from "@/lib/storage";

export type KnowledgeBasePageResource = {
  id: string;
  title: string;
};

export type KnowledgeBaseArticle = {
  id: string;
  sourceUrl: string;
  title: string;
  content: string;
  relatedArticles: {
    id: string;
    title: string;
  }[];
};

export type KnowledgeBaseCategory = {
  id: string;
  title: string;
  articlesCount: number;
};

export const KNOWLEDGE_BASE_CONTENTS_CATEGORY = "category";
export const KNOWLEDGE_BASE_CONTENTS_FOLDER = "folder";
export const KNOWLEDGE_BASE_CONTENTS_ARTICLE = "article";

export type KnowledgeBaseContentsCategoryOrFolder =
  | typeof KNOWLEDGE_BASE_CONTENTS_CATEGORY
  | typeof KNOWLEDGE_BASE_CONTENTS_FOLDER;

export type KnowledgeBaseContents = {
  id: string;
  title: string;
  type:
    | KnowledgeBaseContentsCategoryOrFolder
    | typeof KNOWLEDGE_BASE_CONTENTS_ARTICLE;
  articlesCount?: number;
};

export type KnowledgeBaseContentsMapValue = KnowledgeBaseContents & {
  parent: null | string;
};

export type KnowledgeBaseContentsMap = {
  [id: string]: KnowledgeBaseContentsMapValue;
};

export const useKnowledgeBaseStore = defineStore("knowledge-base", () => {
  const httpClientApi = useHttpClientApi();

  const pageResources = ref<KnowledgeBasePageResource[]>([]);

  const categories = useStorage<KnowledgeBaseCategory[]>(
    getStorageItemName("ih_categories"),
    [],
    localStorage,
    { serializer: StorageSerializers.object },
  );

  const filteredCategories = computed(() => {
    if (!categories.value.length) {
      return;
    }

    return categories.value.filter(category => category.articlesCount > 0);
  });

  const contents = ref<KnowledgeBaseContents[] | undefined>();

  const filteredContents = computed(() => {
    if (!contents.value) {
      return;
    }

    return contents.value.filter(
      content =>
        content.articlesCount === undefined || content?.articlesCount > 0,
    );
  });

  const contentsMap = useStorage<KnowledgeBaseContentsMap>(
    getStorageItemName("ih_contents_map"),
    {},
    localStorage,
  );

  const article = ref<KnowledgeBaseArticle>();

  const searchResults = ref<KnowledgeBaseArticle[]>([]);

  const $reset = () => {
    pageResources.value = [];

    categories.value = [];

    contents.value = undefined;

    contentsMap.value = {};

    article.value = undefined;

    searchResults.value = [];
  };

  const fetchPageResources = async (context: FetchPageResourcesContext) => {
    pageResources.value = await httpClientApi.fetchPageResources(context);
  };

  const fetchArticle = async (id: string) => {
    article.value = undefined;

    article.value = await httpClientApi.fetchArticle(id);

    contentsMap.value[article.value.id] = {
      id: article.value.id,
      title: contentsMap.value[article.value.id]?.title || article.value.title,
      type: KNOWLEDGE_BASE_CONTENTS_ARTICLE,
      parent: contentsMap.value[article.value.id]?.parent || null,
    };
  };

  const fetchCategories = async () => {
    categories.value = [];

    categories.value = await httpClientApi.fetchCategories();

    for (const category of categories.value) {
      contentsMap.value[category.id] = {
        id: category.id,
        title: category.title,
        type: KNOWLEDGE_BASE_CONTENTS_CATEGORY,
        parent: null,
      };
    }
  };

  const fetchContents = async (
    id: string,
    type: KnowledgeBaseContentsCategoryOrFolder,
  ) => {
    contents.value = undefined;

    contents.value = await httpClientApi.fetchContents(id, type);

    for (const content of contents.value) {
      contentsMap.value[content.id] = {
        id: content.id,
        title: content.title,
        type: content.type,
        parent: id,
      };
    }
  };

  const searchArticle = async (query: string) => {
    searchResults.value = await httpClientApi.searchArticle(query);
  };

  const clearSearchResults = () => {
    searchResults.value = [];
  };

  return {
    $reset,
    pageResources: readonly(pageResources),
    filteredCategories: readonly(filteredCategories),
    article: readonly(article),
    contents: readonly(contents),
    filteredContents: readonly(filteredContents),
    contentsMap: readonly(contentsMap),
    searchResults: readonly(searchResults),
    fetchPageResources,
    fetchArticle,
    fetchCategories,
    fetchContents,
    searchArticle,
    clearSearchResults,
  };
});
