import type { JSONValue } from "@/types";

import type { Message } from "@/store/conversation";

import type { Rating } from "@/components/interfaces";

export const HINT_TYPE_EXPLAIN_THIS = "explain_this";
export const HINT_TYPE_TOOLTIP = "tooltip";

export type HintType = typeof HINT_TYPE_EXPLAIN_THIS | typeof HINT_TYPE_TOOLTIP;

export type HintMetadata = {
  [key: string]: JSONValue;
};

export type Hint = {
  id: string;
  createdAt: string;
  updatedAt: string;
  content: string;
  sources?: Message["metadata"]["sources"];
  rating?: Rating;
  ratingDisabled?: boolean;
};
