import type { Ref } from "vue";

import {
  createAppWithDelayedUnmount,
  mountWithDelayedUnmount,
} from "@/core/web-component";

import type { JSONObject } from "@/types";

export const render = (
  target: HTMLElement,
  props?: Ref<JSONObject> | JSONObject,
  cb?: (element: HTMLElement) => () => void,
) => {
  const component = createAppWithDelayedUnmount(
    "inline-help-tooltip",
    "TooltipLauncher",
    props,
  );

  const delayedUnmount = mountWithDelayedUnmount({ ...component, target, cb });

  return (immediate = false) => {
    delayedUnmount(immediate);
  };
};
