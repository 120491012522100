<template>
  <div
    :class="{ 'justify-end ml-auto': !isMessageFromBot(message) }"
    class="flex w-full space-x-3"
  >
    <div class="relative h-10 w-10" v-if="isMessageFromBot(message)">
      <div
        v-if="isBotIconDefault"
        v-html="botIcon"
        class="bg-[color:var(--ih-secondary-color)] text-[color:var(--ih-primary-color)] shrink-0 h-10 w-10 rounded-full"
      ></div>

      <img
        v-else
        :src="botIcon"
        class="shrink-0 h-10 w-10 rounded-full"
        alt=""
      />

      <div
        class="absolute text-xs font-medium text-gray-600 bg-gray-300 px-1 -bottom-1 -right-1 rounded"
        v-if="configStore.config.chatbot.aiGeneratedBadgeEnabled"
      >
        {{
          $pgettext(
            "Chatbot icon text informing the User that Chat Message has been generated by the AI.",
            "AI",
          )
        }}
      </div>
    </div>

    <div class="w-full">
      <div
        class="p-3"
        :class="{
          'bg-[color:var(--ih-primary-color)] text-white rounded-l-lg rounded-br-lg':
            !isMessageFromBot(message),
          'bg-[color:var(--ih-secondary-color)] rounded-r-lg rounded-bl-lg':
            isMessageFromBot(message),
        }"
      >
        <div
          data-chat-message
          :data-chat-message-bot="isMessageFromBot(message) ? true : null"
        >
          <Component :is="messageBody" />
        </div>
      </div>

      <div v-if="hasEmbeds && isFinished" class="empty:hidden flex justify-end">
        <button
          v-for="embed in embeds"
          :key="embed.type"
          @click="activateEmbed(embed as MessageMetadataEmbed)"
          class="mt-2 inline-flex justify-center rounded-md shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus-visible:outline-[color:var(--ih-primary-color)] sm:text-sm border hover:bg-[color:var(--ih-primary-color)] hover:text-white"
        >
          {{ embed.label }}
        </button>
      </div>

      <div
        v-if="isFinished"
        class="flex mt-2 items-center justify-between"
        :key="`mt-${message.id}-${tick}`"
      >
        <div
          class="text-xs text-gray-500 leading-none"
          :title="formatTime(message.createdAt)"
        >
          {{ formatRelativeTime(message.createdAt) }}
        </div>

        <SourcesDropdown
          v-if="
            isMessageFromBot(message) &&
            sources?.length &&
            configStore.config.chatbot.sourcesEnabled
          "
          :sources="sources"
        />

        <RatingThumbs
          v-if="isMessageFromBot(message) && !message.ratingDisabled"
          :rating="message?.rating"
          @rate-positive="ratePositive()"
          @rate-negative="rateNegative()"
        />
      </div>
    </div>

    <div
      v-if="!isMessageFromBot(message)"
      class="shrink-0 h-10 w-10 rounded-full bg-black bg-opacity-30 font-medium text-white flex items-center justify-center"
    >
      {{ userInitials }}
    </div>
  </div>
</template>

<script setup lang="ts">
  import {
    computed,
    type DeepReadonly,
    type DefineComponent,
    nextTick,
    onBeforeUnmount,
    onMounted,
    ref,
    shallowRef,
    watch,
  } from "vue";
  import relativeTime from "dayjs/esm/plugin/relativeTime";
  import { storeToRefs } from "pinia";

  import type { Message, MessageMetadataEmbed } from "@/store/conversation";
  import {
    MESSAGE_ACTION_STREAMING,
    MESSAGE_RATING_NEGATIVE,
    MESSAGE_RATING_POSITIVE,
    MESSAGE_SENT_BY_BOT,
    useConversationStore,
  } from "@/store/conversation";

  import { useConfigStore } from "@/store/config";

  import { useUserStore } from "@/store/user";

  import { replacePlaceholders } from "@/core/content-resolvers";

  import { activateEmbed } from "@/modules/chatbot/embeds";

  import { useTranslations } from "@/translations/translations";

  import defaultBotIcon from "@/assets/images/chatbot-icon.svg?raw";

  import SourcesDropdown from "@/components/SourcesDropdown.vue";
  import RatingThumbs from "@/components/RatingThumbs.vue";

  type Props = {
    message: DeepReadonly<Message>;
  };

  const props = defineProps<Props>();

  const emit = defineEmits<{
    updated: [];
  }>();

  const { dayjs } = useTranslations();

  dayjs.extend(relativeTime);

  const sources = computed(() => props.message.metadata.sources);

  const configStore = useConfigStore();

  const conversationStore = useConversationStore();

  const userStore = useUserStore();
  const { user } = storeToRefs(userStore);

  const isBotIconDefault = !configStore.config.chatbot.icon;
  const botIcon = configStore.config.chatbot.icon || defaultBotIcon;

  const tick = ref(0);

  let ticker: ReturnType<typeof setInterval>;

  onMounted(() => {
    ticker = setInterval(() => {
      tick.value++;
    }, 60_000);
  });

  onBeforeUnmount(() => {
    clearInterval(ticker);
  });

  const isMessageFromBot = (message: DeepReadonly<Message>) =>
    message.sentBy === MESSAGE_SENT_BY_BOT;

  const formatTime = (date: string) => dayjs(date).toString();

  const formatRelativeTime = (date: string) => dayjs(date).fromNow();

  const messageBody = shallowRef<DefineComponent>();

  let contentBuffer = "";
  let renderedContent = "";
  let counter = 0;
  let repeater: ReturnType<typeof setInterval> | undefined;

  let isFinished = ref(false);

  const startRendering = () => {
    isFinished.value = false;

    repeater = setInterval(() => {
      const char = contentBuffer[counter];

      if (!char) {
        clearInterval(repeater);

        repeater = undefined;

        isFinished.value = true;

        emit("updated");

        return;
      }

      counter++;

      renderedContent += char;

      messageBody.value = replacePlaceholders(renderedContent);

      emit("updated");
    }, 15);
  };

  const stopWatcher = watch(
    props.message,
    ({ body, action }) => {
      nextTick(() => {
        if (action !== MESSAGE_ACTION_STREAMING) {
          stopWatcher();

          messageBody.value = replacePlaceholders(body);

          emit("updated");

          isFinished.value = true;

          return;
        }

        if (body) {
          contentBuffer = body;

          if (!repeater) {
            startRendering();
          }
        }
      });
    },
    { immediate: true },
  );

  const ratePositive = () => {
    conversationStore.rateMessage(props.message, MESSAGE_RATING_POSITIVE);
  };

  const rateNegative = () => {
    conversationStore.rateMessage(props.message, MESSAGE_RATING_NEGATIVE);
  };

  const userInitials = computed(() => {
    const name = user.value?.traits?.name as string;

    if (!name) {
      return "IH";
    }

    return name
      .split(" ")
      .map(part => part[0])
      .join("");
  });

  const embeds = computed(() => props.message.metadata.embeds);

  const hasEmbeds = computed(() => embeds.value?.length);
</script>
