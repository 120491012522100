<template>
  <TheLauncher
    :visible="stateStore.launcherVisible"
    @click="stateStore.toggleLauncherWidgetVisibility()"
  />

  <TheWidget
    :visible="stateStore.widgetVisible"
    @close="stateStore.toggleLauncherWidgetVisibility()"
  />

  <TheExplainThisPopup v-if="stateStore.explainThisTooltip.visible" />

  <TheTooltipPopup v-if="stateStore.tooltip.visible" />

  <div ref="hostTeleportTargetRef"></div>
</template>

<script setup lang="ts">
  import { onMounted, provide, ref, watch } from "vue";
  import { useStyleTag, watchOnce } from "@vueuse/core";

  import { useStateStore } from "@/store/state";

  import { useConfigStore } from "@/store/config";

  import { useAppRouter } from "@/router";

  import { activateRememberedModule } from "@/modules";

  import TheLauncher from "@/components/the-launcher/TheLauncher.vue";
  import TheWidget from "@/components/TheWidget.vue";
  import TheExplainThisPopup from "@/modules/hints/explain-this/TheExplainThisPopup.vue";
  import TheTooltipPopup from "@/modules/hints/tooltips/TheTooltipPopup.vue";

  type Props = {
    unmountRequest: boolean;
    unmountReady: () => void;
  };

  defineOptions({ inheritAttrs: false });

  const props = defineProps<Props>();

  const { router } = useAppRouter();

  const stateStore = useStateStore();

  const configStore = useConfigStore();

  const hostTeleportTargetRef = ref<HTMLElement>();

  provide("hostTeleportTargetRef", hostTeleportTargetRef);

  onMounted(() => {
    activateRememberedModule();
  });

  watchOnce(router.currentRoute, route => {
    stateStore.resolveLauncherWidgetVisibilityByRoute(route.path);
  });

  watch(
    () => props.unmountRequest,
    state => {
      if (state) {
        props.unmountReady();
      }
    },
  );

  useStyleTag(
    `
    :root {
      --ih-primary-color: ${configStore.config.widget.style.primaryColor};
      --ih-secondary-color: ${configStore.config.widget.style.secondaryColor};
      --ih-launcher-space-side: ${configStore.config.widget.style.launcher.space?.side}px;
      --ih-launcher-space-bottom: ${configStore.config.widget.style.launcher.space?.bottom}px;
      --ih-font-family: ${configStore.config.widget.style.font};
    }
  `,
  );
</script>

<style lang="scss">
  :host {
    & > * {
      font-family: var(--ih-font-family);
    }
  }
</style>
