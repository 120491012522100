import { defineStore } from "pinia";
import { reactive, readonly, ref } from "vue";
import { StorageSerializers, useStorage } from "@vueuse/core";

import type { Hint, HintMetadata } from "@/modules/hints";
import type { DeepPartial } from "@/types";

import { getStorageItemName } from "@/lib/storage";

import type { PopupConfig } from "@/store/config";

export const FEATURE_EXPLAIN_THIS = "explain_this";
export const FEATURE_CHATBOT = "chatbot";
export const FEATURE_TICKET_FORM = "ticket_form";
export const FEATURE_KNOWLEDGE_BASE = "knowledge_base";

export type Features =
  | typeof FEATURE_EXPLAIN_THIS
  | typeof FEATURE_CHATBOT
  | typeof FEATURE_TICKET_FORM
  | typeof FEATURE_KNOWLEDGE_BASE;

export type HintWithContext = Hint & {
  body: string;
  metadata: HintMetadata;
};

export const useStateStore = defineStore("state", () => {
  const widgetVisible = ref(false);
  const launcherVisible = ref(true);

  const activeFeature = useStorage(
    getStorageItemName("ih_active_feature"),
    null,
    localStorage,
    {
      serializer: StorageSerializers.string,
    },
  );

  const explainThisTooltip = reactive<{
    visible: boolean;
    element?: Readonly<HTMLElement>;
    metadata: DeepPartial<HintMetadata>;
  }>({
    visible: false,
    element: undefined,
    metadata: {},
  });

  const tooltip = reactive<{
    visible: boolean;
    element?: Readonly<HTMLElement>;
    config?: PopupConfig;
    text?: string;
    metadata: DeepPartial<HintMetadata>;
  }>({
    visible: false,
    element: undefined,
    config: undefined,
    text: undefined,
    metadata: {},
  });

  const tooltipCloseTimer = ref<ReturnType<typeof setTimeout>>();

  const explainThisToastVisible = ref(false);

  const hintWithContext = ref<HintWithContext>();

  const $reset = () => {
    widgetVisible.value = false;

    launcherVisible.value = true;

    explainThisToastVisible.value = false;

    hintWithContext.value = undefined;

    hideExplainThisTooltip();

    unsetActiveFeature();
  };

  const resolveLauncherWidgetVisibilityByRoute = (route: string) => {
    launcherVisible.value = route === "/";
    widgetVisible.value = route !== "/";
  };

  const toggleLauncherWidgetVisibility = () => {
    launcherVisible.value = !launcherVisible.value;
    widgetVisible.value = !widgetVisible.value;
  };

  const setActiveFeature = (feature: Features) => {
    activeFeature.value = feature;
  };

  const unsetActiveFeature = () => (activeFeature.value = undefined);

  const displayExplainThisTooltip = (
    element: HTMLElement,
    metadata: HintMetadata,
  ) => {
    explainThisTooltip.visible = true;
    explainThisTooltip.element = element;
    explainThisTooltip.metadata = metadata;
  };

  const hideExplainThisTooltip = () => {
    explainThisTooltip.visible = false;
    explainThisTooltip.element = undefined;
    explainThisTooltip.metadata = {};
  };

  const displayTooltip = (
    element: HTMLElement,
    config: PopupConfig,
    text: string,
    metadata: HintMetadata,
  ) => {
    tooltip.visible = true;
    tooltip.element = element;
    tooltip.config = config;
    tooltip.text = text;
    tooltip.metadata = metadata;
  };

  const hideTooltip = () => {
    tooltip.visible = false;
    tooltip.element = undefined;
    tooltip.config = undefined;
    tooltip.text = undefined;
    tooltip.metadata = {};
  };

  return {
    $reset,
    launcherVisible,
    widgetVisible,
    activeFeature,
    explainThisTooltip: readonly(explainThisTooltip),
    tooltip: readonly(tooltip),
    tooltipCloseTimer,
    explainThisToastVisible,
    hintWithContext,
    resolveLauncherWidgetVisibilityByRoute,
    toggleLauncherWidgetVisibility,
    setActiveFeature,
    unsetActiveFeature,
    displayExplainThisTooltip,
    hideExplainThisTooltip,
    displayTooltip,
    hideTooltip,
  };
});
