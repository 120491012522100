<template>
  <div
    :style="`left: ${x}px; top: ${y}px; max-width: calc(100vw - ${
      padding * 2
    }px); position: ${floatingStyles.position};`"
    class="z-highest bg-white border border-gray-100 drop-shadow-lg rounded-xl w-96"
    ref="popupContentRef"
  >
    <div
      v-if="draggable"
      ref="dragRef"
      class="cursor-move w-full flex items-center justify-center hover:bg-gray-100 rounded-t-xl"
    >
      <Bars3Icon class="h-7 w-7 py-2 pointer-events-none" />
    </div>

    <slot name="header" />

    <div class="flex justify-end p-2">
      <button
        class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
        @click.prevent.stop="close()"
      >
        <span class="sr-only">{{
          $pgettext(
            "Screen reader text representing the close cross of the Popup.",
            "Close",
          )
        }}</span>

        <XMarkIcon class="h-5 w-5"></XMarkIcon>
      </button>
    </div>

    <div
      class="overflow-y-auto"
      :style="`max-height: calc(100vh - ${padding * 2 + 40 + (config.poweredBy ? 40 : 0)}px);`"
    >
      <slot name="default">
        <div class="p-5 w-full"></div>
      </slot>
    </div>

    <div
      v-if="config.poweredBy"
      class="w-full bg-white text-gray-500 border-t border-gray-300 text-xs rounded-b-xl"
      key="footer"
    >
      <a
        href="https://inlinehelp.com?ref=widget"
        target="_blank"
        class="flex items-center justify-center h-10 -ml-3"
      >
        <span class="inline-block pt-[2px]">⚡️</span>

        <span>{{
          $pgettext("App Popup footer brand text.", "Powered by Inline Help")
        }}</span>
      </a>
    </div>

    <div
      v-show="showArrowComputed"
      class="absolute before:absolute before:drop-shadow-sm"
      :class="[
        (staticSide === 'left' || staticSide === 'right') &&
          'top-1/2 -translate-y-1/2 before:-translate-y-1/2 before:border-y-8 before:border-y-transparent',
        (staticSide === 'top' || staticSide === 'bottom') &&
          'left-1/2 -translate-x-1/2 before:-translate-x-1/2 before:border-x-8 before:border-x-transparent',
        staticSide === 'left' &&
          'before:border-r-8 before:border-r-white -left-2',
        staticSide === 'right' &&
          'before:border-l-8 before:border-l-white right-0',
        staticSide === 'top' &&
          'before:border-b-8 before:border-b-white -top-2 before:!drop-shadow-[0_-10px_10px_rgba(0,0,0,.3)]',
        staticSide === 'bottom' &&
          'before:border-t-8 before:border-t-white bottom-0',
      ]"
      :style="{
        left: arrowPos.x != undefined ? `${arrowPos.x}px` : '',
        top: arrowPos.y != undefined ? `${arrowPos.y}px` : '',
      }"
      ref="arrowRef"
    ></div>
  </div>
</template>

<script setup lang="ts">
  import type { DeepReadonly } from "vue";
  import { computed, reactive, ref, watch } from "vue";
  import {
    arrow,
    autoUpdate,
    type Coords,
    flip,
    offset,
    shift,
    useFloating,
  } from "@floating-ui/vue";
  import { XMarkIcon } from "@heroicons/vue/24/solid";
  import { Bars3Icon } from "@heroicons/vue/20/solid";

  import { draggable as draggableMiddleware } from "@/lib/floating-draggable-middleware";

  import type { PopupConfig } from "@/store/config";

  type Props = {
    hoverTriggerRef?: DeepReadonly<HTMLElement>;
    config: AppPopupConfig;
    showArrow?: boolean;
    draggable?: boolean;
  };

  export type AppPopupConfig = PopupConfig & {
    poweredBy: boolean;
  };

  const props = withDefaults(defineProps<Props>(), {
    showArrow: true,
    draggable: false,
  });

  const emit = defineEmits<{
    close: [];
    positionUpdated: [x: number, y: number, placement: string];
  }>();

  defineSlots<{
    header(props: {}): any;
    default(props: {}): any;
  }>();

  const popupContentRef = ref();
  const arrowRef = ref();
  const dragRef = ref();

  const dragged = ref(false);

  const arrowCenterOffset = ref(0);

  const padding = 20;

  const showArrowComputed = computed(
    () =>
      props.showArrow &&
      props.hoverTriggerRef &&
      !dragged.value &&
      arrowCenterOffset.value === 0,
  );

  const screenCenterVirtualElement = computed(() => {
    return {
      getBoundingClientRect() {
        const popupContentRect = popupContentRef.value.getBoundingClientRect();

        const left = document.documentElement.clientWidth / 2;

        const top =
          document.documentElement.clientHeight / 2 +
          popupContentRect.height / 2;

        return {
          left,
          top,
          x: left,
          y: top,
          height: 1,
          width: 1,
          right: left,
          bottom: top,
        };
      },
    };
  });

  const computedHoverTriggerRef = computed(
    () => props.hoverTriggerRef || screenCenterVirtualElement.value,
  );

  watch(computedHoverTriggerRef, () => {
    dragged.value = false;
  });

  const computedPlacement = computed(() =>
    props.hoverTriggerRef ? props.config.position : "top",
  );

  const floatingMiddlewares = [
    offset({
      mainAxis: props.config.space.mainAxis,
      crossAxis: props.config.space.crossAxis,
    }),
    shift({
      padding,
    }),
    flip({ fallbackPlacements: ["right", "left", "top", "bottom"] }),
    arrow({ element: arrowRef }),
  ];

  if (props.draggable) {
    floatingMiddlewares.push(
      draggableMiddleware({
        element: dragRef,
        dragStart({ elements }) {
          dragged.value = true;

          Object.assign(elements.floating.style, {
            opacity: "0.5",
          });
        },
        drag({ elements, x, y, placement }) {
          Object.assign(elements.floating.style, {
            left: `${x}px`,
            top: `${y}px`,
          });

          emit("positionUpdated", x, y, placement);
        },
        dragEnd({ elements }) {
          Object.assign(elements.floating.style, {
            opacity: "1",
          });
        },
      }),
    );
  }

  const { middlewareData, placement, x, y, isPositioned, floatingStyles } =
    useFloating(computedHoverTriggerRef, popupContentRef, {
      strategy: "fixed",
      placement: computedPlacement,
      transform: false,
      whileElementsMounted: autoUpdate,
      middleware: floatingMiddlewares,
    });

  watch(
    [x, y, placement, isPositioned],
    ([x, y, placement, isPositioned]) => {
      if (!isPositioned) {
        return;
      }

      emit("positionUpdated", x, y, placement);
    },
    { immediate: true },
  );

  const arrowPos = reactive<{
    x?: number;
    y?: number;
  }>({
    x: undefined,
    y: undefined,
  });

  const staticSide = computed(
    () =>
      ({
        top: "bottom",
        right: "left",
        bottom: "top",
        left: "right",
      })[placement.value.split("-")[0]] as string,
  );

  const close = () => emit("close");

  const calculateRelativeArrowPositionWithingConstraints = (
    arrow?: Partial<Coords> & { centerOffset: number },
  ) => {
    const popupContentRect = popupContentRef.value.getBoundingClientRect();

    if (arrowCenterOffset.value === 0) {
      if (arrow?.y !== undefined) {
        if (arrow.y < 20) {
          arrowPos.y = 20;
        }

        if (arrow.y > popupContentRect.height - 20) {
          arrowPos.y = popupContentRect.height - 20;
        }
      }

      if (arrow?.x !== undefined) {
        if (arrow.x < 20) {
          arrowPos.x = 20;
        }

        if (arrow.x > popupContentRect.width - 20) {
          arrowPos.x = popupContentRect.width - 20;
        }
      }
    }
  };

  watch(middlewareData, ({ arrow }) => {
    arrowPos.x = arrow?.x;
    arrowPos.y = arrow?.y;

    arrowCenterOffset.value = arrow?.centerOffset ?? 0;

    if (arrowCenterOffset.value === 0) {
      calculateRelativeArrowPositionWithingConstraints(arrow);
    }
  });
</script>
