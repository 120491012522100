import { isArray } from "lodash-es";

import type { PreLoadAPI } from "@/types.d";

import { install } from "@/lib/bootstrap";

if (!window.InlineHelp || !isArray(window.InlineHelpPreLoadQueue)) {
  throw new Error(
    "Looks like Client was not properly initialized. Use Snippet instead of direct <script> tag usage.",
  );
}

/* c8 ignore start */
const preLoadQueue = window.InlineHelpPreLoadQueue;
const options = (window.InlineHelp as unknown as PreLoadAPI)._options || {};
/* c8 ignore end */

if (window.InlineHelp.initialized) {
  throw new Error(
    "Duplicate Client initialization prevented. Looks like you have loaded Client directly via <script> tag.",
  );
}

if (!options.clientApiKey) {
  throw new Error(
    'Required "clientApiKey" is missing. Have you provided it via the Snippet?',
  );
}

if (!options.snippetVersion) {
  throw new Error(
    'Required "snippetVersion" is missing. Have you initialized the Client via the Snippet?',
  );
}

install(preLoadQueue, options);
