/**
 * Returns a hash code from a string
 * @see http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 */
export const hashCode = (input: string) => {
  let hash = 0;

  for (let i = 0, len = input.length; i < len; i++) {
    const char = input.charCodeAt(i);

    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32bit integer
  }

  return hash.toString(16);
};
