import { createConsumer } from "@rails/actioncable";

type WebSocketApiOptions = {
  endpoint: string;
  clientApiKey: string;
};

let options: WebSocketApiOptions | undefined;

export const useWSClientApi = (opts?: WebSocketApiOptions) => {
  if (opts && options) {
    throw new Error("Client WebSocket API has already been initialized");
  }

  if (opts) {
    options = structuredClone(opts);
  }

  const consumer = createConsumer(
    `${options?.endpoint}/cable?client_api_key=${options?.clientApiKey}`,
  );

  const $reset = () => {
    consumer.disconnect();

    options = undefined;
  };

  return { consumer, $reset };
};
