import { defineStore } from "pinia";
import { readonly, ref } from "vue";

import type { JSONObject } from "@/types";

import { generateUUID } from "@/util/uuid";

export type Traits = JSONObject;

export type User = {
  id: string;
  userId: string;
  traits?: Traits;
};

export const useUserStore = defineStore("user", () => {
  const user = ref<User>();

  const $reset = () => {
    user.value = undefined;
  };

  const identify = (userId: string, traits?: Traits) => {
    user.value = {
      id: generateUUID(),
      userId,
      traits,
    };
  };

  return {
    $reset,
    user: readonly(user),
    identify,
  };
});
