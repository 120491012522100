<template>
  <Transition
    appear
    enter-active-class="ease-in duration-300"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="ease-in duration-100"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <AppPopup
      v-if="stateStore.tooltip.visible"
      ref="popupRef"
      class="transition-opacity"
      :hover-trigger-ref="stateStore.tooltip.element"
      :config="popupConfig"
      @mouseover.prevent.stop="onMouseOver()"
      @mouseout.prevent.stop="onMouseOut()"
      @click.prevent.stop
      @close="close()"
    >
      <AppLoader
        :loading="!hintBody"
        :retry-cb="retryHintFetch"
        classes="pb-5"
        :sync="false"
        :text="
          $pgettext(
            'Loading text displayed when Tooltip Popup content is being fetched.',
            'Thinking',
          )
        "
        @state-changed="state => (loading = state)"
      >
        <div class="p-5 pt-0 text-sm" v-if="hintBody">
          <div data-tooltip-popup-content>
            <Component :is="hintBody" />
          </div>

          <button
            v-if="displayEmbed && hintWithContext"
            class="mt-6 p-2 w-full bg-white gap-x-2 flex items-center rounded-lg outline outline-1 -outline-offset-1 outline-gray-300 drop-shadow hover:bg-gray-50 cursor-pointer"
            @click="transferFunds()"
          >
            <span
              class="flex shrink-0 h-8 w-8 items-center justify-center rounded-full bg-[color:var(--ih-secondary-color)]"
            >
              <EyeIcon class="text-[color:var(--ih-primary-color)] size-6" />
            </span>

            <span
              class="text-sm font-semibold text-left leading-6 text-gray-900 grow"
            >
              Transfer funds
            </span>

            <ChevronRightIcon class="w-6 h-6 text-gray-500 shrink-0" />
          </button>

          <div
            class="text-xs font-medium text-gray-600 mt-6"
            v-if="configStore.config.tooltips.aiGeneratedBadgeEnabled"
          >
            <span
              class="inline-flex items-center bg-gray-300 px-1 mr-2 rounded cursor-help"
              :title="
                $pgettext(
                  'Tooltip Popup title of an AI badge informing the User that response has been generated by the AI.',
                  'AI generated',
                )
              "
            >
              {{
                $pgettext(
                  "The Tooltip Popup AI badge informing the User that content has been generated by the AI.",
                  "AI",
                )
              }}
            </span>
          </div>

          <SourcesInline
            v-if="
              hintWithContext &&
              configStore.config.tooltips.sourcesEnabled &&
              hintWithContext.sources?.length
            "
            :sources="hintWithContext.sources"
            @click="close()"
            class="mt-6"
          />

          <div class="flex items-center gap-3 mt-6" v-if="hintWithContext">
            {{
              $pgettext(
                "Tooltip Popup Rating thumbs heading.",
                "Was this helpful?",
              )
            }}

            <HintRatingThumbs
              v-if="
                !hintWithContext.ratingDisabled &&
                configStore.config.tooltips.messageRatingEnabled
              "
              :hint="hintWithContext"
            />
          </div>

          <div
            class="flex flex-col items-center gap-3 mt-6"
            v-if="hintWithContext"
          >
            <HintAskQuestionButton
              v-if="configStore.config.tooltips.askAboutThisEnabled"
              :hint-with-context="hintWithContext"
              @click="close()"
            />
          </div>
        </div>
      </AppLoader>
    </AppPopup>
  </Transition>

  <pre
    key="debug"
    v-if="logLevel === LOG_LEVEL_SILLY"
    @click.prevent.stop
    class="text-[10px] text-gray-400 mt-2 whitespace-pre bg-white p-2 min-w-max hover:z-highest relative"
  ><code>{{ { hintWithContext, popupConfig } }}</code></pre>
</template>

<script setup lang="ts">
  import {
    computed,
    type DefineComponent,
    onMounted,
    onUnmounted,
    ref,
    shallowRef,
  } from "vue";
  import { onClickOutside } from "@vueuse/core";
  import { EyeIcon } from "@heroicons/vue/24/solid";

  import { HINT_TYPE_TOOLTIP } from "@/modules/hints";

  import logger, { LOG_LEVEL_SILLY } from "@/core/logger";

  import { useHttpClientApi } from "@/lib/http";

  import type { HintWithContext } from "@/store/state";
  import { useStateStore } from "@/store/state";

  import { type PopupConfig, useConfigStore } from "@/store/config";

  import HintRatingThumbs from "@/modules/hints/HintRatingThumbs.vue";
  import AppLoader from "@/components/AppLoader.vue";
  import HintAskQuestionButton from "@/modules/hints/HintAskQuestionButton.vue";
  import AppPopup, { type AppPopupConfig } from "@/components/AppPopup.vue";
  import SourcesInline from "@/components/SourcesInline.vue";

  import type { DeepWriteable } from "@/types";

  import { generateUUID } from "@/util/uuid";

  import { processHintStream } from "@/modules/hints/shared";
  import { ChevronRightIcon } from "@heroicons/vue/20/solid";
  import { activateEmbed } from "@/modules/chatbot/embeds";

  const httpClientApi = useHttpClientApi();

  const stateStore = useStateStore();

  const configStore = useConfigStore();

  const hintWithContext = ref<HintWithContext>();
  const hintBody = shallowRef<DefineComponent>();

  const loading = ref(false);

  const popupRef = ref();

  const { logLevel } = logger;

  const popupConfig = computed<AppPopupConfig>(() => ({
    ...(stateStore.tooltip.config as DeepWriteable<PopupConfig>),
    poweredBy: configStore.config.global.poweredBy,
  }));

  const displayEmbed = computed(() => {
    const isPoc = localStorage.getItem("poc") === "true";

    return isPoc && stateStore.tooltip.text === "Quick Transfer";
  });

  const transferFunds = () => {
    activateEmbed({
      type: "im_player_activate_topic",
      label: "Transfer funds",
      data: {
        topicId: 112841,
      },
    });

    close();
  };

  const fetchHint = async () => {
    try {
      const body = `Explain: ${stateStore.tooltip.text}`;

      const metadata = {
        selector: stateStore.tooltip.metadata.selector,
        html: stateStore.tooltip.metadata.html,
        title: document.title,
      };

      const hintId = generateUUID();

      const stream = await httpClientApi.fetchHintStream(
        hintId,
        HINT_TYPE_TOOLTIP,
        body,
        metadata,
      );

      processHintStream(stream, body, metadata, hintBody, hintWithContext);
    } catch (e: unknown) {
      if (e instanceof Error) {
        logger.error(e.message, null, "Tooltip");
      }
    }
  };

  const onMouseOver = () => {
    clearTimeout(stateStore.tooltipCloseTimer);
  };

  const onMouseOut = () => {
    clearTimeout(stateStore.tooltipCloseTimer);

    stateStore.tooltipCloseTimer = setTimeout(close, 1_000);
  };

  const close = () => {
    stateStore.hideTooltip();

    hintWithContext.value = undefined;
    hintBody.value = undefined;
  };

  const retryHintFetch = () => {
    fetchHint();
  };

  onMounted(() => {
    onClickOutside(popupRef, close, {
      ignore: [stateStore.tooltip.element as HTMLElement],
    });

    stateStore.widgetVisible = false;
    stateStore.launcherVisible = true;

    stateStore.hideExplainThisTooltip();

    fetchHint();
  });

  onUnmounted(() => {
    close();
  });
</script>
