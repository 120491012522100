import type { IhHTMLElement } from "./index";
import { IH_NODE_NAME } from "./index";

import { moveFloatingOutline, removeOutline } from "./outline";

import { isElementAllowed, resolveScopedElement } from "./scope";

export const handleClick = (
  event: MouseEvent,
  cb: (target: HTMLElement) => void,
  allowList: Readonly<string[]>,
  denyList: Readonly<string[]>,
) => {
  event.preventDefault();
  event.stopPropagation();
  event.stopImmediatePropagation();

  const element = event.target as unknown as HTMLElement;

  if (!element) {
    return;
  }

  if (element.nodeName === IH_NODE_NAME) {
    return;
  }

  if (!isElementAllowed(element, allowList, denyList)) {
    return;
  }

  const resolvedScopeElement = resolveScopedElement(element);

  removeOutline(resolvedScopeElement);

  cb(resolvedScopeElement);
};

let lastMouseOverElement: IhHTMLElement;

export const handleMouseOver = (
  event: MouseEvent,
  style: string,
  allowList: Readonly<string[]>,
  denyList: Readonly<string[]>,
  inCb?: (element: IhHTMLElement) => void,
  outCb?: (element: IhHTMLElement) => void,
) => {
  const element = event.target as unknown as IhHTMLElement;

  if (lastMouseOverElement && lastMouseOverElement !== element) {
    outCb?.(lastMouseOverElement);
  }

  inCb?.(element);

  lastMouseOverElement = element;

  if (!element) {
    return;
  }

  event.preventDefault();
  event.stopPropagation();
  event.stopImmediatePropagation();

  if (!isElementAllowed(element, allowList, denyList)) {
    return;
  }

  moveFloatingOutline(resolveScopedElement(element), style);
};
