import { generateUUID } from "@/util/uuid";
import { useMutationObserver } from "@vueuse/core";

export const useDomObserver = (selectors: string[], attributeName: string) => {
  const initializedAttributeName = `${attributeName}-initialized`;

  const observe = (
    onNodeAdded?: (element: Element, name: string) => void,
    onNodeRemoved?: (name: string) => void,
  ) => {
    const mutationObserverCallback = (mutationList: MutationRecord[]) => {
      let removedElements: Element[] = [];
      let addedElements: Element[] = [];

      for (const mutation of mutationList) {
        if (mutation.type !== "childList") {
          return;
        }

        mutation.removedNodes.forEach(removedNode => {
          if (removedNode.nodeType !== Node.ELEMENT_NODE) {
            return;
          }

          removedElements = Array.from(
            (removedNode as Element).querySelectorAll(selectors.join(",")),
          );

          if ((removedNode as Element).hasAttribute(initializedAttributeName)) {
            removedElements.push(removedNode as Element);
          }
        });

        mutation.addedNodes.forEach(addedNode => {
          if (addedNode.nodeType !== Node.ELEMENT_NODE) {
            return;
          }

          addedElements = Array.from(
            (addedNode as Element).querySelectorAll(selectors.join(",")),
          );

          if ((addedNode as Element).hasAttribute(attributeName)) {
            addedElements.push(addedNode as Element);
          }
        });
      }

      for (const addedElement of addedElements) {
        cleanupBeforeElementReinitialization(addedElement, removedElements);

        const name = generateUUID();

        addedElement.setAttribute(initializedAttributeName, name);

        onNodeAdded?.(addedElement, name);
      }

      for (const removedElement of removedElements) {
        onNodeRemoved?.(
          removedElement.getAttribute(initializedAttributeName) as string,
        );
      }
    };

    const observer = useMutationObserver(
      document.body,
      mutationObserverCallback,
      {
        attributes: true,
        childList: true,
        subtree: true,
      },
    );

    return () => {
      observer.stop();
    };
  };

  const cleanupBeforeElementReinitialization = (
    addedElement: Element,
    removedElements: Element[],
  ) => {
    const removedElementIndex = removedElements.findIndex(
      removedElement =>
        removedElement.getAttribute(initializedAttributeName) ===
        addedElement.getAttribute(initializedAttributeName),
    );

    if (removedElementIndex !== -1) {
      removedElements.splice(removedElementIndex, 1);
    }

    if (addedElement.hasAttribute(initializedAttributeName)) {
      const id = addedElement.getAttribute(initializedAttributeName) as string;

      const toRemoveChild = addedElement.querySelector(
        `[${initializedAttributeName}="${id}"]`,
      );

      const toRemoveParent = document.createElement("div");

      toRemoveParent.setAttribute(initializedAttributeName, id);

      removedElements.push(toRemoveParent);

      toRemoveChild?.remove();
    }
  };

  const mapUninitialized = (cb?: (element: Element, name: string) => void) => {
    const mapElements = document.querySelectorAll<HTMLElement>(
      selectors.join(","),
    );

    mapElements.forEach(mapElement => {
      if (mapElement.hasAttribute(initializedAttributeName)) {
        return;
      }

      const name = generateUUID();

      mapElement.setAttribute(initializedAttributeName, name);

      cb?.(mapElement, name);
    });
  };

  const unmapInitialized = (cb?: (name: string) => void) => {
    const unmapElements = document.querySelectorAll<HTMLElement>(
      selectors.join(","),
    );

    unmapElements.forEach(unmapElement => {
      if (!unmapElement.hasAttribute(initializedAttributeName)) {
        return;
      }

      const name = unmapElement.getAttribute(
        initializedAttributeName,
      ) as string;

      unmapElement.removeAttribute(initializedAttributeName);

      cb?.(name);
    });
  };

  return { observe, mapUninitialized, unmapInitialized };
};
